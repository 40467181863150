import ClearIcon from 'ui/icons/ClearIcon';

import { Box, Chip, SxProps, Theme, useTheme } from '@mui/material';

export interface ChipListItem {
  name: string | JSX.Element;
  id: string;
}

export interface ChipListProps {
  list: ChipListItem[];
  classNames?: string;
  onDelete?: (id: string) => void;
}

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    root: {
      listStyle: 'none',
      padding: 0,
      '& > li': {
        maxWidth: `calc(100% - ${theme.spacing(2)})`,
      },
      // fix for Chrome Zoom bug that causes triggering ellipsis in zoom less than 100%
      '&:after': {
        content: "''",
        display: 'block',
        minWidth: '0.1px',
      },
    },
    item: {
      mx: 1,
      my: 1,
      [theme.breakpoints.down('md')]: {
        my: 0.5,
      },
    },
  };
};

export default function ChipList(props: ChipListProps) {
  const { list, classNames, onDelete } = props;

  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <Box mx={-1} component="ul" display="flex" flexWrap="wrap" sx={styles.root}>
      {list.map((item: { name: string | JSX.Element; id: string }, index: number) => (
        <Box component="li" key={index} sx={styles.item}>
          <Chip
            clickable={false}
            className={classNames}
            label={item.name}
            onDelete={
              onDelete &&
              (() => {
                onDelete(item.id);
              })
            }
            deleteIcon={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#3B414D',
                  marginRight: '10px !important',
                }}
              >
                <ClearIcon sx={{ fontSize: '1.1rem' }} />
              </Box>
            }
          />
        </Box>
      ))}
    </Box>
  );
}
