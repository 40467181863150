import LoadSummaryPlainText from 'app-sections/common/applications/summary-sections/property-size/components/load-summary-plain-text/LoadSummaryPlainText';
import { get, omit } from 'lodash-es';
import { renderToStaticMarkup } from 'react-dom/server';
import { Application, Params } from 'types';

import { VariantOptionId } from '../step3/config';

export interface DataPart {
  data: Params;
  isValid: boolean;
}

export interface DataParts {
  propertySize: DataPart;

  presentOwner: DataPart;
  ownerChange: DataPart;

  presentUse: DataPart;
  proposedUse: DataPart;

  presentLoad: DataPart;
  proposedLoad: DataPart;

  propertyChangeTypes: ChangeType[];
}

export type ChangeType = keyof DataParts;

export interface ChangeTypeOption {
  name: string;
  id: ChangeType;
}

export const CHANGE_TYPES: ChangeTypeOption[] = [
  { name: 'Owner/Business Transfer', id: 'ownerChange' },
  { name: 'Use Change', id: 'proposedUse' },
  { name: 'Load Change', id: 'proposedLoad' },
];

export const DATA_PARTS: { name: string; id: keyof DataParts }[] = [
  { name: 'Building Description', id: 'propertySize' },
  { name: 'Building/Space Owner', id: 'presentOwner' },
  { name: 'Building/Space Use', id: 'presentUse' },
  { name: 'Building/Space Load', id: 'presentLoad' },
  { name: 'Proposed Use', id: 'proposedUse' },
  { name: 'Proposed Load', id: 'proposedLoad' },
  { name: 'Proposed Owner', id: 'ownerChange' },
  { name: 'Type of Changes', id: 'propertyChangeTypes' },
];

export const getDataPartName = (id: keyof DataParts): string => {
  return DATA_PARTS.find((item) => item.id === id)?.name || '';
};

export const getDataPartsFromApplication = (app: Application): DataParts => {
  return {
    propertySize: app.propertySize,
    presentOwner: app.presentOwner,
    ownerChange: app.ownerChange,
    presentUse: app.presentUse,
    proposedUse: app.proposedUse,
    presentLoad: app.presentLoad,
    proposedLoad: app.proposedLoad,
    propertyChangeTypes: app.propertyChangeTypes,
  };
};

export const generateLoadAsPlainText = (data: Params, propertyChangeTypes: Params): string => {
  let dataKey = 'presentLoad.data';
  let categoryKey = 'presentUse.data.specificZoningUse';

  if (propertyChangeTypes.includes('proposedLoad')) {
    dataKey = 'proposedLoad.data';
  }

  if (propertyChangeTypes.includes('proposedUse')) {
    dataKey = 'proposedLoad.data';
    categoryKey = 'proposedUse.data.specificZoningUse';
  }

  return renderToStaticMarkup(
    <LoadSummaryPlainText data={get(data, dataKey) || {}} specificZoningUse={get(data, categoryKey) || ''} />
  );
};

export const getProposedLoadZoningUseData = (dataParts: Params, propertyChangeTypes: Params) => {
  if (dataParts?.proposedUse?.data?.specificZoningUse) {
    return dataParts?.proposedUse?.data.specificZoningUse;
  }

  if (dataParts?.presentUse?.data?.specificZoningUse && !propertyChangeTypes.includes('proposedUse')) {
    return dataParts?.presentUse?.data?.specificZoningUse;
  }

  return '';
};

export const getDataForPartialReviewChange = (reviewerChangeData: Params, propertyChangeTypes: (keyof DataParts)[]) => {
  let cleanData = {
    ...reviewerChangeData.dataPart,
    data: omit(reviewerChangeData.dataPart.data, ['changeReason']),
  };
  if (reviewerChangeData.partName === 'propertyChangeTypes') {
    cleanData = propertyChangeTypes;
  }

  return {
    data: cleanData,
    fieldChanged: reviewerChangeData.partName,
    changeReason: reviewerChangeData.dataPart.data.changeReason,
    occupantLoad: generateLoadAsPlainText(reviewerChangeData.dataToSave, propertyChangeTypes),
  };
};

/**
 * Cleanup change data if it's not a `change` application.
 * It may cause issues with the application in next steps
 *
 * @param typeOfApplication
 * @param dataParts
 * @returns
 */
export const parseDataParts = (typeOfApplication: VariantOptionId, dataParts: DataParts): DataParts => {
  if (typeOfApplication !== 'change') {
    return {
      ...dataParts,
      ownerChange: {
        data: {},
        isValid: false,
      },
      proposedUse: {
        data: {},
        isValid: false,
      },
      proposedLoad: {
        data: {},
        isValid: false,
      },
      propertyChangeTypes: [],
    };
  }
  return dataParts;
};

export const getSectionLabels = (typeOfApplication: VariantOptionId | null | undefined): Params => {
  if (typeOfApplication === 'change') {
    return {
      presentPropertiesHeader: {
        title: 'Existing conditions of the building / spaces',
        subtitle:
          "Describe the current conditions of the building / spaces and select the type of change(s) you're proposing.",
      },

      presentOwner: {
        title: 'Existing building/space owner',
        subtitle: '',
      },

      propertySize: {
        title: 'Building Description',
        subtitle: 'Provide the overall size of the Building/Space.',
      },

      presentUse: {
        title: 'Existing use of building/space',
        subtitle: '',
      },
      presentLoad: {
        title: 'Existing occupant Load of building/space',
        subtitle: '',
      },

      propertyChangeTypes: {
        title: 'Proposed changes',
        subtitle:
          'Fill out each section below carefully for each of the type of change selected. Once reviewed and approved by the Department of Buildings this information will display on the new Certificate of Occupancy.',
      },

      ownerChange: {
        title: 'Proposed building/space owner',
        subtitle: '',
      },

      proposedUse: {
        title: 'Proposed use of building/space',
        subtitle: '',
      },
      proposedLoad: {
        title: 'Proposed occupant load of building/space',
        subtitle: '',
      },
    };
  }

  // For regular types Present/Existing becomes Proposed because of it's new establishment
  return {
    presentPropertiesHeader: {
      title: 'Proposed conditions of the building/spaces',
      subtitle: 'Describe the proposed conditions of the building/spaces.',
    },

    presentOwner: {
      title: 'Proposed building/space owner',
      subtitle: '',
    },

    propertySize: {
      title: 'Building Description',
      subtitle: 'Provide the overall size of the Building/Space.',
    },

    presentUse: {
      title: 'Proposed use of building/space',
      subtitle: '',
    },
    presentLoad: {
      title: 'Proposed occupant load of building/space',
      subtitle: '',
    },
  };
};
