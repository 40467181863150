import { Link } from 'react-router-dom';

import { Button, useMediaQuery, useTheme } from '@mui/material';

export interface DashboardButtonProps {
  isShortOnMobile?: boolean;
  color?: "primary" | "secondary";
}

export default function DashboardButton(props: DashboardButtonProps) {
  const { isShortOnMobile = false, color = "primary" } = props;

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = isShortOnMobile && matchesMobile;
  const label = isMobile ? "Dashboard" : "Go to dashboard";

  return (
    <Button
      type="button"
      variant="contained"
      color={color}
      disableElevation
      component={Link}
      to={"/"}
    >
      {label}
    </Button>
  );
}
