import { get } from 'lodash-es';
import { Fragment } from 'react';
import { Params } from 'types';
import { ExtendableGroupData, getItemById, NestedFormItem } from 'ui/form-fields/nested-fields/config';

import { NestedFieldsSummaryPlainTextItem } from '../nested-fields-summary-pain-text-item/NestedFieldsSummaryPlainTextItem';
import { NestedItemsGroupSummary } from '../nested-items-group-summary/NestedItemsGroupSummary';

export interface NestedItemsSummaryGroupProps {
  data: Params;
  config: NestedFormItem[];
  collectionName: string;
  isParentExtendable: ExtendableGroupData | null;
}

export function NestedItemsSummaryGroup(props: NestedItemsSummaryGroupProps) {
  const { data, config, collectionName, isParentExtendable } = props;

  const fields = get(data, collectionName) || [];

  return (
    <>
      {fields.map((group: Params, index: number) => (
        <Fragment key={`${collectionName}.${index}`}>
          {isParentExtendable?.groupItemHeader && (
            <>
              {"- "}
              {isParentExtendable?.groupItemHeader} {index + 1}
              {"\n"}
            </>
          )}
          <>
            {Object.keys(group).map((key: string, i: number) => {
              const itemConfig = getItemById(key, config);

              if (!itemConfig) {
                return null;
              }

              const inputName = `${collectionName}.${index}.${key}`;
              const itemValue = get(data, inputName);

              if (!itemValue) {
                return null;
              }

              return (
                <NestedFieldsSummaryPlainTextItem
                  key={key}
                  itemValue={itemValue}
                  item={itemConfig}
                  inputName={inputName}
                  collectionName={collectionName}
                />
              );
            })}
          </>
          {fields.length - 1 > index && <>{"\n"}</>}
        </Fragment>
      ))}

      {isParentExtendable && isParentExtendable?.summaryOptions && (
        <>
          <NestedItemsGroupSummary
            data={data}
            fields={fields}
            groupSettings={isParentExtendable}
            collectionName={collectionName}
          />
          <>{"\n"}</>
        </>
      )}
    </>
  );
}
