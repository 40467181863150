import { get } from 'lodash-es';
import { Params } from 'types';
import { ExtendableGroupData } from 'ui/form-fields/nested-fields/config';

export interface NestedItemsGroupSummaryProps {
  data: Params;
  fields: any[];
  collectionName: string;
  groupSettings: ExtendableGroupData;
}

export function NestedItemsGroupSummary(props: NestedItemsGroupSummaryProps) {
  const { collectionName, groupSettings, fields, data } = props;

  const total = fields
    .map((group: Params, index: number) => {
      const inputName = `${collectionName}.${index}.${groupSettings?.summaryOptions?.showTotalByField}`;
      const val = parseInt(get(data, inputName));
      return isNaN(val) ? 0 : val;
    })
    .reduce((acc, curr) => acc + curr, 0);

  return (
    <>
      {"   - "}Total: {String(total)}
    </>
  );
}
