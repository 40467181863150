import { NestedFormItem } from 'ui/form-fields/nested-fields/config';

export const SECTIONS = [
  { name: "Cellar/Basement", id: "cellarBasement" },
  { name: "Mezzanine", id: "mezzanine" },
  { name: "1st Floor", id: "1stFloor" },
  { name: "2nd Floor", id: "2ndFloor" },
  { name: "3rd Floor", id: "3rdFloor" },
  { name: "4th Floor", id: "4thFloor" },
  { name: "5th Floor", id: "5thFloor" },
  { name: "6th Floor", id: "6thFloor" },
  { name: "7th Floor", id: "7thFloor" },
  { name: "8th Floor", id: "8thFloor" },
  { name: "9th Floor", id: "9thFloor" },
  { name: "10th Floor", id: "10thFloor" },
  { name: "11th Floor", id: "11thFloor" },
  { name: "12th Floor", id: "12thFloor" },
  { name: "13th Floor", id: "13thFloor" },
  { name: "14th Floor", id: "14thFloor" },
  { name: "Roof", id: "roof" },
  { name: "Summer Garden (private outdoor space)", id: "summerGarden" },
  { name: "Outdoor Café (public space)", id: "outdoorCafe" },
  { name: "Other", id: "other" },
];

export const CHILDCARE_FIELD_SCHEMA: NestedFormItem[] = [
  {
    id: "childrenRange1",
    label: "Number of children 0 - 30 months",
    type: "checkbox",
    fullWidth: true,
    isExtendable: {
      maxItems: SECTIONS.length,
      buttonLabel: "Add another section",
      removeButtonLabel: "Delete section",
      groupSchema: {
        sectionId: "",
        sectionLoad: "",
      },
      summaryOptions: {
        showTotalByField: "sectionLoad",
      },
    },
    hasChildren: [
      {
        id: "sectionId",
        label: "Select section of the building/space",
        type: "select",
        isExtendable: null,
        hasChildren: null,
        dictionary: SECTIONS,
      },
      {
        id: "sectionLoad",
        label: "Number of children",
        type: "text",
        isExtendable: null,
        hasChildren: null,
      },
    ],
    childrenType: "group",
  },
  {
    id: "childrenRange2",
    label: "Number of children 30 months 1 day - 47 months",
    type: "checkbox",
    fullWidth: true,
    isExtendable: {
      maxItems: SECTIONS.length,
      buttonLabel: "Add another section",
      removeButtonLabel: "Delete section",
      groupSchema: {
        sectionId: "",
        sectionLoad: "",
      },
      summaryOptions: {
        showTotalByField: "sectionLoad",
      },
    },
    hasChildren: [
      {
        id: "sectionId",
        label: "Select section",
        type: "select",
        isExtendable: null,
        hasChildren: null,
        dictionary: SECTIONS,
      },
      {
        id: "sectionLoad",
        label: "Number of children",
        type: "text",
        isExtendable: null,
        hasChildren: null,
      },
    ],
    childrenType: "group",
  },
  {
    id: "childrenRange3",
    label: "Number of children 4 years - 18 years",
    type: "checkbox",
    fullWidth: true,
    isExtendable: {
      maxItems: SECTIONS.length,
      buttonLabel: "Add another section",
      removeButtonLabel: "Delete section",
      groupSchema: {
        sectionId: "",
        sectionLoad: "",
      },
      summaryOptions: {
        showTotalByField: "sectionLoad",
      },
    },
    hasChildren: [
      {
        id: "sectionId",
        label: "Select section",
        type: "select",
        isExtendable: null,
        hasChildren: null,
        dictionary: SECTIONS,
      },
      {
        id: "sectionLoad",
        label: "Number of children",
        type: "text",
        isExtendable: null,
        hasChildren: null,
      },
    ],
    childrenType: "group",
  },
  {
    id: "staff",
    label: "Number of staff / faculty",
    type: "checkbox",
    fullWidth: true,
    isExtendable: {
      maxItems: SECTIONS.length,
      buttonLabel: "Add another section",
      removeButtonLabel: "Delete section",
      groupSchema: {
        sectionId: "",
        sectionLoad: "",
      },
      summaryOptions: {
        showTotalByField: "sectionLoad",
      },
    },
    hasChildren: [
      {
        id: "sectionId",
        label: "Select section of the building/space",
        type: "select",
        isExtendable: null,
        hasChildren: null,
        dictionary: SECTIONS,
      },
      {
        id: "sectionLoad",
        label: "Number of staff / faculty",
        type: "text",
        isExtendable: null,
        hasChildren: null,
      },
    ],
    childrenType: "group",
  },
];
