import { Box, CircularProgress } from '@mui/material';

export interface ButtonLoaderProps {
  isActive: boolean;
  noMargin?: boolean;
  color?: 'secondary' | 'primary';
  size?: number;
}

export default function ButtonLoader(props: ButtonLoaderProps) {
  const { isActive, noMargin = false, color = 'secondary', size = 20 } = props;

  if (!isActive) {
    return null;
  }

  return (
    <Box display="flex" mr={noMargin ? 0 : 2}>
      <CircularProgress color={color} size={size} />
    </Box>
  );
}
