import { DRAWER_MINI_WIDTH } from 'core/config';

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';

import { useUiStore } from '../../../../store/ui';

interface AppNavbarToggleButtonProps {
  children: JSX.Element;
}

export default function AppNavbarToggleButton(
  props: AppNavbarToggleButtonProps
) {
  const { toggleDrawerState, toggleSidebar } = useUiStore();
  const children = props.children || <MenuIcon />;
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = () => {
    matchesMobile ? toggleDrawerState() : toggleSidebar();
  };

  return (
    <IconButton
      sx={{
        color: theme.palette.common.white,
        width: `calc(${DRAWER_MINI_WIDTH}px + 1px)`,
        height: "100%",
      }}
      onClick={() => {
        handleClick();
      }}
      color="inherit"
    >
      {children}
    </IconButton>
  );
}
