import {
    getLoadData, isChildCareVisible, isDwellingUnitsFilled, isDwellingUnitsVisible, isEatingDrinkingVisible
} from 'app-sections/user/applications/steps/step4/components/property-load-form/config';
import { CHILDCARE_FIELD_SCHEMA } from 'app-sections/user/applications/steps/step4/components/property-load-form/config-parts/childcare/fieldsConfig';
import { DWELLING_UNITS_FIELD_SCHEMA } from 'app-sections/user/applications/steps/step4/components/property-load-form/config-parts/dwelling-units/fieldsConfig';
import { EATING_DRINKING_FIELD_SCHEMA } from 'app-sections/user/applications/steps/step4/components/property-load-form/config-parts/eating-drinking/fieldsConfig';
import { get } from 'lodash-es';
import { Params } from 'types';
import NestedFieldsSummaryPlainText from 'ui/form-fields/nested-fields-summary-plain-text/NestedFieldsSummaryPlainText';

export interface LoadSummaryPlainTextProps {
  data: Params;
  specificZoningUse: string;
}

export default function LoadSummaryPlainText(props: LoadSummaryPlainTextProps) {
  const { data, specificZoningUse } = props;

  if (!specificZoningUse) {
    return null;
  }

  const loadData = getLoadData(specificZoningUse);
  const childCareVisible = isChildCareVisible(loadData.id);
  const eatingDrinkingVisible = isEatingDrinkingVisible(loadData.id);
  const dwellingUnitsVisible = isDwellingUnitsVisible(loadData.id);
  const dwellingUnitsFilled = isDwellingUnitsFilled(data);

  const isExtendedDataVisible =
    childCareVisible ||
    eatingDrinkingVisible ||
    (dwellingUnitsVisible && dwellingUnitsFilled);

  const occupantLoad = `Occupant Load (Maximum total number of people that occupy the space): `;

  const dwellingOccupantLoad = `Occupant Load (Total Number of Dwelling Units): `;

  return (
    <>
      Load Type: {loadData.name}
      {"\n"}
      {"\n"}
      {dwellingUnitsVisible && dwellingOccupantLoad}
      {!dwellingUnitsVisible && occupantLoad}
      {String(get(data, "propertyLoad")) || ""}
      {"\n"}
      {"\n"}
      {isExtendedDataVisible && (
        <>
          Load{"\n"}
          {"\n"}
        </>
      )}
      {childCareVisible && (
        <NestedFieldsSummaryPlainText
          data={data}
          collectionName="extendedPropertyLoad"
          config={CHILDCARE_FIELD_SCHEMA}
        />
      )}
      {eatingDrinkingVisible && (
        <NestedFieldsSummaryPlainText
          data={data}
          collectionName="extendedPropertyLoad"
          config={EATING_DRINKING_FIELD_SCHEMA}
        />
      )}
      {dwellingUnitsVisible && (
        <NestedFieldsSummaryPlainText
          data={data}
          collectionName="extendedPropertyLoad"
          config={DWELLING_UNITS_FIELD_SCHEMA}
        />
      )}
    </>
  );
}
