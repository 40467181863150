import { useDashboardStore } from 'core/store/dashboard';
import { useUiStore } from 'core/store/ui';
import { useEffect } from 'react';
import { WebsocketMessage } from 'types';

// Triggers for refreshing dashboard columns
const COLUMN_REFRESH_TRIGGERS = [
  "emails/admin/correction_requests/client_responded",
  "emails/admin/correction_requests/certificate_correction_request",
  "emails/admin/applications/ready_for_draft_issuance",
  "emails/admin/applications/inspection_fulfilled",
  "emails/admin/reviews/assigned",
];

export interface IncreaseUnreadCountProps {
  message: WebsocketMessage;
}
export default function IncreaseUnreadCount(props: IncreaseUnreadCountProps) {
  const { message } = props;
  const { setUnreadCount, unreadNotificationCounter } = useUiStore();
  const { setColumnRefresh } = useDashboardStore();

  useEffect(() => {
    setUnreadCount(unreadNotificationCounter + 1);
    if (COLUMN_REFRESH_TRIGGERS.includes(message.model.contentCode)) {
      setColumnRefresh(message.uuid);
    }
  }, [message.uuid]);

  return null;
}
