import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

import { FormControl, FormHelperText, Input, InputAdornment } from '@mui/material';

import LabelTooltip from '../label-tooltip/LabelTooltip';

export type BasicTextInputType = "text" | "email" | "number";

export interface BasicTextInputProps {
  label: string;
  helperText?: string;
  control: any;
  inputName: string;
  placeholder?: string;
  type?: BasicTextInputType;
  required?: boolean;
  disabled?: boolean;
  inputComponent?: any;
  inputProps?: any;
  onChange?: any;
  errorsInputName?: string;
  adornment?: { position: "start" | "end"; label: string };
  tooltipText?: string | ReactNode;
}

export default function BasicTextInput(props: BasicTextInputProps) {
  const {
    control,
    label,
    inputName,
    helperText,
    placeholder,
    type = "text",
    required,
    disabled = false,
    inputComponent,
    inputProps,
    onChange,
    adornment,
    tooltipText,
  } = props;

  return (
    <Controller
      name={inputName}
      control={control}
      render={({ field, fieldState }) => (
        <>
          <LabelTooltip
            tooltipText={tooltipText}
            label={label}
            disabled={disabled}
            inputName={inputName}
            required={required}
            error={!!fieldState.error}
          />
          <FormControl error={!!fieldState.error} sx={{ float: "left" }}>
            <Input
              {...field}
              type={type}
              error={!!fieldState.error}
              placeholder={placeholder}
              disabled={disabled}
              inputComponent={inputComponent}
              inputProps={inputProps}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange();
              }}
              endAdornment={
                adornment &&
                adornment.position === "end" && (
                  <InputAdornment position="end">
                    {adornment.label}
                  </InputAdornment>
                )
              }
              startAdornment={
                adornment &&
                adornment.position === "start" && (
                  <InputAdornment position="start">
                    {adornment.label}
                  </InputAdornment>
                )
              }
            />
            {(!!fieldState.error || helperText) && (
              <FormHelperText>
                {fieldState.error?.message || helperText}
              </FormHelperText>
            )}
          </FormControl>
        </>
      )}
    />
  );
}
