import { HolidaysTypes } from 'date-holidays';
import dayjs from 'dayjs';

import { DB_DATE_FORMAT_STRING } from './parseDate';

export const parseHolidayDay = (day: HolidaysTypes.Holiday): string => {
  const d = dayjs(day.date.split(' ')[0]);
  let output = d;
  if (d.day() === 0) {
    output = d.add(1, 'day');
  }
  if (d.day() === 6) {
    output = d.add(-1, 'day');
  }
  return output.format(DB_DATE_FORMAT_STRING);
};

export function getFridayAfterThanksgiving(): string {
  const NEEDED_DAY_OF_WEEK = 4;

  let output = dayjs();
  // Get November the 1st
  const currentNovember = dayjs().startOf('year').add(10, 'month');

  let nextDay = currentNovember.clone();
  if (currentNovember.day() !== NEEDED_DAY_OF_WEEK) {
    do {
      nextDay = nextDay.add(1, 'day');
    } while (nextDay.day() !== NEEDED_DAY_OF_WEEK);
  }
  output = nextDay.add(3, 'weeks');

  // Get Friday after the 4th thursday
  output = output.add(1, 'day');
  return output.format(DB_DATE_FORMAT_STRING);
}
