import axios from 'axios';
import { startCase } from 'lodash-es';

export const getErrorMessagesAsArray = (err: any): string[] | undefined => {
  if (err instanceof axios.Cancel) {
    // when request is cancelled there is no need to throw error.
    return;
  }

  let errData: any;

  console.error(err);

  if (err.response) {
    errData = err.response.data;
  } else if (err?.errors) {
    errData = err;
  }

  return parseDBErrors(errData.errors);
};

const parseDBErrors = (e: { [key: string]: string[] }) => {
  return Object.entries(e).map(([key, value]) => {
    return `${startCase(key)}: ${value.join(", ")}`;
  });
};
