import { checkIsLiftedAfter } from 'app-sections/common/applications/application-state-listener/messages';
import { Application } from 'types';
import InfoMessage from 'ui/components/info-message/InfoMessage';

export interface SwoInfoProps {
  data: Application | undefined;
}

export default function SwoLiftedInfo(props: SwoInfoProps) {
  const { data } = props;
  if (checkIsLiftedAfter(data?.swoLiftedAt || '')) {
    return null;
  }

  return (
    <InfoMessage
      type="success"
      notClosable={true}
      title="Stop Work Order Lifted"
      content={
        <>
          We would like to inform you that the Stop Work Order (SWO) restriction associated with this application has
          been lifted.
        </>
      }
    />
  );
}
