import CollapsibleDrawer from 'core/layouts/collapsible-drawer/CollapsibleDrawer';

import { Box, Drawer, SxProps, Theme, useTheme } from '@mui/material';

import { DRAWER_WIDTH } from '../../../config';
import { useUiStore } from '../../../store/ui';
import AppNavbar from '../app-navbar/AppNavbar';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    wrapper: {
      zIndex: 4,
      background: theme.palette.background.default,
    },
    navbar: {
      height: "100%",
      zIndex: 4,
      transition: theme.transitions.create(["width", "min-width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
    },
  };
};

export default function AppSidebar() {
  const { drawerState, toggleDrawerState } = useUiStore();

  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Box sx={styles.wrapper}>
      {/* DESKTOP NAVIGATION WITH COLLAPSIBLE SIDEBAR */}
      <Box sx={{ ...styles.navbar, display: { xs: "none", md: "block" } }}>
        <CollapsibleDrawer>
          <AppNavbar />
        </CollapsibleDrawer>
      </Box>

      {/* MOBILE NAVIGATION */}
      <Drawer
        anchor="left"
        variant="temporary"
        open={drawerState}
        onClose={toggleDrawerState}
        PaperProps={{
          sx: { ...styles.navbar, width: DRAWER_WIDTH, minWidth: DRAWER_WIDTH },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <AppNavbar />
      </Drawer>
    </Box>
  );
}
