import { setCache } from 'core/cache/helpers';
import { Review, WebsocketMessage } from 'types';

import { useQueryClient } from '@tanstack/react-query';

export interface SetReviewCollectionProps {
  message: WebsocketMessage;
}

/**
 * Set all reviews at once
 * @param props
 * @returns
 */
export default function SetReviewCollection(props: SetReviewCollectionProps) {
  const { message } = props;

  const queryClient = useQueryClient();

  if (message.model[0]?.applicationId) {
    const queryKey = [
      "applications",
      message.model[0]?.applicationId,
      "reviews",
    ];

    setCache<Review[]>(queryClient, queryKey, message.model);
  }

  return null;
}
