import { LogoConfig } from './ToolbarLogo';

export const DEFAULT_LOGO_CONF: LogoConfig = {
  fileName: "certifi-logo.svg",
  width: 187,
  label: "Certifi",
};

export const MOBILE_LOGO_CONF: LogoConfig = {
  fileName: "certifi-logo-mobile.svg",
  width: 39,
  label: "Certifi",
};

// const EXTENDED_LOGO_CONF: LogoConfig = {
//   fileName: "logo-inspections.svg",
//   width: 325,
//   label: "Tertius DOB Constructions Inspections",
// };

export const getLogoConfig = (): LogoConfig => {
  return DEFAULT_LOGO_CONF;
};
