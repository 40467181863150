import { Link } from 'react-router-dom';
import AddIcon from 'ui/icons/AddIcon';

import { Box, Button, useMediaQuery, useTheme } from '@mui/material';

export interface AddNewAppButtonProps {
  isShortOnMobile?: boolean;
}

export default function AddNewAppButton(props: AddNewAppButtonProps) {
  const { isShortOnMobile = false } = props;

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = isShortOnMobile && matchesMobile;
  const label = isMobile ? "Application" : "Add new application";

  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      disableElevation
      component={Link}
      to={"/applications/create-new"}
    >
      <Box display="flex" alignItems="center">
        <AddIcon
          sx={{
            display: "block",
            mr: 1,
            fontSize: "2.1rem",
          }}
        />
        <Box component="span">{label}</Box>
      </Box>
    </Button>
  );
}
