import { assetUrl } from 'helpers/assetUrl';
import FbIcon from 'ui/icons/FbIcon';
import InstaIcon from 'ui/icons/InstaIcon';
import LinkedinIcon from 'ui/icons/LinkedinIcon';
import XIcon from 'ui/icons/XIcon';

import { Box, Container, Grid, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    main: {
      background: "#535863",
      color: "#fff",
    },

    subtitle: {
      mb: 2,
      color: "#fff",
      fontSize: "2.4rem",
      fontWeight: "bold",
    },

    rowContainer: {
      display: "block",
      alignItems: "stretch",
      width: "100%",
      height: "100%",
      py: 3,
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },

    listHeader: {
      mt: 0,
      mb: 2,
      fontSize: "2.4rem",
      fontWeight: "bold",
    },

    listContainer: {
      mt: 4,
      counterReset: "section-counter",
      p: 0,
    },

    listContent: {
      fontSize: "1.6rem",
      listStyle: "none",
      padding: 0,
      margin: 0,
      "&.horizontal": {
        display: "flex",
        mx: "-1px",
        "& li": {
          mb: 0,
          mx: 1,
        },
        [theme.breakpoints.down("md")]: {
          display: "block",
        },
      },
      "& li": {
        mb: 1,
      },
      "& a": {
        textDecoration: "none",
        color: "#fff",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },

    mediaListContent: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      display: "flex",
      flex: 1,
      mx: 3,
      "& li": {
        mb: 0,
        mx: 1,
      },

      "& a": {
        textDecoration: "none",
        color: "#fff",
        opacity: 0.6,
        "&:hover": {
          opacity: 1,
        },
      },

      [theme.breakpoints.down("md")]: {
        margin: "16px 0",
      },
    },
  };
};

export default function Footer() {
  const theme = useTheme();
  const styles = getStyles(theme);
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box component="footer" sx={styles.main}>
      <Container sx={{ pb: 1 }}>
        <Box sx={styles.rowContainer}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box mb={1} sx={{ width: { xs: "300px", sm: "350px" } }}>
              <img
                src={assetUrl(`/images/logos/certifi-bw.svg`)}
                alt="Certifi"
                width="100%"
                height="100%"
              />
            </Box>
            <Box sx={{ flex: 1, my: 1 }}>
              The comprehensive platform for you to apply for, track, and
              receive your approved DC Certificate of Occupancy (CofO)
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: matches ? 0 : 2,
              }}
            >
              <Box ml={-1}>
                <img
                  src={assetUrl(`/images/logos/logo-dob-full-white.png`)}
                  alt="DCRA"
                  width="125"
                />
              </Box>
              <Box ml={2}>
                <img
                  src={assetUrl(`/images/logos/mayor-logo.svg`)}
                  alt="Government of the District of Columbia. Muriel Bowser, Mayor"
                  width="175"
                  height="33"
                />
              </Box>
            </Box>
          </Box>
          <Box
            role="presentation"
            sx={{
              mx: 4,
              width: "1px",
              background: "rgba(255, 255,255, 0.2)",
              alignSelf: "stretch",
            }}
          ></Box>
          <Grid container columnSpacing={4} spacing={4}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box component="h3" sx={styles.listHeader}>
                General
              </Box>
              <Box component="ul" sx={styles.listContent}>
                <li>
                  <a href=" https://dob.dc.gov/" target="_parent">
                    About DOB
                  </a>
                </li>
                <li>
                  <a
                    href="https://dob.dc.gov/accessdc"
                    target="_parent"
                  >
                    About Access DC
                  </a>
                </li>
                <li>
                  <a
                    href="https://info.dob.dc.gov/contact/contact-us-form-HkP7ZsfTs"
                    target="_parent"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="https://dob.dc.gov/sites/default/files/dc/sites/dob/Certifi%20FAQs.pdf" target="_parent">
                    FAQ
                  </a>
                </li>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box component="h3" sx={styles.listHeader}>
                Resources
              </Box>
              <Box component="ul" sx={styles.listContent}>
                <li>
                  <a
                    href="https://permitwizard.dcra.dc.gov/landing-21860"
                    target="_parent"
                  >
                    Permit Wizard
                  </a>
                </li>
                <li>
                  <a
                    href="https://govservices.dcra.dc.gov/contractorratingsystem"
                    target="_parent"
                  >
                    Contractor Rating System
                  </a>
                </li>
                <li>
                  <a
                    href="https://scout.dcra.dc.gov/login"
                    target="_parent"
                    rel="noopener noreferrer"
                  >
                    Scout
                  </a>
                </li>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          role="presentation"
          sx={{
            my: 2,
            width: "100%",
            height: "1px",
            background: "rgba(255, 255,255, 0.2)",
          }}
        ></Box>
        <Box
          sx={{
            display: matches ? "flex" : "block",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box fontSize="1.6rem">©2023 District of Columbia</Box>

          <Box component="ul" sx={styles.mediaListContent}>
            <li>
              <a href="https://instagram.com/deptofbuildings" target="_parent">
                <InstaIcon />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/deptofbuildings"
                target="_parent"
              >
                <FbIcon />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/DC_DOB" target="_parent">
                <XIcon />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/departmentofbuildings/"
                target="_parent"
              >
                <LinkedinIcon />
              </a>
            </li>
          </Box>

          <Box component="ul" sx={styles.listContent} className="horizontal">
            <li>
              <a href="https://dc.gov/node/298582" target="_parent">
                Accessibility
              </a>
            </li>
            <li>
              {/* <a
                href="https://tertius.dcra.dc.gov/privacy-policy"
                target="_parent"
              >
                Privacy and Security
              </a> */}
            </li>
            <li>
              <a href="https://dc.gov/node/819092" target="_parent">
                About dc.gov
              </a>
            </li>
            {/* <li>
              <a href="https://tertius.dcra.dc.gov/legal" target="_parent">
                Terms and Conditions
              </a>
            </li> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
