import { updateCacheItem } from 'core/cache/helpers';
import { Review, WebsocketMessage } from 'types';

import { useQueryClient } from '@tanstack/react-query';

export interface UpdateReviewCollectionProps {
  message: WebsocketMessage;
}

/**
 * For Review Collection item updates
 * @param props
 * @returns
 */
export default function UpdateReviewCollection(
  props: UpdateReviewCollectionProps
) {
  const { message } = props;

  const queryClient = useQueryClient();

  const queryKey = ["applications", message.model.applicationId, "reviews"];

  updateCacheItem<Review>(queryClient, queryKey, message.model, "id");

  return null;
}
