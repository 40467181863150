export const getErrorMessage = (error: Error): string => {
  if (!error.stack) {
    return error.message || error.toString();
  }
  return error.stack;
};

export const isRaygunEnabled = (): boolean => {
  // only for UAT and PROD
  return (
    window.location.host.includes("uat") ||
    window.location.host.includes("certifi.dob.dc.gov")
  );
};
