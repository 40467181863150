import { updateOneCacheItem } from 'core/cache/helpers';
import { Certificate, WebsocketMessage } from 'types';

import { useQueryClient } from '@tanstack/react-query';

import { getQueryKeyByModel } from '../updates-listener/helpers';

export interface FileGeneratedProps {
  message: WebsocketMessage;
  type: "pdf" | "csv";
}

/**
 * For PDF or CSV files generated event
 * @param props
 * @returns
 */
export default function FileGenerated(props: FileGeneratedProps) {
  const { message, type } = props;

  const queryClient = useQueryClient();

  const queryKey = getQueryKeyByModel(message.model);

  let data: any = { fileUrl: message?.model.fileUrl };
  if (type === "pdf") {
    data = { document: { fileUrl: message.model.fileUrl } };
  }

  setTimeout(() => {
    // give some time for S3 private files issue
    updateOneCacheItem<Certificate>(queryClient, queryKey, data);
  }, 1500);

  return null;
}
