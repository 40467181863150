import { SvgIcon, SvgIconProps } from '@mui/material';

export default function UsersProfilesIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 12C9.77817 12 11.625 10.1532 11.625 7.875C11.625 5.59683 9.77817 3.75 7.5 3.75C5.22183 3.75 3.375 5.59683 3.375 7.875C3.375 10.1532 5.22183 12 7.5 12Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 20.25C0.75 16.5221 3.77208 13.5 7.5 13.5C11.2279 13.5 14.25 16.5221 14.25 20.25"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2511 4.41621C15.9764 3.29516 18.264 3.61695 19.6131 5.17046C20.9622 6.72397 20.9602 9.0341 19.6084 10.5853C18.2566 12.1364 15.9685 12.4542 14.2451 11.3302"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 13.6671C17.0024 13.2134 19.1025 13.6948 20.7074 14.9754C22.3123 16.256 23.2479 18.1969 23.25 20.2501"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
