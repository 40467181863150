import { setCache } from 'core/cache/helpers';
import { AccelaPermit, ApiWebsocketResponse, ApiWebsocketResponseState, WebsocketMessage } from 'types';

import { useQueryClient } from '@tanstack/react-query';

export interface SetReviewCollectionProps {
  message: WebsocketMessage;
}

/**
 * Set all reviews at once
 * @param props
 * @returns
 */
export default function SetPermitsForAddress(props: SetReviewCollectionProps) {
  const { message } = props;

  const queryClient = useQueryClient();

  if (message.model?.applicationId) {
    const queryKey = ['accela/permits', message.model?.applicationId, message.model?.certificateType];
    let results: AccelaPermit[] = [];
    let state: ApiWebsocketResponseState = 'isSuccess';
    let error: string[] | null = null;

    if (message.model.models.errors) {
      state = 'isError';
      error = Object.keys(message.model.models.errors).map((key: string) => {
        return message.model.models.errors[key];
      });
    } else {
      if (!message.model.models[0]?.customID) {
        results = [];
      } else {
        results = message.model.models;
      }
    }

    setCache<ApiWebsocketResponse<AccelaPermit>>(queryClient, queryKey, {
      results,
      state,
      error,
    });
  }

  return null;
}
