import LoginButton from 'app-sections/auth/login/LoginButton';
import NotificationsPanel from 'app-sections/common/notifications/notifications-panel/NotificationsPanel';
import AddNewAppButton from 'app-sections/user/applications/components/applications/add-new-button/AddNewAppButton';
import { useAuthStore } from 'core/store/auth';
import { isAdmin, isClient } from 'ui/helpers/users';

import MenuIcon from '@mui/icons-material/Menu';
import { Container, SxProps, Theme, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import { useUiStore } from '../../../store/ui';
import ToolbarLogo from '../toolbar-logo/ToolbarLogo';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    wrapper: {
      width: "100%",
      flexGrow: 1,
      position: "absolute",
      zIndex: 5,
    },
    appBar: {
      background: "#fff",
    },
    iconButton: {
      mr: 1,
      color: theme.palette.primary.dark,
    },
    header: {
      flexGrow: 1,
      margin: 0,
    },
  };
};

interface AppToolbarProps {
  forceCenter?: boolean;
}

export default function AppToolbar(props: AppToolbarProps) {
  const { forceCenter = false } = props;
  const { toggleDrawerState } = useUiStore();
  const { currentUser } = useAuthStore();

  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <Box sx={styles.wrapper}>
      <AppBar position="static" elevation={1} sx={styles.appBar}>
        <Container
          className={currentUser && !forceCenter ? "disable-center" : ""}
        >
          <Toolbar sx={{ padding: "0 !important", m: 0 }}>
            {currentUser && (
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                sx={{
                  ...styles.iconButton,
                  display: { xs: "block", md: "none" },
                }}
                onClick={toggleDrawerState}
              >
                <MenuIcon />
              </IconButton>
            )}

            <ToolbarLogo />
            <Box sx={styles.header}></Box>
            {currentUser && isClient(currentUser.userProfile.roles) && (
              <AddNewAppButton isShortOnMobile={true} />
            )}

            {!currentUser && (
              <>
                <LoginButton variant="text" />
              </>
            )}

            {currentUser && (
              <>
                <NotificationsPanel
                  isForAdmin={isAdmin(currentUser.userProfile.roles)}
                  userId={currentUser.id}
                />
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
