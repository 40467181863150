import { Box, SxProps, Theme, useTheme } from '@mui/material';

import { assetUrl } from '../../../../helpers/assetUrl';
import { DEFAULT_LOGO_CONF, MOBILE_LOGO_CONF } from './helpers';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    imageLink: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "64px",
    },
    withShadow: {
      filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15))",
    },
  };
};

export interface LogoConfig {
  fileName: string;
  width: number;
  label: string;
}

interface ToolbarLogoProps {
  textLogoFileConfig?: LogoConfig;
}

export default function ToolbarLogo(props: ToolbarLogoProps) {
  const { textLogoFileConfig = DEFAULT_LOGO_CONF } = props;
  const homePageLink = "/app";

  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <>
      <Box display={{ xs: "none", sm: "none", md: "block" }}>
        <Box component="a" href={homePageLink} sx={styles.imageLink}>
          <img
            src={assetUrl(`/images/logos/${textLogoFileConfig.fileName}`)}
            alt={textLogoFileConfig.label}
            width={textLogoFileConfig.width}
          />
        </Box>
      </Box>

      <Box display={{ xs: "none", sm: "block", md: "none" }}>
        <Box component="a" href={homePageLink} sx={styles.imageLink}>
          <img
            src={assetUrl(`/images/logos/${MOBILE_LOGO_CONF.fileName}`)}
            alt={MOBILE_LOGO_CONF.label}
            width={MOBILE_LOGO_CONF.width}
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "none" }}>
        <Box component="a" href={homePageLink} sx={styles.imageLink} mr={1}>
          <img
            src={assetUrl(`/images/logos/${MOBILE_LOGO_CONF.fileName}`)}
            alt={MOBILE_LOGO_CONF.label}
            width={MOBILE_LOGO_CONF.width}
          />
        </Box>
      </Box>
    </>
  );
}
