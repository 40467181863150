import { ApiCollectionResponse, NotificationItem, Params } from 'types';

import { getCollectionResponse, request } from '../config';

const GET_NOTIFICATIONS_URL = "/notifications.json";

export function getNotifications(
  params: Params
): Promise<ApiCollectionResponse<NotificationItem>> {
  return request(
    {
      url: GET_NOTIFICATIONS_URL,
      method: "GET",
      params: params,
    },
    (data) => {
      return getCollectionResponse<NotificationItem>(data);
    }
  );
}
