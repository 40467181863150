import AlertTriangle from 'ui/icons/AlertTriangle';
import CheckSuccessIcon from 'ui/icons/CheckSuccessIcon';
import ClockIcon from 'ui/icons/ClockIcon';

import { Box, Card, LinearProgress, SxProps, Typography } from '@mui/material';

const getStyles = (): { [key: string]: SxProps } => {
  return {
    failed: {
      background: "#ffe9ee",
      border: "1px solid #D50037",
    },
    success: {
      background: "#e9f3e8",
    },
    pending: {
      background: "#fff3e6",
    },
  };
};

const getIcon = (type: StatusCardState): JSX.Element => {
  if (type === "failed") {
    return <AlertTriangle style={{ fontSize: "60px" }} />;
  }

  if (type === "success") {
    return <CheckSuccessIcon style={{ color: "#28a96b", fontSize: "60px" }} />;
  }

  return <ClockIcon style={{ color: "#e27816", fontSize: "56px" }} />;
};

export type StatusCardState = "failed" | "success" | "pending";

export interface StatusCardProps {
  status: StatusCardState;
  title: string;
  content: string | JSX.Element;
  button?: JSX.Element | null;
  isLoading?: boolean;
}

export default function StatusCard(props: StatusCardProps) {
  const { status, content, button, title, isLoading = false } = props;

  const styles = getStyles();

  return (
    <Card elevation={0} sx={styles[status]}>
      <Box p={3} display="flex">
        {getIcon(status)}
        <Box ml={2} sx={{ overflow: "hidden" }}>
          <Typography
            component="h5"
            variant="h5"
            sx={{ textTransform: "uppercase", mb: 0.5, overflowWrap: "break-word" }}
          >
            {title}
          </Typography>
          <Box fontSize="2rem" sx={{ overflowWrap: "break-word" }}>{content}</Box>
        </Box>
        {button && (
          <Box display="flex" flex="1" justifyContent="flex-end">
            <Box>{button}</Box>
          </Box>
        )}
      </Box>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Card>
  );
}
