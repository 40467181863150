import rg4js from 'raygun4js';
import { Component, ErrorInfo, ReactNode } from 'react';

import ErrorMessage from './ErrorMessage';
import { getErrorMessage, isRaygunEnabled } from './errors.lib';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

class LayoutErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: "",
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true, errorMessage: "" };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    this.setState({
      hasError: true,
      errorMessage: getErrorMessage(error),
    });

    // Send error and error info to Raygun!
    if (isRaygunEnabled()) {
      rg4js("send", error);
    }
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorMessage errorMessage={this.state.errorMessage} />;
    }

    return this.props.children;
  }
}

export default LayoutErrorBoundary;
