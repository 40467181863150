import { Box, Card, CircularProgress, Typography } from '@mui/material';

import AlertCircleIcon from '../../icons/AlertCircleIcon';
import CheckSuccessIcon from '../../icons/CheckSuccessIcon';
import InfoCircleIcon from '../../icons/InfoCircleIcon';
import ClosablePanel from '../closable-panel/ClosablePanel';

export type MessageType =
  | "info"
  | "info-light"
  | "success"
  | "error"
  | "error2"
  | "neutral"
  | "neutral-inversed"
  | "warning";
export interface InfoMessageProps {
  type: MessageType;
  title?: string;
  content?: string | JSX.Element;
  isWithLoader?: boolean;
  notClosable?: boolean;
  customIcon?: JSX.Element;
  isSlim?: boolean;
  onClose?: () => void;
}

const getIcon = (
  type: MessageType,
  customIcon: JSX.Element | undefined
): JSX.Element => {
  if (typeof customIcon !== "undefined") {
    return customIcon;
  }

  if (type === "error" || type === "error2") {
    return <AlertCircleIcon style={{ color: "#fff" }} />;
  }

  if (type === "success") {
    return <CheckSuccessIcon style={{ color: "#fff" }} />;
  }

  if (type === "warning") {
    return <InfoCircleIcon style={{ color: "#3B414D" }} />;
  }

  return <InfoCircleIcon style={{ color: "#fff" }} />;
};

/**
 *
 * InfoMessage
 *
 * Props:
 *
 *  type: 'info' | 'info-light' | 'success' | 'error' | 'neutral' | 'neutral-inversed' | 'warning';
 *
 *  title: string;
 *
 *  content: string | JSX.Element;
 *
 *  isWithLoader?: boolean;
 *
 *  notClosable?: boolean;
 *
 *  isSlim?: boolean;
 *
 *  customIcon?: JSX.Element;
 *
 */
export default function InfoMessage(props: InfoMessageProps) {
  const {
    type,
    title = "",
    content,
    isWithLoader,
    notClosable,
    customIcon,
    isSlim,
    onClose,
  } = props;

  let contentMargin = 0.8;
  if (!title) {
    contentMargin = 0;
  }

  const py = isSlim ? 1.5 : 2.5;

  const innerContent = (
    <Card elevation={0} className={`card-type-${type}`}>
      <Box px={2.5} py={py} display="flex">
        {isWithLoader && (
          <CircularProgress
            color="primary"
            size={24}
            style={{ color: "#fff" }}
          />
        )}
        {!isWithLoader && getIcon(type, customIcon)}
        <Box ml={2}>
          {title && title !== "" && (
            <Typography component="h5" variant="h5">
              {title}
            </Typography>
          )}

          {content && (
            <Box mt={contentMargin} mb={0} fontSize="1.6rem">
              {content}
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );

  if (notClosable) {
    return innerContent;
  }

  return <ClosablePanel onClose={onClose}>{innerContent}</ClosablePanel>;
}
