import { ExternalButton } from 'ui/components/external-button/ExternalButton';

import { Button } from '@mui/material';

import { getUrl } from './LoginButton';

export interface RegisterButtonProps {
  label?: string;
  color?: "primary" | "secondary";
  variant?: "contained" | "text" | "outlined";
}

export default function RegisterButton(props: RegisterButtonProps) {
  const {
    label = "Register",
    color = "primary",
    variant = "contained",
  } = props;

  return (
    <Button
      color={color}
      variant={variant}
      disableElevation
      component={ExternalButton}
      onClick={() => {
        window.location.href = getUrl();
      }}
    >
      {label}
    </Button>
  );
}
