import merge from 'lodash-es/merge';

import { createTheme } from '@mui/material';

import { GLOBAL_THEME } from './theme-components/global';
import { OVERRIDES_THEME } from './theme-components/overrides';

const mainTheme = createTheme(merge(OVERRIDES_THEME, GLOBAL_THEME));

export default mainTheme;
