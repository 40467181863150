import { updateOneCacheItem } from 'core/cache/helpers';
import { Application, WebsocketMessage } from 'types';

import { useQueryClient } from '@tanstack/react-query';

export interface ApplicationUpdateProps {
  message: WebsocketMessage;
}

/**
 * For Application updates
 * @param props
 * @returns
 */
export default function ApplicationUpdate(props: ApplicationUpdateProps) {
  const { message } = props;

  const queryClient = useQueryClient();

  const queryKey = ["applications", message.model.id];

  updateOneCacheItem<Application>(queryClient, queryKey, message.model);

  return null;
}
