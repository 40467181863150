import { useUiStore } from 'core/store/ui';
import { useEffect } from 'react';
import { WebsocketMessage } from 'types';

export interface SetUnreadCountProps {
  message: WebsocketMessage;
}

export default function SetUnreadCount(props: SetUnreadCountProps) {
  const { message } = props;

  const { setUnreadCount } = useUiStore();

  useEffect(() => {
    setUnreadCount(message.model.unread_count);
  }, [message.uuid]);

  return null;
}
