import { useState } from 'react';
import ButtonLoader from 'ui/components/button-loader/ButtonLoader';

import { Button } from '@mui/material';

export const getUrl = () => {
  return `/api/oauth/login_redirect?host=${window.location.origin}`;
};

export interface LoginButtonProps {
  label?: string;
  color?: "primary" | "secondary";
  variant?: "contained" | "text" | "outlined";
}

export default function LoginButton(props: LoginButtonProps) {
  const { label = "Log In", color = "primary", variant = "contained" } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Button
      color={color}
      variant={variant}
      disableElevation
      disabled={isLoading}
      onClick={() => {
        setIsLoading(true);
        window.location.href = getUrl();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }}
    >
      <ButtonLoader isActive={isLoading} />
      {label}
    </Button>
  );
}
