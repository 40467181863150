import {
  Application,
  Params,
  ReviewSectionStateId,
  ReviewTabSectionId,
} from "types";

export const APP_DETAILS_NAV_TABS: Params[] = [
  {
    id: "status-info",
    name: "Status info",
  },
  {
    id: "review-details",
    name: "Review details",
  },
  {
    id: "c-of-o-issuance",
    name: "CofO Issuance",
  },
];

export const APP_DETAILS_NAV_TABS_ADMIN: Params[] = [
  {
    id: "status-info",
    name: "Status info",
  },
  {
    id: "review-details",
    name: "Review details",
  },
  {
    id: "c-of-o-issuance",
    name: "CofO Issuance",
  },
  {
    id: "internal-messaging",
    name: "Internal Messaging",
  },
];

export const APP_DETAILS_REVIEWS_NAV_TABS: {
  id: ReviewTabSectionId;
  name: string;
  sectionStateId?: ReviewSectionStateId;
}[] = [
  {
    id: "applicantValidation",
    name: "Applicant Validation",
    sectionStateId: "applicantValidationState",
  },
  {
    id: "existingCOO",
    name: "Existing CofO",
    sectionStateId: "existingCOOState",
  },
  {
    id: "propertySize",
    name: "Building Description",
    sectionStateId: "propertySizeState",
  },
  {
    id: "permits",
    name: "Permits",
    sectionStateId: "permitsState",
  },
  {
    id: "inspections",
    name: "Inspections",
    sectionStateId: "inspectionsState",
  },
  {
    id: "finalAcceptance",
    name: "Final Acceptance",
    sectionStateId: "finalAcceptanceState",
  },
];

export const STEPS: Params[] = [
  { name: "Property Address", url: "1" },
  { name: "Applicant Validation", url: "2" },
  { name: "Existing CofO", url: "3" },
  { name: "Building Description", url: "4" },
  { name: "Permits", url: "5" },
  { name: "Summary and Submission", url: "5" },
];

export const getDetailsNavTabs = (isAdmin: boolean) => {
  return isAdmin ? APP_DETAILS_NAV_TABS_ADMIN : APP_DETAILS_NAV_TABS;
}

export const getSectionNameById = (id: string): string => {
  return (
    APP_DETAILS_REVIEWS_NAV_TABS.find((item) => item.id === id)?.name || ""
  );
};

export const getLinkToApplication = (item: Application): string => {
  if (item.state !== "draft") {
    return getLinkToDetails(item.id, APP_DETAILS_NAV_TABS[0].id);
  }
  return getLinkToStep(item.id, item.currentStep + 1);
};

export const getLinkToStep = (applicationId: string, step: number): string => {
  return `/applications/${applicationId}/step/${step}`;
};

export const getLinkToDetails = (
  applicationId: string,
  section: string
): string => {
  return `/applications/${applicationId}/details/${section}`;
};

export const getLinkToReviewDetails = (
  applicationId: string,
  section: string
): string => {
  return `/applications/${applicationId}/details/${APP_DETAILS_NAV_TABS[1].id}/${section}`;
};
