import { useAuthStore } from 'core/store/auth';
import { assetUrl } from 'helpers/assetUrl';
import DashboardButton from 'ui/components/dashboard-button/DashboardButton';

import { Box, Container, Grid, SxProps, Theme, Typography, useTheme } from '@mui/material';

import Footer from './components/Footer';
import RegisterButton from './RegisterButton';

const bgUrl = assetUrl(`/images/bgs/login-bg.jpg`);

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    header: {
      background: `#1B305B center / cover no-repeat url(${bgUrl})`,
      display: "flex",
      alignItems: "center",
      minHeight: 630,
    },
    subtitle: {
      mb: 2,
      color: "#fff",
      fontSize: "2.4rem",
      fontWeight: "bold",
    },

    listHeader: {
      mt: 0,
      mb: 2,
      color: theme.palette.primary.main,
      fontSize: "2rem",
      fontWeight: "bold",
      display: "flex",

      "&:before": {
        display: "flex",
        flexWidth: "32px",
        width: 32,
        height: 32,
        background: theme.palette.primary.main,
        color: "#fff",
        borderRadius: 16,
        fontSize: "1.4rem",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
        content: "counter(section-counter)",
        flexShrink: 0,
      },
    },

    listContainer: {
      mt: 4,
      counterReset: "section-counter",
      p: 0,
      listStyle: "none",
      "& li": {
        counterIncrement: "section-counter",
      },
    },

    listContent: {
      marginLeft: "42px",
      fontSize: "2rem",
    }
  };
};

export default function Login() {
  const theme = useTheme();
  const styles = getStyles(theme);
  const { currentUser } = useAuthStore();

  return (
    <>
      <Box sx={styles.header}>
        <Container>
          <Typography
            variant="h1"
            component="h1"
            className="with-indicator"
            sx={{ color: "#fff", span: {textTransform: "lowercase"} }}
          >
            Apply for a <br /> Certificate of <br /> Occupancy
            (C<span>of</span>O)
          </Typography>
          <Box sx={styles.subtitle}>
            with the DC Department of Buildings (DOB)
          </Box>

          {!currentUser && (
            <RegisterButton color="secondary" label="Sign in with Access DC" />
          )}
          {currentUser && <DashboardButton color="secondary" />}
        </Container>
      </Box>
      <Box sx={{ background: "#fff", flex: 1, pb: 3 }}>
        <Container sx={{ pt: 1, position: "relative" }}>
          <Typography
            variant="h1"
            component="h1"
            className="with-indicator centered"
            sx={{ mt: 4 }}
          >
            How it works
          </Typography>

          <Grid
            container
            columnSpacing={4}
            spacing={4}
            sx={styles.listContainer}
            component="ul"
          >
            <Grid component="li" item xs={12} sm={12} md={4} lg={4}>
              <Box component="h3" sx={styles.listHeader}>
                Apply
              </Box>
              <Box sx={styles.listContent}>
                Search by property address, validate your identity, and select
                application type. Fill out the application form, <a href='https://dob.dc.gov/service/get-certificate-occupancy'>upload all required documents,</a> and select a date for your required CofO
                inspections.
              </Box>
            </Grid>
            <Grid component="li" item xs={12} sm={12} md={4} lg={4}>
              <Box component="h3" sx={styles.listHeader}>
                DOB Reviews Your Application
              </Box>
              <Box sx={styles.listContent}>
                DOB will review your application, 
                and may request additional information from you. 
                After review has been successfully completed, 
                a draft CofO will be created for your approval.
              </Box>
            </Grid>
            <Grid component="li" item xs={12} sm={12} md={4} lg={4}>
              <Box component="h3" sx={styles.listHeader}>
                Pay Fee and Download CofO
              </Box>
              <Box sx={styles.listContent}>
                Once you approve the draft CofO, a payment request will be
                created. After you pay the fee, you may download the final
                version of your CofO.
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
