import { Params } from 'types';
import { NestedFormItem } from 'ui/form-fields/nested-fields/config';
import { parseDateNoTime } from 'ui/helpers/parseDate';

export interface NestedFieldsSummaryPlainTextItemProps {
  itemValue: any;
  item: NestedFormItem;
  inputName: string;
  collectionName: string;
}

function getSelectValue(itemId: string, dictionary: Params[]): string {
  return dictionary.find((item) => item.id === itemId)?.name || "";
}

export function NestedFieldsSummaryPlainTextItem(
  props: NestedFieldsSummaryPlainTextItemProps
) {
  const { itemValue, item } = props;

  if (item.isHidden) {
    return null;
  }

  const headBullet = " - ";
  const subBullet = "   - ";

  return (
    <>
      {item.type === "checkbox" && (
        <>
          {headBullet}
          {item.label}
        </>
      )}
      {item.type === "text" && (
        <>
          {subBullet}
          {item.label}: {itemValue} {item.adornment?.label || ""}
        </>
      )}
      {item.type === "select" && (
        <>
          {subBullet}
          {item.label}: {getSelectValue(itemValue, item.dictionary || [])}
        </>
      )}
      {item.type === "textarea" && (
        <>
          {subBullet}
          {item.label}: {itemValue},
        </>
      )}
      {item.type === "datepicker" && (
        <>
          {subBullet}
          {item.label}: {parseDateNoTime(itemValue)}
        </>
      )}
      {"\n"}
    </>
  );
}
