import { logout as apiLogout } from 'core/api/auth';
import { isRaygunEnabled } from 'core/errors/errors.lib';
import { useAuthStore } from 'core/store/auth';
import rg4js from 'raygun4js';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';

import { useQuery } from '@tanstack/react-query';

export default function Logout() {
  const navigate = useNavigate();
  const { logout, currentUser } = useAuthStore();

  const { isLoading, isSuccess } = useQuery({
    queryKey: ["logout"],
    queryFn: () => apiLogout(),
  });

  useEffect(() => {
    if (isSuccess) {
      if (isRaygunEnabled()) {
        rg4js("setUser", {
          identifier: currentUser?.email || "", // A unique ID, email address, or another ID such as a UUID
          isAnonymous: true, // Indicates whether the user is anonymous or not
        });
      }
      logout();
      navigate("/home?logged-out=true");
    }
  }, [isSuccess]);

  if (isLoading) {
    return <DataLoader />;
  }

  return null;
}
