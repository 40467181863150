import { getItemNameById } from 'app-sections/user/applications/steps/step4/components/change-type-picker/helpers';
import dayjs, { Dayjs } from 'dayjs';
import { Application, ApplicationState, Review, ReviewSectionStateId } from 'types';

const SECTIONS_FOR_PROGRESS: ReviewSectionStateId[] = [
  "applicantValidationState",
  "existingCOOState",
  "propertySizeState",
  "permitsState",
  "finalAcceptanceState",
];

const APP_COMPLETED_PROGRESS_STATES: ApplicationState[] = [
  "pending_payment",
  "certificate_draft_issued",
  "completed",
];

const COMPLETED_PROGRESS = 100;

export const countProgress = (
  reviews: Review[],
  application: Application
): number => {
  /**
   * Case right after application creation, when there are no reviews created yet
   * The value will be get by websockets
   **/
  if (!reviews || reviews.length === 0) {
    return 0;
  }

  const appProgress = countAppProgress(application);
  if (appProgress === COMPLETED_PROGRESS) {
    return appProgress;
  }
  return countSectionsProgress(reviews);
};

const countAppProgress = (application: Application): number => {
  if (APP_COMPLETED_PROGRESS_STATES.includes(application.state)) {
    return 100;
  }

  return 0;
};

const countSectionsProgress = (reviews: Review[]): number => {
  const progressStep = countProgressStep();
  const progress = SECTIONS_FOR_PROGRESS.reduce(
    (sum: number, currentValue: ReviewSectionStateId) => {
      const divisionalProgress = countDivisionWithinSectionProgress(
        reviews,
        currentValue,
        progressStep
      );

      return sum + divisionalProgress;
    },
    0
  );

  return Math.round(progress);
};

const countDivisionWithinSectionProgress = (
  reviews: Review[],
  sectionId: ReviewSectionStateId,
  progressStep: number
): number => {
  const approved = reviews.filter((item) => item[sectionId] === "approved");
  const fullLength = reviews.length;

  return progressStep * (approved.length / fullLength);
};

const countProgressStep = (): number => {
  return COMPLETED_PROGRESS / SECTIONS_FOR_PROGRESS.length;
};

export const getApplicationSecondaryType = (app: Application): string => {
  if (app.typeOfApplication === "change") {
    const selected = app.propertyChangeTypes || [];
    return selected
      .map((itemId) => {
        return getItemNameById(itemId);
      })
      .join(", ");
  }

  return "";
};

export const getSlaDeadline = (data: Application): Dayjs => {
  let slaDiff: number;
  if (data.slaStoppedAt) {
    const slaStartedAt = dayjs(data.submittedAt).add(1, "d").hour(9).minute(0);
    if (dayjs(data.slaStoppedAt).isBefore(slaStartedAt, "day")) {
      slaDiff = dayjs(data.slaDeadlineAt).diff(slaStartedAt, "seconds");
    } else {
      slaDiff = dayjs(data.slaDeadlineAt).diff(data.slaStoppedAt, "seconds");
    }
    return dayjs().add(slaDiff, "seconds");
  }

  return dayjs(data.slaDeadlineAt);
};
