import Login from 'app-sections/auth/login/Login';
import Logout from 'app-sections/auth/logout/Logout';
import ApplicationDetailsLayout from 'core/layouts/components/application-details-layout/ApplicationDetailsLayout';
import NoSidebarLayout from 'core/layouts/components/no-sidebar-layout/NoSidebarLayout';
import PublicAppLayout from 'core/layouts/components/no-sidebar-layout/PublicAppLayout';
import { Navigate, RouteObject } from 'react-router-dom';

import { Container } from '@mui/material';

import ErrorPage from '../layouts/error-pages/ErrorPage';
import ApplicationsPublicResolver from './protected-routes/applications-resolver/ApplicationsPublicResolver';
import CheckIsLoggedIn from './public/check-is-logged-in/CheckIsLoggedIn';
import SwitchPublicCfoNumber from './public/SwitchPublicCfoNumber';
import { applicationDetailsRoutes } from './user/applicationDetailsRoutes';

export const commonRoutes: RouteObject[] = [
  {
    path: 'home',
    element: (
      <CheckIsLoggedIn>
        <PublicAppLayout />
      </CheckIsLoggedIn>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Login />,
      },
    ],
  },
  {
    path: 'auth',
    element: <NoSidebarLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'login',
        element: <Navigate replace to="/home" />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
    ],
  },
  {
    path: 'public',
    element: (
      <CheckIsLoggedIn>
        <PublicAppLayout />
      </CheckIsLoggedIn>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'cofo/:id',
        element: <SwitchPublicCfoNumber />,
        children: applicationDetailsRoutes,
      },
      {
        path: 'applications/:id/details',
        element: (
          <ApplicationsPublicResolver>
            <Container className="disableGutters">
              <ApplicationDetailsLayout />
            </Container>
          </ApplicationsPublicResolver>
        ),
        children: applicationDetailsRoutes,
      },
    ],
  },
];
