import { getDetailsNavTabs } from 'app-sections/user/applications/steps/config';
import { useAuthStore } from 'core/store/auth';
import { useUiStore } from 'core/store/ui';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Params } from 'types';
import { isAdmin } from 'ui/helpers/users';

import { Box, Tab, Tabs } from '@mui/material';

import { getIndexById, getSectionByPath } from './config';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DetailsTabs() {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuthStore();
  const { isPublicSection } = useUiStore();

  const [value, setValue] = useState(0);

  const isUserAdmin =
    isAdmin(currentUser?.userProfile.roles || []) && !isPublicSection;

  useEffect(() => {
    const index = getIndexById(
      getSectionByPath(location.pathname),
      isUserAdmin
    );
    if (index > -1) {
      setValue(index);
    }
  }, [location.key]);

  const detailsNavTabs = getDetailsNavTabs(isUserAdmin);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const section = detailsNavTabs[newValue].id;
    navigate(section);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Application details navigation"
        indicatorColor="secondary"
        className="uppercase"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {detailsNavTabs.map((item: Params, index: number) => (
          <Tab key={item.id} label={item.name} {...a11yProps(index)} />
        ))}
      </Tabs>
    </Box>
  );
}
