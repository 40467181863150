import { DRAWER_MINI_WIDTH } from 'core/config';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';

import { ButtonBase, SxProps, Theme, Tooltip, useTheme } from '@mui/material';
import { Box } from '@mui/system';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      textAlign: "left",
      width: "100%",
      fontSize: "1.6rem",
      lineHeight: 1,
      height: theme.spacing(6),
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
      color: theme.palette.text.primary,
      textDecoration: "none",
      position: "relative",
      fontFamily: theme.typography.fontFamily,
      overflow: "hidden",
      transition: "width 0.3s",
      "& .iconContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.primary.main,
        width: `${DRAWER_MINI_WIDTH}px`,
        zIndex: 20,
        flex: `0 0 ${DRAWER_MINI_WIDTH}px`,
      },
      "& .labelContainer": {
        display: "block",
        position: "relative",
        zIndex: 20,
        marginLeft: "-8px",
        opacity: 1,
        transition: "opacity 0.2s",
        whiteSpace: "break-spaces",
        flex: `0 0 180px`,
      },
      "&:hover, &.active": {
        "&:before": {
          position: "absolute",
          right: 0,
          top: 0,
          content: '""',
          display: "block",
          height: "100%",
          width: "calc(100% - 10px)",
          background: theme.palette.grey[200],
          borderRadius: "4px 0 0 4px",
          opacity: 1,
        },
      },
      "&.active": {
        "&:after": {
          position: "absolute",
          right: 0,
          top: 0,
          content: '""',
          display: "block",
          height: "100%",
          width: "4px",
          background: theme.palette.secondary.main,
          borderRadius: theme.shape.borderRadius,
          transition: "opacity 0.3s",
        },
      },
      '&[aria-disabled="true"]': {
        opacity: 0.5,
      },
      "&.is-short": {
        justifyContent: "left",
        paddingLeft: 0,
        width: `${DRAWER_MINI_WIDTH}px`,
        "& .labelContainer": {
          opacity: 0,
        },
        "&.active, &:hover": {
          "&:after": {
            opacity: 0,
          },
          "&:before": {
            right: "12px",
            width: "48px",
            height: "48px",
            borderRadius: "4px",
          },
        },
      },
    },
  };
};

const checkMatch = (pathname: string, pathToCheck: string) => {
  return matchPath(pathToCheck, pathname);
};

const getPathMatch = (
  pathname: string,
  pathToCheck: string | Array<string>
): boolean => {
  if (typeof pathToCheck === "string") {
    return checkMatch(pathname, pathToCheck) !== null;
  } else {
    return pathToCheck.some((path: string) => {
      return checkMatch(pathname, path) !== null;
    });
  }
};

// NAVIGATION
export interface INavigationElement {
  disabled: boolean;
  icon: JSX.Element;
  id: string;
  name: string;
  path: string | Array<string>;
  show?: boolean;
  url: string;
  isExternal?: boolean;
  settings?: boolean;
  tooltip?: string;
  handleClick?: () => void;
}

interface NavigationElementProps {
  element: INavigationElement;
  sidebarState?: boolean;
  isMobile?: boolean;
}
export default function NavigationElement(props: NavigationElementProps) {
  const { element, sidebarState, isMobile } = props;
  const theme = useTheme();
  const styles = getStyles(theme);

  const { pathname } = useLocation();

  const match = getPathMatch(pathname, element.path);

  const classes = `${match ? "active" : ""} ${
    element.disabled ? "disabled" : ""
  } ${!sidebarState && !isMobile ? "is-short" : ""}`;

  if (element?.isExternal) {
    return (
      <Tooltip
        title={!sidebarState && (element.name || element.tooltip)}
        aria-label={element.name}
        placement="right"
        arrow
      >
        <Box
          component="a"
          sx={styles.root}
          target="_blank"
          rel="noopener noreferrer"
          href={element.url}
          className={classes}
        >
          <span className="iconContainer">{element.icon}</span>
          <span className="labelContainer">{element.name}</span>
        </Box>
      </Tooltip>
    );
  }

  if (element?.handleClick) {
    return (
      <Tooltip
        title={!sidebarState && (element.name || element.tooltip)}
        aria-label={element.name}
        placement="right"
        arrow
      >
        <ButtonBase
          className={classes}
          disabled={element.disabled}
          sx={styles.root}
          onClick={() => {
            if (element.handleClick) {
              element.handleClick();
            }
          }}
        >
          <span className="iconContainer">{element.icon}</span>
          <span className="labelContainer">{element.name}</span>
        </ButtonBase>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={!sidebarState && !isMobile && (element.name || element.tooltip)}
      aria-label={element.name}
      placement="right"
      arrow
    >
      <ButtonBase
        component={RouterLink}
        to={element.url}
        className={classes}
        disabled={element.disabled}
        sx={styles.root}
      >
        <span className="iconContainer">{element.icon}</span>
        <span className="labelContainer">{element.name}</span>
      </ButtonBase>
    </Tooltip>
  );
}
