import { useWebSockets } from 'core/hooks/useWebsockets';
import { useEffect, useState } from 'react';
import { WebsocketMessage } from 'types';

import UpdatesListenerActions from '../updates-listener-actions/UpdatesListenerActions';

export interface UpdatesListenerProps {
  userId: string;
}

/**
 * Listen websockets for updates channel and split messages into actions
 * @param props
 * @returns
 */
export default function UpdatesListener(props: UpdatesListenerProps) {
  const { userId } = props;

  const [uniqueMessage, setUniqueMessage] = useState<WebsocketMessage>();

  const message = useWebSockets("updates", userId);

  useEffect(() => {
    if (message) {
      setUniqueMessage(message);
    }
  }, [message?.uuid]);

  if (!uniqueMessage) {
    return null;
  }

  if (uniqueMessage) {
    return <UpdatesListenerActions message={uniqueMessage} />;
  }

  return null;
}
