import { create } from 'zustand';

const SIDEBAR_STATE_KEY = 'sidebarState';

const getDefaultSidebarState = (): boolean => {
  const storedState = localStorage.getItem(SIDEBAR_STATE_KEY);
  return storedState === 'closed' ? false : true;
};
const setDefaultSidebarState = (state: boolean): void => {
  localStorage.setItem(SIDEBAR_STATE_KEY, state ? 'opened' : 'closed');
};

export interface UiStore {
  drawerState: boolean;
  isPublicSection: boolean;
  unreadNotificationCounter: number;
  setDrawerState: (drawerState: boolean) => void;
  setIsPublicSection: (state: boolean) => void;
  setUnreadCount: (count: number) => void;
  toggleDrawerState: () => void;
  // COLLAPSIBLE SIDEBAR
  sidebarState: boolean;
  setSidebarState: (state: boolean) => void;
  toggleSidebar: () => void;
}

export const useUiStore = create<UiStore>((set) => ({
  drawerState: false,
  isPublicSection: false,
  unreadNotificationCounter: 0,
  setDrawerState: (drawerState: boolean) => set(() => ({ drawerState: drawerState })),
  setUnreadCount: (count: number) => set(() => ({ unreadNotificationCounter: count })),
  toggleDrawerState: () =>
    set((state: UiStore) => ({
      drawerState: !state.drawerState,
    })),
  setIsPublicSection: (state: boolean) => set(() => ({ isPublicSection: state })),
  sidebarState: getDefaultSidebarState(),
  setSidebarState: (state: boolean) =>
    set(() => {
      setDefaultSidebarState(state);
      return { sidebarState: state };
    }),
  toggleSidebar: () =>
    set((state) => {
      setDefaultSidebarState(!state.sidebarState);
      return { sidebarState: !state.sidebarState };
    }),
}));
