import { Address } from 'types';

import { Box } from '@mui/material';

export interface AddressFormattedProps {
  address: Address;
  commaSeparated?: boolean;
  isUppercase?: boolean;
  isInline?: boolean;
}

export default function AddressFormatted(props: AddressFormattedProps) {
  const {
    address,
    commaSeparated = true,
    isUppercase = false,
    isInline = false,
  } = props;

  const streetNumber = address.streetNumber || "";
  const unitNumber = address.unitNumber || "";
  const zip = address.zip || "";

  const firstLineAddress = `
      ${streetNumber}
      ${address.streetNumberFraction || ""}
      ${address.streetPrefix || ""}
      ${address.streetName || ""} ${address.streetNameSuffix || ""}
      ${address.quadrant}`;

  const secondLineAddress = unitNumber && `UNIT ${unitNumber}`;
  const thirdLineAddress = `
    ${address.city || "WASHINGTON"}
    ${address.state || "DC"}
    ${zip || ""}`;

  return (
    <Box
      display={isInline ? "inline" : "block"}
      sx={isUppercase ? { textTransform: "uppercase" } : {}}
    >
      {!commaSeparated && (
        <>
          {firstLineAddress}
          <br />
          {secondLineAddress && (
            <>
              {secondLineAddress}
              <br />
            </>
          )}
          {thirdLineAddress}
        </>
      )}
      {commaSeparated && (
        <>
          {firstLineAddress}, {secondLineAddress && <>{secondLineAddress},</>}
          {thirdLineAddress}
        </>
      )}
    </Box>
  );
}
