import { get } from 'lodash-es';
import { Fragment } from 'react';
import { Params } from 'types';

import { NestedFormItem } from '../nested-fields/config';
import { NestedFieldsSummaryPlainTextItem } from './components/nested-fields-summary-pain-text-item/NestedFieldsSummaryPlainTextItem';
import { NestedItemsSummaryGroup } from './components/nested-items-summary-group/NestedItemsSummaryGroup';

export interface NestedFieldsSummaryPlainTextProps {
  data: Params;
  collectionName: string;
  childCollectionName?: string;
  isParentExtendable?: Params | null;
  config: NestedFormItem[];
}

export default function NestedFieldsSummaryPlainText(
  props: NestedFieldsSummaryPlainTextProps
) {
  const { data, collectionName, config } = props;

  return (
    <>
      {config.map((item: NestedFormItem) => {
        const fieldName = item.id;
        if (!fieldName) {
          console.error("Field name not found");
          return null;
        }

        const fieldConfig = item;
        const inputName = `${collectionName}.${fieldName}`;
        const childrenName = `${inputName}Items`;

        const hasNestedChildren =
          fieldConfig.hasChildren &&
          fieldConfig.childrenType === "array" &&
          fieldConfig.hasChildren.length > 0;

        const hasGroupChildren =
          fieldConfig.hasChildren &&
          fieldConfig.childrenType === "group" &&
          fieldConfig.hasChildren.length > 0;

        const itemValue = get(data, inputName);

        if (!itemValue) {
          return null;
        }

        return (
          <Fragment key={`${item.id}-prp`}>
            <NestedFieldsSummaryPlainTextItem
              itemValue={itemValue}
              item={item}
              inputName={inputName}
              collectionName={collectionName}
            />

            {fieldConfig.hasChildren && (
              <>
                {hasGroupChildren && (
                  <NestedItemsSummaryGroup
                    data={data}
                    config={item.hasChildren || []}
                    collectionName={childrenName}
                    isParentExtendable={fieldConfig.isExtendable}
                  />
                )}
                {hasNestedChildren && (
                  <>
                    <NestedFieldsSummaryPlainText
                      data={data}
                      collectionName={childrenName}
                      isParentExtendable={fieldConfig.isExtendable}
                      config={item.hasChildren || []}
                    />
                  </>
                )}
              </>
            )}
          </Fragment>
        );
      })}
    </>
  );
}
