import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ProfileIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 15C14.8995 15 17.25 12.6495 17.25 9.75C17.25 6.85051 14.8995 4.5 12 4.5C9.10051 4.5 6.75 6.85051 6.75 9.75C6.75 12.6495 9.10051 15 12 15Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9129 20.8761C17.0835 19.0352 14.5953 18 11.9999 18C9.40454 18 6.91637 19.0352 5.08691 20.8761"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
