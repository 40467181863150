import { getCurrentUserData } from 'core/api/auth';
import { isRaygunEnabled } from 'core/errors/errors.lib';
import { useAuthStore } from 'core/store/auth';
import { useUiStore } from 'core/store/ui';
import rg4js from 'raygun4js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRole } from 'types';
import FullPageLoader from 'ui/components/full-page-loader/FullPageLoader';

import { useQuery } from '@tanstack/react-query';

export interface MustBeLoggedInProps {
  children: any;
  role: UserRole;
}

export default function MustBeLoggedIn(props: MustBeLoggedInProps) {
  const { children, role } = props;

  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useAuthStore();
  const { setIsPublicSection } = useUiStore();

  const [redirect, setRedirect] = useState<string>('');

  useEffect(() => {
    if (redirect !== '') {
      setRedirect('');
      navigate(redirect);
    }
  }, [redirect, navigate]);

  const { isLoading, isError, isSuccess, data } = useQuery({
    queryKey: ['auth'],
    queryFn: () => getCurrentUserData(),
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (isSuccess) {
      setIsPublicSection(false);
      if (
        // eliminate unnecessary re-renders
        currentUser?.id !== data.user.id ||
        currentUser.swoLiftedApplications.length !== data.user.swoLiftedApplications.length
      ) {
        setCurrentUser(data.user);
        if (isRaygunEnabled()) {
          rg4js('setUser', {
            identifier: data.user.email, // A unique ID, email address, or another ID such as a UUID
            isAnonymous: false, // Indicates whether the user is anonymous or not
            uuid: data.user.id, // Optional device identifier
          });
        }
      }
      if (!data.user.userProfile.roles.includes(role)) {
        if (role === 'client') {
          setRedirect('/admin');
        } else {
          setRedirect('/');
        }
      }
    }
  }, [isSuccess, JSON.stringify(data?.user)]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!isLoading && isError) {
    navigate('/home');
    return null;
  }

  return <>{children}</>;
}
