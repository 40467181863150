import { DRAWER_MINI_WIDTH, DRAWER_WIDTH } from 'core/config';
import { useUiStore } from 'core/store/ui';

import { Box, CSSObject, styled, SxProps, Theme, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

export interface CollapsibleDrawerProps {
  children: any;
}

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    root: {
      position: "relative",
      height: "100%",
    },
  };
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${DRAWER_MINI_WIDTH}px + 1px)`,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function CollapsibleDrawer(props: CollapsibleDrawerProps) {
  const { children } = props;

  const theme = useTheme();
  const styles = getStyles(theme);
  const { sidebarState } = useUiStore();

  return (
    <Drawer open={sidebarState} variant="permanent">
      <Box sx={styles.root}>{children}</Box>
    </Drawer>
  );
}
