import { getCurrentUserData } from 'core/api/auth';
import { useAuthStore } from 'core/store/auth';
import { useUiStore } from 'core/store/ui';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

export interface CheckIsLoggedInProps {
  children: any;
}

export default function CheckIsLoggedIn(props: CheckIsLoggedInProps) {
  const { children } = props;

  const { setCurrentUser } = useAuthStore();
  const { setIsPublicSection } = useUiStore();

  const [searchParams] = useSearchParams();

  const { isSuccess, isError, data } = useQuery({
    queryKey: ["auth"],
    queryFn: () => getCurrentUserData(),
    enabled: !Boolean(searchParams.get("logged-out")),
    retry: 0,
  });

  useEffect(() => {
    if (isSuccess && !Boolean(searchParams.get("logged-out"))) {
      setCurrentUser(data.user);
      setIsPublicSection(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setIsPublicSection(true);
    }
  }, [isError]);

  return <>{children}</>;
}
