import { CHANGE_TYPES, ChangeType, ChangeTypeOption } from "../../config";

export const getItemNameById = (id: ChangeType): string => {
  return (
    CHANGE_TYPES.find((item: ChangeTypeOption) => item.id === id)?.name || ""
  );
};

export const renderValues = (selected: ChangeType[]): string => {
  if (selected.length === 0) {
    return "- Select -";
  }
  return selected
    .map((itemId) => {
      return getItemNameById(itemId);
    })
    .join(", ");
};
