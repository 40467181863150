import { updateOneCacheItem } from 'core/cache/helpers';
import { Application, Params, WebsocketMessage } from 'types';

import { useQueryClient } from '@tanstack/react-query';

export interface ApplicationSwoUpdateProps {
  message: WebsocketMessage;
}

/**
 * For Application updates
 * @param props
 * @returns
 */
export default function ApplicationSwoUpdate(props: ApplicationSwoUpdateProps) {
  const { message } = props;

  const queryClient = useQueryClient();

  const queryKey = ['applications', message.model.id];

  updateOneCacheItem<Params>(queryClient, ['application/check-swo', message.model.id], {
    id: message.model.id,
    isChecked: message.uuid,
    hasSwo: message.model.state === 'swo',
    isLoading: false,
  });

  updateOneCacheItem<Application>(queryClient, queryKey, message.model);

  return null;
}
