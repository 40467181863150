import { getNotifications } from 'core/api/notifications';
import { useUiStore } from 'core/store/ui';
import React from 'react';
import { Link } from 'react-router-dom';
import ErrorsList from 'ui/components/errors-list/ErrorsList';
import InfoMessage from 'ui/components/info-message/InfoMessage';
import LinesSkeleton from 'ui/components/skeletons/lines-skeleton/LinesSkeleton';
import BellIcon from 'ui/icons/BellIcon';

import { Badge, Box, Button, IconButton, Menu, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import NotificationItemSmall from '../notification-item-small/NotificationItemSmall';

interface NotificationsPanelProps {
  userId: string;
  isForAdmin?: boolean;
}

export default function NotificationsPanel(props: NotificationsPanelProps) {
  const { isForAdmin = false, userId } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { unreadNotificationCounter } = useUiStore();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const queryKey = ["notifications/get", "panel"];
  const queryClient = useQueryClient();

  const { isLoading, isFetching, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => getNotifications({ page: "1", limit: 4 }),
  });

  const urlPrefix = isForAdmin ? "/admin/" : "";

  const fetchNotifications = () => {
    queryClient.fetchQuery({
      queryKey: queryKey,
      queryFn: () => getNotifications({ page: "1", limit: 4 }),
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    fetchNotifications();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const forAdminUrl = isForAdmin ? "admin/" : "";

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          textAlign: "center",
          position: "relative",
          height: "64px",
          ml: 2,
        }}
      >
        {anchorEl !== null && (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "4px",
              borderRadius: "20px",
              bgcolor: "#AD3838",
              bottom: 0,
            }}
          ></Box>
        )}
        <Tooltip title="Notifications">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableRipple={true}
          >
            <Badge badgeContent={unreadNotificationCounter} color="error">
              <BellIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 10px 30px rgba(52, 61, 75, 0.3))",
            bgcolor: "#F2F3F4",
            mt: 3,
            width: matches ? "340px" : "400px",

            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 12,
              width: 10,
              height: 10,
              bgcolor: "#F2F3F4",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box p={2}>
          <Box
            mb={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              component="h3"
              variant="h3"
              sx={{ fontSize: "2rem", textTransform: "none", mr: 2 }}
            >
              Notifications
            </Typography>

            <Button
              type="button"
              variant="contained"
              color="primary"
              disableElevation
              component={Link}
              to={`/${forAdminUrl}notifications`}
            >
              Show More
            </Button>
          </Box>

          <Box
            sx={{
              width: "100%",
              overflow: "auto",
              maxHeight: "calc(100vh - 200px)",
            }}
          >
            <>
              {(isLoading || isFetching) && !isError && (
                <Box mb={4} width={368}>
                  <LinesSkeleton
                    elements={4}
                    isWithButtons={false}
                    lineHeight={75}
                    spacing={2}
                  />
                </Box>
              )}

              {!isLoading && data?.results.length === 0 && (
                <Box minWidth="368px">
                  <InfoMessage
                    type="neutral-inversed"
                    notClosable={true}
                    isSlim={true}
                    title=""
                    content="No notifications there"
                  />
                </Box>
              )}

              {!isLoading &&
                !isFetching &&
                !isError &&
                data?.results.map((item) => (
                  <NotificationItemSmall
                    data={item}
                    key={item.id}
                    urlPrefix={urlPrefix}
                    userId={userId}
                  />
                ))}

              {isError && error && (
                <ErrorsList
                  title="Unexpected loading error"
                  errors={error as string[]}
                />
              )}
            </>
          </Box>
        </Box>
      </Menu>
    </>
  );
}
