import { useUiStore } from 'core/store/ui';

import { SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import NavigationElement, { INavigationElement } from './NavigationElement';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(5),
      flex: 1,
      overflow: "hidden",
      width: "100%",
      "& .settingsPanel": {
        position: "relative",
        borderTop: `1px solid ${theme.palette.divider}`,
        overflow: "hidden",
        "&:before": {
          position: "absolute",
          top: 0,
          left: 0,
          content: '""',
          display: "block",
          width: `calc(100% - ${theme.spacing(3)}px)`,
          height: 1,
          background: theme.palette.divider,
        },
      },
    },
  };
};

interface NavigationProps {
  navigation: INavigationElement[];
}
export default function Navigation(props: NavigationProps) {
  const { toggleDrawerState, sidebarState } = useUiStore();
  const theme = useTheme();
  const styles = getStyles(theme);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { navigation } = props;

  const settings = navigation.filter((item: INavigationElement) => {
    return item.show && item.settings;
  });

  const nav = navigation.filter((item: INavigationElement) => {
    return item.show && !item.settings;
  });

  return (
    <Box sx={styles.root} onClick={() => isMobile && toggleDrawerState()}>
      <div className="main-panel">
        {nav.map((item: INavigationElement) => (
          <Box key={item.id} sx={{ marginBottom: "2px" }}>
            <NavigationElement
              element={item}
              isMobile={isMobile}
              sidebarState={sidebarState}
            />
          </Box>
        ))}
      </div>
      <Box sx={{ flex: 1 }}></Box>
      <div className="settings-panel">
        {settings.map((item: INavigationElement) => (
          <Box key={item.id} sx={{ marginBottom: "2px" }}>
            <NavigationElement
              element={item}
              isMobile={isMobile}
              sidebarState={sidebarState}
            />
          </Box>
        ))}
      </div>
    </Box>
  );
}
