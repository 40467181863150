import { getLinkToDetails } from 'app-sections/user/applications/steps/config';
import { getApplicationById } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import { useAuthStore } from 'core/store/auth';
import { useUiStore } from 'core/store/ui';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';
import ErrorsList from 'ui/components/errors-list/ErrorsList';

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

export interface ApplicationsPublicResolverProps {
  children: any;
}

export default function ApplicationsPublicResolver(
  props: ApplicationsPublicResolverProps
) {
  const { children } = props;
  const { id } = useParams();
  const { currentUser } = useAuthStore();
  const { setIsPublicSection } = useUiStore();
  const navigate = useNavigate();

  const { setActiveApplicationId } = useApplicationsStore();
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    setActiveApplicationId(id || "");
    setIsValid(false);
  }, [id]);

  const { isSuccess, isLoading, isError, error, data } = useQuery({
    queryKey: ["applications", id],
    queryFn: () => getApplicationById(id ?? ""),
    enabled: id !== "",
    gcTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (isSuccess) {
      setIsValid(true);

      // if user is logged if user is creator of application
      // redirect to regular view instead of public
      if (currentUser && currentUser.id === data.clientId && id) {
        setIsPublicSection(false);
        navigate(getLinkToDetails(id, ""));
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      // just to show error message
      setIsValid(true);
    }
  }, [isError]);

  if (isLoading || !isValid) {
    return (
      <Box mt={4}>
        <DataLoader />
      </Box>
    );
  }

  if (!isLoading && isError) {
    return (
      <Box p={3}>
        <ErrorsList
          title="Unexpected loading error"
          errors={error as string[]}
        />
      </Box>
    );
  }

  return <>{children}</>;
}
