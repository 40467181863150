import { APP_VER, DRAWER_MINI_WIDTH, DRAWER_WIDTH } from 'core/config';
import { useAuthStore } from 'core/store/auth';
import { useUiStore } from 'core/store/ui';
import { isAdmin } from 'ui/helpers/users';
import ArrowLeftIcon from 'ui/icons/ArrowLeftIcon';

import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';

import { getNavigationConfig } from '../navigation/config';
import Navigation from '../navigation/Navigation';
import AppNavbarToggleButton from './app-navbar-toggle-button/AppNavbarToggleButton';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    bar: {
      width: `${DRAWER_WIDTH}px`,
      position: "relative",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: theme.spacing(8),
      background: theme.palette.secondary.main,

      "&.admin": {
        background: "#535863",
      },
    },
    backButtonContainer: {
      color: theme.palette.background.paper,
      height: "100%",
      position: "relative",
    },
    navigationContainer: {
      display: "flex",
      flexGrow: 1,
      width: "100%",
      overflowY: "auto",
      overflowX: "hidden",
    },
    userNameHeader: {
      textTransform: "capitalize",
      zIndex: 2,
      textAlign: "right",
      width: `calc(100% - ${DRAWER_MINI_WIDTH}px)`,
      padding: theme.spacing(1),
      color: theme.palette.common.white,
      fontSize: "1.4rem",
      fontWeight: 700,
    },
    appVer: {
      position: "absolute",
      fontSize: "1rem",
      opacity: "0.7",
      top: "64px",
      right: 0,
      width: "60px",
      textAlign: "right",
    },
  };
};

export default function AppNavbar() {
  const theme = useTheme();
  const styles = getStyles(theme);

  const { currentUser } = useAuthStore();
  const { sidebarState, drawerState } = useUiStore();

  let isUserAdmin = false;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (currentUser) {
    isUserAdmin = isAdmin(currentUser.userProfile.roles);
  }

  return (
    <Box sx={styles.root}>
      <AppBar elevation={0} color="transparent" position="static">
        <Box sx={styles.bar} className={isUserAdmin ? "admin" : ""}>
          <Box sx={styles.backButtonContainer}>
            <AppNavbarToggleButton>
              {(sidebarState && !isMobile) || (drawerState && isMobile) ? (
                <ArrowLeftIcon />
              ) : (
                <MenuIcon />
              )}
            </AppNavbarToggleButton>
          </Box>

          <Box sx={styles.userNameHeader}>
            {currentUser && <>{currentUser.userProfile.fullName}</>}
          </Box>
        </Box>
      </AppBar>

      <Box sx={styles.appVer}>
        v{APP_VER} [{currentUser?.id}]
      </Box>

      <Box sx={styles.navigationContainer}>
        {currentUser && (
          <Navigation
            navigation={getNavigationConfig(
              currentUser.userProfile.roles,
              currentUser.userProfile.id
            )}
          />
        )}
      </Box>
    </Box>
  );
}
