import { IMask } from 'react-imask';

export const cleanMaskedValues = (value: string): string => {
  return value.replace(/\s+/g, "").replace(/-/g, "").replace(/_/g, "");
};

export const formatPhoneNumber = (value: string): string => {
  let v = value;
  if (!v) {
    return "";
  }

  if (typeof v !== "string") {
    v = String(v);
  }

  const phoneMasker = IMask.createMask({
    mask: "+1 000-000-0000",
  });
  phoneMasker.resolve(v);

  return phoneMasker.value;
};
