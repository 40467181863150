import { getReviewsPaginated } from 'core/api/reviews';
import { Params } from 'types';

export const DEFAULT_COLUMNS_VISIBILITY = {
  pendingAssignment: false,
  notStarted: true,
  inProgress: true,
  updateNeeded: true,
};

export type ColumnId =
  | "pendingAssignment"
  | "notStarted"
  | "inProgress"
  | "updateNeeded";

export type ColumnParams = {
  [key in ColumnId]: Params;
};

export const getColumnConfig = (
  userId: string,
  divisionName: string
): ColumnParams => {
  return {
    pendingAssignment: {
      title: "Pending assignment",
      count: 0,
      isLoadMore: 0,
      isLoading: { isFetchingNextPage: false, isLoading: false },
      color: "#3b414d",
      endpointFn: getReviewsPaginated,
      cacheQueryKey: "pendingAssignment/get",
      requestParams: {
        divisionName: divisionName,
        reviewerIdExists: false,
        notShowOnDashboard: false,
        sortColumn: "created_at",
      },
    },
    notStarted: {
      title: "Not started",
      count: 0,
      isLoadMore: 0,
      isLoading: { isFetchingNextPage: false, isLoading: false },
      color: "#213a6d",
      endpointFn: getReviewsPaginated,
      cacheQueryKey: "notStarted/get",
      requestParams: {
        reviewerId: userId,
        anyActionTaken: false,
        notShowOnDashboard: false,
        sortColumn: "created_at",
      },
    },
    inProgress: {
      title: "In progress",
      count: 0,
      isLoadMore: 0,
      isLoading: { isFetchingNextPage: false, isLoading: false },
      color: "#0062a0",
      endpointFn: getReviewsPaginated,
      cacheQueryKey: "inProgress/get",
      requestParams: {
        reviewerId: userId,
        anyActionTaken: true,
        anyActionNeeded: "false",
        notShowOnDashboard: false,
        sortColumn: "created_at",
      },
    },
    updateNeeded: {
      title: "Update needed",
      count: 0,
      isLoadMore: 0,
      isLoading: { isFetchingNextPage: false, isLoading: false },
      color: "#b05e11",
      endpointFn: getReviewsPaginated,
      cacheQueryKey: "updateNeeded/get",
      requestParams: {
        reviewerId: userId,
        anyActionNeeded: true,
        notShowOnDashboard: false,
        sortColumn: "updated_at",
      },
    },
  };
};
