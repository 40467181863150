import { ApiCollectionResponse } from 'types';

import { QueryClient, QueryKey } from '@tanstack/react-query';

export function updateCacheItem<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  data: T,
  field: keyof T
): void {
  const previousState = queryClient.getQueryData(queryKey) as T[];

  if (!previousState) {
    return;
  }

  const currentState = previousState.map((item: T) => {
    if (item[field] === data[field]) {
      return { ...item, ...data };
    }
    return item;
  });
  queryClient.setQueryData(queryKey, currentState);
}

export function addCacheItem<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  data: T
): void {
  const previousState = queryClient.getQueryData(queryKey) as T[];

  if (!previousState) {
    return;
  }

  const currentState = [data, ...previousState];
  queryClient.setQueryData(queryKey, currentState);
}

export function removeCacheItem<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  data: T,
  field: keyof T
): void {
  const previousState = queryClient.getQueryData(queryKey) as T[];

  if (!previousState) {
    return;
  }

  const currentState = previousState.filter(
    (item: T) => item[field] !== data[field]
  );
  queryClient.setQueryData(queryKey, currentState);
}

export function updateCacheItemInPaginatedResults<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  data: T,
  field: keyof T
): void {
  const previousState = queryClient.getQueryData(
    queryKey
  ) as ApiCollectionResponse<T>;

  if (!previousState) {
    return;
  }

  const currentState = {
    ...previousState,
    results: previousState.results.map((item: T) => {
      if (item[field] === data[field]) {
        return { ...item, ...data };
      }
      return item;
    }),
  };
  queryClient.setQueryData(queryKey, currentState);
}

export function removeCacheItemInPaginatedResults<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  data: T,
  field: keyof T
): void {
  const previousState = queryClient.getQueryData(
    queryKey
  ) as ApiCollectionResponse<T>;

  if (!previousState) {
    return;
  }

  const currentState = {
    ...previousState,
    results: previousState.results.filter(
      (item: T) => item[field] !== data[field]
    ),
  };
  queryClient.setQueryData(queryKey, currentState);
}

export function addCacheItemInPaginatedResults<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  data: T
): void {
  const previousState = queryClient.getQueryData(
    queryKey
  ) as ApiCollectionResponse<T>;

  if (!previousState) {
    return;
  }

  const currentState = {
    ...previousState,
    results: [data, ...previousState.results],
  };

  queryClient.setQueryData(queryKey, currentState);
}

export function updateOneCacheItem<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  data: Partial<T>
): void {
  const previousState = queryClient.getQueryData(queryKey) as T;

  if (!previousState) {
    return;
  }

  const currentState = { ...previousState, ...data };
  queryClient.setQueryData(queryKey, currentState);
}

/**
 * Replace cache key item with new data
 * @param queryClient
 * @param queryKey
 * @param data
 * @returns
 */
export function setCache<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  data: T
): void {
  const previousState = queryClient.getQueryData(queryKey);

  if (!previousState) {
    return;
  }

  queryClient.setQueryData(queryKey, data);
}
