import DebugLink from 'app-sections/common/applications/details/components/details-header/components/debug-link/DebugLink';
import { useApplicationsStore } from 'core/store/applications';

import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';

import HorizontalStepper from '../stepper/Stepper';
import SummaryHeaderWrapper from '../summary-header-wrapper/SummaryHeaderWrapper';
import SwoWrapper from '../swo-wrapper/SwoWrapper';

export default function StepsHeader() {
  const { activeApplicationId, activeStep, latestValidStep } =
    useApplicationsStore();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box mt={5}>
      <Container className="disable-center">
        <Typography variant="h1" component="h1" className="with-indicator">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            Apply for Certificate of Occupancy <DebugLink />
          </Box>
        </Typography>
        <Box mt={6} mb={matches ? 4 : 0}>
          <SwoWrapper />
          <HorizontalStepper
            activeStep={activeStep}
            latestValidStep={latestValidStep}
          />
        </Box>
        {activeApplicationId && <SummaryHeaderWrapper />}
      </Container>
    </Box>
  );
}
