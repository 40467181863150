import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ClearIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 10 10" fill="none">
      <path
        d="M9.66634 1.2735L8.72634 0.333496L4.99967 4.06016L1.27301 0.333496L0.333008 1.2735L4.05967 5.00016L0.333008 8.72683L1.27301 9.66683L4.99967 5.94016L8.72634 9.66683L9.66634 8.72683L5.93967 5.00016L9.66634 1.2735Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
