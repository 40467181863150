import { DEFAULT_COLUMNS_VISIBILITY } from "app-sections/admin/dashboard-new/components/config";
import { Params } from "types";
import { create } from "zustand";

const COLUMNS_STATE_KEY = "columnsState";

export const saveColumnsState = (state: Params): void => {
  localStorage.setItem(COLUMNS_STATE_KEY, JSON.stringify(state));
};

export const getColumnsState = (): Params => {
  const state = localStorage.getItem(COLUMNS_STATE_KEY);
  if (state) {
    return JSON.parse(state);
  }
  return DEFAULT_COLUMNS_VISIBILITY;
};

export interface DashboardStore {
  columnsRefresh: string;
  dashboardFilterDrawerState: boolean;
  columnsState: Params;
  toggleDashboardFilterDrawerState: () => void;
  setColumnsState: (state: Params) => void;
  setColumnRefresh: (state: string) => void;
}

export const useDashboardStore = create<DashboardStore>((set) => ({
  columnsRefresh: "",
  columnsState: getColumnsState(),
  dashboardFilterDrawerState: false,
  toggleDashboardFilterDrawerState: () =>
    set((state: DashboardStore) => ({
      dashboardFilterDrawerState: !state.dashboardFilterDrawerState,
    })),
  setColumnsState: (state: Params) => {
    saveColumnsState(state);
    return set(() => ({ columnsState: state }));
  },
  setColumnRefresh: (state: string) => {
    return set(() => ({ columnsRefresh: state }));
  },
}));
