import {
  getIndexById as _getIndexById,
  getSectionByPath as _getSectionByPath,
} from 'app-sections/common/tabs/tabsHelpers';
import { getDetailsNavTabs } from 'app-sections/user/applications/steps/config';

export const getIndexById = (id: string, isUserAdmin: boolean): number => {
  const detailTabs = getDetailsNavTabs(isUserAdmin);
  return _getIndexById(id, detailTabs);
};

export const getSectionByPath = (path: string): string => {
  return _getSectionByPath(path, 'details');
};
