import { Fragment } from 'react';

import { Box, Grid, useTheme } from '@mui/material';

export interface SummaryTextGridItem {
  label: string;
  desc: string | JSX.Element;
}

export interface SummaryTextGridProps {
  items: SummaryTextGridItem[];
  firstColumnWidth?: number;
}

export default function SummaryTextGrid(props: SummaryTextGridProps) {
  const { items, firstColumnWidth = 4 } = props;

  const theme = useTheme();

  const secondColumnWidth = 12 - firstColumnWidth;

  return (
    <Grid container spacing={1}>
      {items.map((item, index) => (
        <Fragment key={index}>
          <Grid
            item
            xs={12}
            sm={12}
            md={firstColumnWidth}
            lg={firstColumnWidth}
          >
            <Box fontSize="2rem">{item.label}</Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={secondColumnWidth}
            lg={secondColumnWidth}
          >
            <Box display="flex" minWidth={1}>
              <Box fontSize="2rem">-&nbsp;</Box>
              {(typeof item.desc === "string" ||
                typeof item.desc === "number") && (
                <Box
                  component="strong"
                  fontSize="2rem"
                  color={theme.palette.primary.main}
                  sx={{
                    wordWrap: "break-word",
                    display: "block",
                    maxWidth: "100%",
                  }}
                >
                  {item.desc}
                </Box>
              )}
              {typeof item.desc !== "string" &&
                typeof item.desc !== "number" && <>{item.desc}</>}
            </Box>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
}
