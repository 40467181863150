import { NestedFormItem } from 'ui/form-fields/nested-fields/config';

export const DWELLING_UNITS_FIELD_SCHEMA: NestedFormItem[] = [
  {
    id: "inclusionaryZoningUnits",
    label:
      "Inclusionary Zoning Units out of the total number of dwelling units",
    type: "checkbox",
    isExtendable: null,
    childrenType: "array",
    hasChildren: [
      {
        id: "izItemsCount",
        label: "Number",
        type: "text",
        isExtendable: null,
        hasChildren: null,
      },
    ],
  },
  {
    id: "affordableZoningUnits",
    label: "Non-Inclusionary Zoning Affordable Units out of the total Number of Dwelling Units",
    type: "checkbox",
    isExtendable: null,
    childrenType: "array",
    hasChildren: [
      {
        id: "azItemsCount",
        label: "Number",
        type: "text",
        isExtendable: null,
        hasChildren: null,
      },
    ],
  },
];
