import { UserRole } from 'types';
import { isAdmin } from 'ui/helpers/users';

import { getAdminNavigation } from './admin-config/admin';
import { INavigationElement } from './NavigationElement';
import { getUserNavigation } from './users-config/user';

export const getNavigationConfig = (
  roles: UserRole[],
  id: string
): INavigationElement[] => {
  if (isAdmin(roles)) {
    return getAdminNavigation(id);
  }

  return getUserNavigation();
};
