import { SvgIcon, SvgIconProps } from '@mui/material';

export default function AddIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 22" fill="none">
      <path
        d="M1.625 11H20.375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M11 1.625V20.375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
