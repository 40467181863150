import { Params } from 'types';

// export type LoadType = "residents" | "childrenStaff" | "persons" | "seatsPersons" | "";

export const SPECIFIC_BUILDING_CODE_CATEGORY_WITH_DESC = [
  "otherSpecify",
  "mixedUseSpecify",
];

export const LOAD_TYPES: Params[] = [
  { name: "residents", id: "residents" },
  { name: "children/staff", id: "childrenStaff" },
  { name: "persons", id: "persons" },
  { name: "seats/persons", id: "seatsPersons" },
  { name: "beds", id: "beds" },
  { name: "rooms", id: "rooms" },
  { name: "parking spaces", id: "parkingSpaces" },
  { name: "dwelling units", id: "dwellingUnits" },
  { name: "boarders", id: "boarders" },
  { name: "beds", id: "beds" },
];

export const DEFAULT_LOAD_TYPE = { name: "occupants", id: "occupants" };

export const ZR16_USE_CATEGORY: Params[] = [
  { name: "Agriculture, Large", id: "agricultureLarge" },
  { name: "Agriculture, Residential", id: "agricultureResidential" },
  {
    name: "Animal Sales, Care, and Boarding",
    id: "animalSalesCareAndBoarding",
  },
  { name: "Antennas", id: "antennas" },
  { name: "Arts, Design, and Creation", id: "artsDesignAndCreation" },
  { name: "Utility, Basic", id: "basicUtilities" },
  { name: "Chancery", id: "chancery" },
  {
    name: "Community-Based Institutional Facility",
    id: "communityBasedInstitutionalFacility",
  },
  { name: "Daytime Care", id: "daytimeCare" },
  {
    name: "Eating and Drinking Establishment",
    id: "eatingAndDrinkingEstablishment",
  },
  { name: "Education Private", id: "educationPrivate" },
  { name: "Education Public", id: "educationPublic" },
  { name: "Education, College/University", id: "educationCollegeUniversity" },
  { name: "Emergency Shelter", id: "emergencyShelter" },
  {
    name: "Entertainment, Assembly, and Performing Art",
    id: "entertainmentAssemblyAndPerformingArt",
  },
  { name: "Firearm Sales", id: "firearmSales" },
  { name: "Government, Large Scale", id: "governmentLargeScale" },
  { name: "Government, Local", id: "governmentLocal" },
  { name: "Institutional, General", id: "institutionGeneral" },
  { name: "Institutional, Religious Based", id: "institutionalReligiousBased" },
  { name: "Lodging", id: "lodging" },
  { name: "Marine", id: "marine" },
  { name: "Medical Care", id: "medicalCare" },
  { name: "Motor Vehicle - Related", id: "motorVehicleRelated" },
  { name: "Office", id: "office" },
  { name: "Parking", id: "parking" },
  { name: "Parks and Recreation", id: "parksAndRecreation" },
  {
    name: "Production, Distribution, and Repair",
    id: "productionDistributionAndRepair",
  },
  { name: "Residential", id: "residential" },
  { name: "Retail", id: "retail" },
  { name: "Service, Financial", id: "serviceFinancial" },
  { name: "Service, General", id: "serviceGeneral" },
  { name: "Temporary Use", id: "temporaryUse" },
  { name: "Transportation Infrastructure", id: "transportationInfrastructure" },
  { name: "Waste-Related Services", id: "wasteRelatedServices" },
  {
    name: "Sexually-Oriented Business Establishment",
    id: "sexuallyOrientedBusinessEstablishment",
  },
];

export const SPECIFIC_ZONING_USE: Params[] = [
  // agricultureLarge
  {
    parentId: "agricultureLarge",
    id: "farmOrTruckGarden",
    name: "Farm or truck garden",
    loadType: null,
  },
  {
    parentId: "agricultureLarge",
    id: "greenhouseOrHorticulturalNursery",
    name: "Greenhouse or horticultural nursery",
    loadType: null,
  },
  {
    parentId: "agricultureLarge",
    id: "privateStable",
    name: "Private stable",
    loadType: null,
  },
  {
    parentId: "agricultureLarge",
    id: "stable",
    name: "Stable",
    loadType: null,
  },

  //agricultureResidential
  {
    parentId: "agricultureResidential",
    id: "communityGarden",
    name: "Community garden",
    loadType: null,
  },

  //animalSalesCareAndBoarding
  {
    parentId: "animalSalesCareAndBoarding",
    id: "animalBoarding",
    name: "Animal boarding",
    loadType: null,
  },
  {
    parentId: "animalSalesCareAndBoarding",
    id: "animalShelter",
    name: "Animal shelter",
    loadType: null,
  },
  {
    parentId: "animalSalesCareAndBoarding",
    id: "petCareEstablishmentIncludingSaleOfPetSuppliesGroomingTrimmingAndPetWalking",
    name: "Pet care establishment, including sale of pet supplies, grooming, trimming, and pet walking",
    loadType: null,
  },
  {
    parentId: "animalSalesCareAndBoarding",
    id: "petGroomingEstablishment",
    name: "Pet grooming establishment",
    loadType: null,
  },
  {
    parentId: "animalSalesCareAndBoarding",
    id: "petShopSaleOfLiveAnimals",
    name: "Pet shop (sale of live animals)",
    loadType: null,
  },
  {
    parentId: "animalSalesCareAndBoarding",
    id: "veterinaryBoardingHospital",
    name: "Veterinary boarding hospital",
    loadType: null,
  },
  {
    parentId: "animalSalesCareAndBoarding",
    id: "veterinaryHospital",
    name: "Veterinary hospital",
    loadType: null,
  },
  // antennas
  { parentId: "antennas", name: "Antenna", id: "antenna", loadType: null },
  {
    parentId: "antennas",
    name: "Radio or TV broadcasting studio (and antenna)",
    id: "radioOrTvBroadcastingStudioAndAntenna",
    loadType: null,
  },
  // artsDesignAndCreation
  {
    parentId: "artsDesignAndCreation",
    name: "Art center",
    id: "artCenter",
    loadType: null,
  },
  {
    parentId: "artsDesignAndCreation",
    name: "Artist studio",
    id: "artistStudio",
    loadType: null,
  },
  {
    parentId: "artsDesignAndCreation",
    name: "Craftsman or artisan studio, with sales",
    id: "craftsmanOrArtisanStudioWithSales",
    loadType: null,
  },
  // basicUtilities
  {
    parentId: "basicUtilities",
    name: "Electric substation or natural gas regulator station",
    id: "electricSubstationOrNaturalGasRegulatorStation",
    loadType: null,
  },
  {
    parentId: "basicUtilities",
    name: "Electronic Equipment Facility",
    id: "electronicEquipmentFacility",
    loadType: null,
  },
  {
    parentId: "basicUtilities",
    name: "Optical transmission node",
    id: "opticalTransmissionNode",
    loadType: null,
  },
  {
    parentId: "basicUtilities",
    name: "Public utility pumping station",
    id: "publicUtilityPumpingStation",
    loadType: null,
  },
  {
    parentId: "basicUtilities",
    name: "Utilities",
    id: "utilities",
    loadType: null,
  },
  {
    parentId: "basicUtilities",
    name: "Utility (pumping station)",
    id: "utilityPumpingStation",
    loadType: null,
  },
  // chancery
  { parentId: "chancery", name: "Chancery", id: "chancery", loadType: null },
  // communityBasedInstitutionalFacility
  {
    parentId: "communityBasedInstitutionalFacility",
    name: "CBRF (Adult rehab. home)",
    id: "cbrfAdultRehabHome",
    loadType: "residents",
  },
  {
    parentId: "communityBasedInstitutionalFacility",
    name: "CBRF (Youth rehab. home)",
    id: "cbrfYouthRehabHome",
    loadType: "residents",
  },
  // daytimeCare
  {
    parentId: "daytimeCare",
    name: "Adult day treatment facility",
    id: "adultDayTreatmentFacility",
    loadType: null,
  },
  {
    parentId: "daytimeCare",
    name: "Child development center",
    id: "childDevelopmentCenter",
    loadType: "childrenStaff",
  },
  {
    parentId: "daytimeCare",
    name: "Elderly development center ",
    id: "elderlyDevelopmentCenter",
    loadType: "persons",
  },
  // eatingAndDrinkingEstablishment
  {
    parentId: "eatingAndDrinkingEstablishment",
    name: "Prepared food shop",
    id: "preparedFoodShop",
    loadType: "seatsPersons",
  },
  {
    parentId: "eatingAndDrinkingEstablishment",
    name: "Restaurant",
    id: "restaurant",
    loadType: "seatsPersons",
  },
  {
    parentId: "eatingAndDrinkingEstablishment",
    name: "Bakery",
    id: "bakery",
    loadType: "seatsPersons",
  },
  {
    parentId: "eatingAndDrinkingEstablishment",
    name: "Bar or cocktail lounge",
    id: "barOrCocktailLounge",
    loadType: "seatsPersons",
  },
  {
    parentId: "eatingAndDrinkingEstablishment",
    name: "Catering establishment",
    id: "cateringEstablishment",
    loadType: null,
  },
  {
    parentId: "eatingAndDrinkingEstablishment",
    name: "Fast food establishment",
    id: "fastFoodEstablishment",
    loadType: "seatsPersons",
  },
  {
    parentId: "eatingAndDrinkingEstablishment",
    name: "Food delivery service (no drive-through)",
    id: "foodDeliveryServiceNoDriveThrough",
    loadType: null,
  },
  {
    parentId: "eatingAndDrinkingEstablishment",
    name: "Ice cream parlor",
    id: "iceCreamParlor",
    loadType: "seatsPersons",
  },
  // educationPrivate
  {
    parentId: "educationPrivate",
    name: "School, private [elementary and/or middle school]",
    id: "schoolPrivateElementaryAndOrMiddleSchool",
    loadType: "childrenStaff",
  },
  {
    parentId: "educationPrivate",
    name: "School, private [high school]",
    id: "schoolPrivateHighSchool",
    loadType: "seatsPersons",
  },
  {
    parentId: "educationPrivate",
    name: "Art or performing arts school",
    id: "artOrPerformingArtsSchool",
    loadType: "seatsPersons",
  },
  {
    parentId: "educationPrivate",
    name: "School, trade",
    id: "schoolTrade",
    loadType: "seatsPersons",
  },
  // educationPublic
  {
    parentId: "educationPublic",
    name: "School, public",
    id: "schoolPublic",
    loadType: "childrenStaff",
  },
  // educationCollegeUniversity
  {
    parentId: "educationCollegeUniversity",
    name: "College or university",
    id: "collegeOrUniversity",
    loadType: "seatsPersons",
  },
  {
    parentId: "educationCollegeUniversity",
    name: "Dormitory",
    id: "dormitory",
    loadType: "beds",
  },

  // emergencyShelter
  {
    parentId: "emergencyShelter",
    name: "CBRF (Emergency shelter)",
    id: "cbrfEmergencyShelter",
    loadType: "residents",
  },
  // entertainmentAssemblyAndPerformingArt
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Amusement enterprise",
    id: "amusementEnterprise",
    loadType: "seatsPersons",
  },
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Assembly hall, auditorium or public hall",
    id: "assemblyHallAuditoriumOrPublicHall",
    loadType: "seatsPersons",
  },
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Auction house",
    id: "auctionHouse",
    loadType: "seatsPersons",
  },
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Ballpark",
    id: "ballpark",
    loadType: "seatsPersons",
  },
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Billiard parlor or pool hall",
    id: "billiardParlorOrPoolHall",
    loadType: "seatsPersons",
  },
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Bowling alley",
    id: "bowlingAlley",
    loadType: "seatsPersons",
  },
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Cabaret",
    id: "cabaret",
    loadType: "seatsPersons",
  },
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Concert hall or other performing arts space",
    id: "concertHallOrOtherPerformingArtsSpace",
    loadType: "seatsPersons",
  },
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Dance hall, discotheque or ballroom",
    id: "danceHallDiscothequeOrBallroom",
    loadType: "seatsPersons",
  },
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Museum",
    id: "museum",
    loadType: null,
  },
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Recreational building or use",
    id: "recreationalBuildingOrUse",
    loadType: null,
  },
  {
    parentId: "entertainmentAssemblyAndPerformingArt",
    name: "Theater, including motion picture or performing arts",
    id: "theaterIncludingMotionPictureOrPerformingArts",
    loadType: "seatsPersons",
  },
  // firearmSales
  {
    parentId: "firearmSales",
    name: "Firearm Retail Sales",
    id: "firearmRetailSales",
    loadType: null,
  },
  // governmentLargeScale
  {
    parentId: "governmentLargeScale",
    name: "Fire or Police Department Training Facility",
    id: "fireOrPoliceDepartmentTrainingFacility",
    loadType: null,
  },
  {
    parentId: "governmentLargeScale",
    name: "Jail",
    id: "jail",
    loadType: null,
  },
  {
    parentId: "governmentLargeScale",
    name: "Police Department General Facility",
    id: "policeDepartmentGeneralFacility",
    loadType: null,
  },
  // governmentLocal
  {
    parentId: "governmentLocal",
    name: "Community center, public",
    id: "communityCenter",
    loadType: null,
  },
  {
    parentId: "governmentLocal",
    name: "Community Service centers",
    id: "communityServiceCenters",
    loadType: null,
  },
  {
    parentId: "governmentLocal",
    name: "Fire Department Administrative Facility",
    id: "fireDepartmentAdministrativeFacility",
    loadType: null,
  },
  {
    parentId: "governmentLocal",
    name: "Fire Department Support Facility",
    id: "fireDepartmentSupportFacility",
    loadType: null,
  },
  {
    parentId: "governmentLocal",
    name: "Fire Station",
    id: "fireStation",
    loadType: null,
  },
  {
    parentId: "governmentLocal",
    name: "Library, public",
    id: "libraryPublic",
    loadType: null,
  },
  {
    parentId: "governmentLocal",
    name: "Police Department Local Facility",
    id: "policeDepartmentLocalFacility",
    loadType: null,
  },
  // institutionGeneral
  {
    parentId: "institutionGeneral",
    name: "Library, private",
    id: "libraryOtherThanPublic",
    loadType: null,
  },
  {
    parentId: "institutionGeneral",
    name: "Community center, private",
    id: "communityCenterPrivate",
    loadType: null,
  },
  {
    parentId: "institutionGeneral",
    name: "Non-profit",
    id: "nonProfit",
    loadType: null,
  },
  {
    parentId: "institutionGeneral",
    name: "Club, private",
    id: "clubPrivate",
    loadType: "persons",
  },
  // institutionalReligiousBased
  {
    parentId: "institutionalReligiousBased",
    name: "Place of Worship",
    id: "placeOfWorship",
    loadType: "seatsPersons",
  },
  {
    parentId: "institutionalReligiousBased",
    name: "Parsonage, vicarage or rectory",
    id: "parsonageVicarageOrRectory",
    loadType: "persons",
  },
  // lodging
  { parentId: "lodging", name: "Hostel", id: "hostel", loadType: "beds" },
  { parentId: "lodging", name: "Hotel", id: "hotel", loadType: "rooms" },
  { parentId: "lodging", name: "Inn", id: "inn", loadType: "rooms" },
  { parentId: "lodging", name: "Motel", id: "motel", loadType: "rooms" },
  // marine
  {
    parentId: "marine",
    name: "Boat club or marina",
    id: "boatClubOrMarina",
    loadType: null,
  },
  {
    parentId: "marine",
    name: "Boat construction",
    id: "boatConstruction",
    loadType: null,
  },
  {
    parentId: "marine",
    name: "Boat launching facility, dock, wharf, or pier",
    id: "boatLaunchingFacilityDockWharfOrPier",
    loadType: null,
  },
  { parentId: "marine", name: "Boathouse", id: "boathouse", loadType: null },
  {
    parentId: "marine",
    name: "Cruise line operation & associated dock & land facilities",
    id: "cruiseLineOperationAssociatedDockLandFacilities",
    loadType: null,
  },
  { parentId: "marine", name: "Marina", id: "marina", loadType: null },
  {
    parentId: "marine",
    name: "Water taxi information/ticket booth & passenger shelter",
    id: "waterTaxiInformationTicketBoothPassengerShelter",
    loadType: null,
  },
  { parentId: "marine", name: "Yacht Club", id: "yachtClub", loadType: null },
  // medicalCare
  { parentId: "medicalCare", name: "Clinic", id: "clinic", loadType: null },
  {
    parentId: "medicalCare",
    name: "Dental laboratory",
    id: "dentalLaboratory",
    loadType: null,
  },
  {
    parentId: "medicalCare",
    name: "Hospital",
    id: "hospital",
    loadType: null,
  },
  {
    parentId: "medicalCare",
    name: "Office, Medical -including physicians, dentists",
    id: "officeMedicalIncludingPhysiciansDentists",
    loadType: null,
  },
  {
    parentId: "medicalCare",
    name: "Optician and optometrist",
    id: "opticianAndOptometrist",
    loadType: null,
  },
  // motorVehicleRelated
  {
    parentId: "motorVehicleRelated",
    name: "Auto supply stores",
    id: "autoSupplyStores",
    loadType: null,
  },
  {
    parentId: "motorVehicleRelated",
    name: "Automobile accessories sales (including installation)",
    id: "automobileAccessoriesSalesIncludingInstallation",
    loadType: null,
  },
  {
    parentId: "motorVehicleRelated",
    name: "Automobile accessory sales (w/out installation)",
    id: "automobileAccessorySalesWOutInstallation",
    loadType: null,
  },
  {
    parentId: "motorVehicleRelated",
    name: "Automobile and/or truck sales",
    id: "automobileAndOrTruckSales",
    loadType: null,
  },
  {
    parentId: "motorVehicleRelated",
    name: "Car wash",
    id: "carWash",
    loadType: null,
  },
  {
    parentId: "motorVehicleRelated",
    name: "Automobile rental",
    id: "automobileRental",
    loadType: null,
  },
  {
    parentId: "motorVehicleRelated",
    name: "Boat or marine sales",
    id: "boatOrMarineSales",
    loadType: null,
  },
  {
    parentId: "motorVehicleRelated",
    name: "Driver's License Road Test Facility",
    id: "driversLicenseRoadTestFacility",
    loadType: null,
  },
  {
    parentId: "motorVehicleRelated",
    name: "Garage, repair",
    id: "garageRepair",
    loadType: null,
  },
  {
    parentId: "motorVehicleRelated",
    name: "Gasoline service station",
    id: "gasolineServiceStation",
    loadType: null,
  },
  {
    parentId: "motorVehicleRelated",
    name: "Gasoline service station accessory (internal) to garage",
    id: "gasolineServiceStationAccessoryInternalToGarage",
    loadType: null,
  },
  {
    parentId: "motorVehicleRelated",
    name: "Motorcycle sales and repair",
    id: "motorcycleSalesAndRepair",
    loadType: null,
  },
  // office
  { parentId: "office", name: "Architect", id: "architect", loadType: null },
  { parentId: "office", name: "Attorney", id: "attorney", loadType: null },
  {
    parentId: "office",
    name: "Bail bondsman",
    id: "bailBondsman",
    loadType: null,
  },
  {
    parentId: "office",
    name: "Laboratory",
    id: "laboratory",
    loadType: null,
  },
  { parentId: "office", name: "Office", id: "office", loadType: null },
  {
    parentId: "office",
    name: "Performing arts ticket office or booking agency",
    id: "performingArtsTicketOfficeOrBookingAgency",
    loadType: null,
  },
  {
    parentId: "office",
    name: "Real estate agent, appraiser, broker, developer",
    id: "realEstateAgentAppraiserBrokerDeveloper",
    loadType: null,
  },
  {
    parentId: "office",
    name: "Social service agency office",
    id: "socialServiceAgencyOffice",
    loadType: null,
  },
  {
    parentId: "office",
    name: "Travel Agency, Ticket Office",
    id: "travelAgencyTicketOffice",
    loadType: null,
  },
  // parking
  {
    parentId: "parking",
    name: "Parking garage, public",
    id: "parkingGaragePublic",
    loadType: "parkingSpaces",
  },
  {
    parentId: "parking",
    name: "Parking lot, public",
    id: "parkingLotPublic",
    loadType: "parkingSpaces",
  },
  {
    parentId: "parking",
    name: "Private garage as principal use",
    id: "privateGarageAsPrincipalUse",
    loadType: "parkingSpaces",
  },
  // parksAndRecreation
  {
    parentId: "parksAndRecreation",
    name: "Park or open space",
    id: "parkOrOpenSpace",
    loadType: null,
  },
  {
    parentId: "parksAndRecreation",
    name: "Public nature education or interpretive center",
    id: "publicNatureEducationOrInterpretiveCenter",
    loadType: null,
  },
  {
    parentId: "parksAndRecreation",
    name: "Public recreation & community center",
    id: "publicRecreationCommunityCenter",
    loadType: null,
  },
  {
    parentId: "parksAndRecreation",
    name: "Swimming pool, public",
    id: "swimmingPoolPublic",
    loadType: null,
  },
  // productionDistributionAndRepair
  {
    parentId: "productionDistributionAndRepair",
    name: "Asphalt plant",
    id: "asphaltPlant",
    loadType: null,
  },
  {
    parentId: "productionDistributionAndRepair",
    name: "Concrete plant",
    id: "concretePlant",
    loadType: null,
  },
  {
    parentId: "productionDistributionAndRepair",
    name: "Light manufacturing, processing or fabricating",
    id: "lightManufacturingProcessingOrFabricating",
    loadType: null,
  },
  {
    parentId: "productionDistributionAndRepair",
    name: "Outdoor materials storage",
    id: "outdoorMaterialsStorage",
    loadType: null,
  },
  {
    parentId: "productionDistributionAndRepair",
    name: "Printing, lithographing, or photoengraving establishment",
    id: "printingLithographingOrPhotoengravingEstablishment",
    loadType: null,
  },
  {
    parentId: "productionDistributionAndRepair",
    name: "Warehouse",
    id: "warehouse",
    loadType: null,
  },
  {
    parentId: "productionDistributionAndRepair",
    name: "Wholesale or storage establishment (incl. open storage)",
    id: "wholesaleOrStorageEstablishmentInclOpenStorage",
    loadType: null,
  },
  // residential
  {
    parentId: "residential",
    name: "Apartment house",
    id: "apartmentHouse",
    loadType: "dwellingUnits",
  },
  {
    parentId: "residential",
    name: "Boarding House",
    id: "boardingHouse",
    loadType: "boarders",
  },
  {
    parentId: "residential",
    name: "Caretaker's residence",
    id: "caretakersResidence",
    loadType: "dwellingUnits",
  },
  {
    parentId: "residential",
    name: "CBRF (Community res. facility)",
    id: "cbrfCommunityResFacility",
    loadType: "residents",
  },
  {
    parentId: "residential",
    name: "CBRF (fewer than 6)",
    id: "cbrfFewerThan6",
    loadType: "residents",
  },
  {
    parentId: "residential",
    name: "CBRF (Health care facility)",
    id: "cbrfHealthCareFacility",
    loadType: "residents",
  },
  {
    parentId: "residential",
    name: "CBRF (other)",
    id: "cbrfOther",
    loadType: "residents",
  },
  {
    parentId: "residential",
    name: "CBRF (Substance abusers home)",
    id: "cbrfSubstanceAbusersHome",
    loadType: "residents",
  },
  {
    parentId: "residential",
    name: "CBRF (Youth res. care home)",
    id: "cbrfYouthResCareHome",
    loadType: "residents",
  },
  {
    parentId: "residential",
    name: "Child development home",
    id: "childDevelopmentHome",
    loadType: "childrenStaff",
  },
  {
    parentId: "residential",
    name: "Clerical & religious group residences",
    id: "clericalReligiousGroupResidences",
    loadType: "residents",
  },

  {
    parentId: "residential",
    name: "Dwelling, Multiple",
    id: "dwellingMultiple",
    loadType: "dwellingUnits",
  },

  {
    parentId: "residential",
    name: "Elderly day care home",
    id: "elderlyDayCareHome",
    loadType: "persons",
  },
  {
    parentId: "residential",
    name: "Embassy staff residence",
    id: "embassyStaffResidence",
    loadType: "dwellingUnits",
  },
  {
    parentId: "residential",
    name: "Flat, [two principal dwelling]",
    id: "flatTwoPrincipalDwelling",
    loadType: "dwellingUnits",
  },
  {
    parentId: "residential",
    name: "Floating home",
    id: "floatingHome",
    loadType: "dwellingUnits",
  },
  {
    parentId: "residential",
    name: "Fraternity house, sorority house or dormitory",
    id: "fraternityHouseSororityHouseOrDormitory",
    loadType: "beds",
  },
  {
    parentId: "residential",
    name: "Home occupation (limited)",
    id: "homeOccupationLimited",
    loadType: null,
  },
  {
    parentId: "residential",
    name: "Rooming house",
    id: "roomingHouse",
    loadType: "rooms",
  },
  // retail
  {
    parentId: "retail",
    name: "Alcoholic beverage sales (off-premises consumption)",
    id: "alcoholicBeverageSalesOffPremisesConsumption",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Antique store or shop",
    id: "antiqueStoreOrShop",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Apparel and accessories store",
    id: "apparelAndAccessoriesStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Appliance sales or repair",
    id: "applianceSalesOrRepair",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Appliance store",
    id: "applianceStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Art gallery",
    id: "artGallery",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Art supplies store",
    id: "artSuppliesStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Bed and breakfast (limited)",
    id: "bedAndBreakfastLimited",
    loadType: "rooms",
  },
  {
    parentId: "retail",
    name: "Bicycle sale, repair, or rental",
    id: "bicycleSaleRepairOrRental",
    loadType: null,
  },
  { parentId: "retail", name: "Book Store", id: "bookStore", loadType: null },
  {
    parentId: "retail",
    name: "Camera or photographic supplies store",
    id: "cameraOrPhotographicSuppliesStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Candy Store",
    id: "candyStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Commercial adjunct to an apartment house",
    id: "commercialAdjunctToAnApartmentHouse",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Computer store",
    id: "computerStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Cosmetic/toiletries sales",
    id: "cosmeticToiletriesSales",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Department Store",
    id: "departmentStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Drug store or pharmacy",
    id: "drugStoreOrPharmacy",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Electric appliance store, including radio and TV sales",
    id: "electricApplianceStoreIncludingRadioAndTvSales",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Fabric store",
    id: "fabricStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Florist & plant stores",
    id: "floristPlantStores",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Food/grocery store",
    id: "foodGroceryStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Furniture Store",
    id: "furnitureStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Gift, Novelty, and Souvenir Shop",
    id: "giftNoveltyAndSouvenirShop",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Grocery store",
    id: "groceryStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Hardware store",
    id: "hardwareStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Hobby, toys and game shop",
    id: "hobbyToysAndGameShop",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Home furnishing store",
    id: "homeFurnishingStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Jewelry store",
    id: "jewelryStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Leather goods and luggage store",
    id: "leatherGoodsAndLuggageStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Liquor store",
    id: "liquorStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Music store, including the sale of musical instruments",
    id: "musicStoreIncludingTheSaleOfMusicalInstruments",
    loadType: null,
  },
  { parentId: "retail", name: "Newsstand", id: "newsstand", loadType: null },
  {
    parentId: "retail",
    name: "Notions or novelty store",
    id: "notionsOrNoveltyStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Off premises alcoholic beverages sales",
    id: "offPremisesAlcoholicBeveragesSales",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Office supplies and equipment sales",
    id: "officeSuppliesAndEquipmentSales",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Optical goods store",
    id: "opticalGoodsStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Paint store",
    id: "paintStore",
    loadType: null,
  },
  { parentId: "retail", name: "Pawn shop", id: "pawnShop", loadType: null },
  {
    parentId: "retail",
    name: "Pet supplies retail (no live animals)",
    id: "petSuppliesRetailNoLiveAnimals",
    loadType: null,
  },
  { parentId: "retail", name: "Pharmacy", id: "pharmacy", loadType: null },
  {
    parentId: "retail",
    name: "Retail establishment",
    id: "retailEstablishment",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Seasonal or occasional market for produce, arts & crafts w/ non permanent structures",
    id: "seasonalOrOccasionalMarketForProduceArtsCraftsWNonPermanentStructures",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Secondhand store or consignment shop",
    id: "secondhandStoreOrConsignmentShop",
    loadType: null,
  },
  { parentId: "retail", name: "Shoe store", id: "shoeStore", loadType: null },
  {
    parentId: "retail",
    name: "Sporting goods store",
    id: "sportingGoodsStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Stationery store",
    id: "stationeryStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Tobacco products store",
    id: "tobaccoProductsStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Variety Store",
    id: "varietyStore",
    loadType: null,
  },
  {
    parentId: "retail",
    name: "Video rental and sales",
    id: "videoRentalAndSales",
    loadType: null,
  },
  // serviceFinancial
  {
    parentId: "serviceFinancial",
    name: "Bank, loan office, or financial institution",
    id: "bankLoanOfficeOrFinancialInstitution",
    loadType: null,
  },
  // serviceGeneral
  {
    parentId: "serviceGeneral",
    name: "Barber or Beauty shop",
    id: "barberOrBeautyShop",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Blueprinting, printing or copy service",
    id: "blueprintingPrintingOrCopyService",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Cobbler/shoe repair",
    id: "cobblerShoeRepair",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Dressmaking or Tailor Shop",
    id: "dressmakingOrTailorShop",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Dry cleaning or laundry",
    id: "dryCleaningOrLaundry",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Funeral, mortuary or undertaking establishment",
    id: "funeralMortuaryOrUndertakingEstablishment",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Gym or exercise studio",
    id: "gymOrExerciseStudio",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Laundry",
    id: "laundry",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Laundry, self service",
    id: "laundrySelfService",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Locksmith",
    id: "locksmith",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Massage Establishment",
    id: "massageEstablishment",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Photographic studio",
    id: "photographicStudio",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Picture framing studio/shop",
    id: "pictureFramingStudioShop",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Plumbing or heating shop (no outdoor storage)",
    id: "plumbingOrHeatingShopNoOutdoorStorage",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Printing or fast copy services",
    id: "printingOrFastCopyServices",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Recording studio",
    id: "recordingStudio",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Shoe Repair",
    id: "shoeRepair",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Shoeshine parlor",
    id: "shoeshineParlor",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Tailor or valet shop",
    id: "tailorOrValetShop",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Watch repair shop",
    id: "watchRepairShop",
    loadType: null,
  },
  {
    parentId: "serviceGeneral",
    name: "Parcel delivery service",
    id: "parcelDeliveryService",
    loadType: null,
  },
  // temporaryUse
  {
    parentId: "temporaryUse",
    name: "Temporary building or use",
    id: "temporaryBuildingOrUse",
    loadType: null,
  },
  {
    parentId: "temporaryUse",
    name: "Temporary use by fairs, circuses, or carnivals",
    id: "temporaryUseByFairsCircusesOrCarnivals",
    loadType: null,
  },
  // transportationInfrastructure
  {
    parentId: "transportationInfrastructure",
    name: "Bus passenger depot",
    id: "busPassengerDepot",
    loadType: null,
  },
  {
    parentId: "transportationInfrastructure",
    name: "Mass transit facility",
    id: "massTransitFacility",
    loadType: null,
  },
  {
    parentId: "transportationInfrastructure",
    name: "Streetcar or bus passenger depot",
    id: "streetcarOrBusPassengerDepot",
    loadType: null,
  },
  {
    parentId: "transportationInfrastructure",
    name: "Transit system yards, buildings and structures",
    id: "transitSystemYardsBuildingsAndStructures",
    loadType: null,
  },
  // wasteRelatedServices
  {
    parentId: "wasteRelatedServices",
    name: "Carting, express, moving or hauling yard/terminal",
    id: "cartingExpressMovingOrHaulingYardTerminal",
    loadType: null,
  },
  {
    parentId: "wasteRelatedServices",
    name: "Incinerator",
    id: "incinerator",
    loadType: null,
  },
  {
    parentId: "wasteRelatedServices",
    name: "Intermediate materials recycling facility",
    id: "intermediateMaterialsRecyclingFacility",
    loadType: null,
  },
  {
    parentId: "wasteRelatedServices",
    name: "Solid waste handling facility",
    id: "solidWasteHandlingFacility",
    loadType: null,
  },
  // sexuallyOrientedBusinessEstablishment
  {
    parentId: "sexuallyOrientedBusinessEstablishment",
    id: "sexuallyThemedBookstoresOrNewstands",
    name: "Sexually-themed bookstores or newstands",
    loadType: null,
  },
  {
    parentId: "sexuallyOrientedBusinessEstablishment",
    id: "theaters",
    name: "Theaters",
    loadType: null,
  },
  {
    parentId: "sexuallyOrientedBusinessEstablishment",
    id: "amusementEnterprises",
    name: "Amusement enterprises",
    loadType: null,
  },
];

export const GENERAL_BUILDING_CODE_CATEGORY: Params[] = [
  { id: "assembly", name: "Assembly" },
  { id: "business", name: "Business" },
  { id: "educational", name: "Educational" },
  { id: "factoryAndIndustrial", name: "Factory and Industrial" },
  { id: "highHazard", name: "High Hazard" },
  { id: "institutional", name: "Institutional" },
  { id: "mercantile", name: "Mercantile" },
  { id: "residential", name: "Residential" },
  { id: "storage", name: "Storage" },
  { id: "utilityAndMiscellaneous", name: "Utility and Miscellaneous " },
  { id: "other", name: "Other" },
];

export const SPECIFIC_BUILDING_CODE_CATEGORY: Params[] = [
  {
    name: "Accessory to religious places relig. educational and auditoriums ocupant  load less than 100",
    id: "accessoryToReligiousPlacesReligEducationalAndAuditoriumsOcupantLoadLessThan100",
    parentId: "assembly",
  },
  {
    name: "Airport Trafic Control Towers - B",
    id: "airportTraficControlTowersB",
    parentId: "assembly",
  },
  {
    name: "Ambulatory care facilities",
    id: "ambulatoryCareFacilities",
    parentId: "assembly",
  },
  {
    name: "Amusement Arcade - A-3",
    id: "amusementArcadeA3",
    parentId: "assembly",
  },
  {
    name: "Amusement Park - A-5",
    id: "amusementParkA5",
    parentId: "assembly",
  },
  {
    name: "Animal hospitals, kennels and pounds",
    id: "animalHospitalsKennelsAndPounds",
    parentId: "assembly",
  },
  {
    name: "Arena - A-4",
    id: "arenaA4",
    parentId: "assembly",
  },
  {
    name: "Art Galleries - A-3",
    id: "artGalleriesA3",
    parentId: "assembly",
  },
  {
    name: "Auditorium - A-1",
    id: "auditoriumA1",
    parentId: "assembly",
  },
  {
    name: "Banquet Halls - A-2",
    id: "banquetHallsA2",
    parentId: "assembly",
  },
  {
    name: "Bar - A-2",
    id: "barA2",
    parentId: "assembly",
  },
  {
    name: "Bar/Cocktail Lounge - A-2",
    id: "barCocktailLoungeA2",
    parentId: "assembly",
  },
  {
    name: "Billiard Parlor - A-3",
    id: "billiardParlorA3",
    parentId: "assembly",
  },
  {
    name: "Bleachers - A-5",
    id: "bleachersA5",
    parentId: "assembly",
  },
  {
    name: "Bowling Alleys - A-3",
    id: "bowlingAlleysA3",
    parentId: "assembly",
  },
  {
    name: "Cafeterias A-2",
    id: "cafeteriasA2",
    parentId: "assembly",
  },
  {
    name: "Casinos (gaming areas) - A-2",
    id: "casinosGamingAreasA2",
    parentId: "assembly",
  },
  {
    name: "Churches - A-3",
    id: "churchesA3",
    parentId: "assembly",
  },
  {
    name: "Coffee Shop - A-2",
    id: "coffeeShopA2",
    parentId: "assembly",
  },
  {
    name: "Community Halls - A-3",
    id: "communityHallsA3",
    parentId: "assembly",
  },
  {
    name: "Courtroom - A-3",
    id: "courtroomA3",
    parentId: "assembly",
  },
  {
    name: "Dance Hall - A-3",
    id: "danceHallA3",
    parentId: "assembly",
  },
  {
    name: "Exhibitions Halls - A-3",
    id: "exhibitionsHallsA3",
    parentId: "assembly",
  },
  {
    name: "Fast Food Establishment - A-2",
    id: "fastFoodEstablishmentA2",
    parentId: "assembly",
  },
  {
    name: "Funeral Parlors - A-3",
    id: "funeralParlorsA3",
    parentId: "assembly",
  },
  {
    name: "Grandstands - A-5",
    id: "grandstandsA5",
    parentId: "assembly",
  },
  {
    name: "Gymnasiums ( With out spectator seating) - A-3",
    id: "gymnasiumsWithOutSpectatorSeatingA3",
    parentId: "assembly",
  },
  {
    name: "Lecture Halls - A-3",
    id: "lectureHallsA3",
    parentId: "assembly",
  },
  {
    name: "Library - A-3",
    id: "libraryA3",
    parentId: "assembly",
  },
  {
    name: "Motion Picture Theaters - A1",
    id: "motionPictureTheatersA1",
    parentId: "assembly",
  },
  {
    name: "Movie Theater - A-1",
    id: "movieTheaterA1",
    parentId: "assembly",
  },
  {
    name: "Museums - A-3",
    id: "museumsA3",
    parentId: "assembly",
  },
  {
    name: "Night Club - A-2",
    id: "nightClubA2",
    parentId: "assembly",
  },
  {
    name: "Passenger Stations (waiting area) - A-3",
    id: "passengerStationsWaitingAreaA3",
    parentId: "assembly",
  },
  {
    name: "Places of religious Worships A-3",
    id: "placesOfReligiousWorshipsA3",
    parentId: "assembly",
  },
  {
    name: "Pool and Billiard Parlors - A-3",
    id: "poolAndBilliardParlorsA3",
    parentId: "assembly",
  },
  {
    name: "Pool Hall - A-3",
    id: "poolHallA3",
    parentId: "assembly",
  },
  {
    name: "Private Studio - A-1",
    id: "privateStudioA1",
    parentId: "assembly",
  },
  {
    name: "Public Theater - A-1",
    id: "publicTheaterA1",
    parentId: "assembly",
  },
  {
    name: "Restaurants - A-2",
    id: "restaurantsA2",
    parentId: "assembly",
  },
  {
    name: "Skating Rinks - A-4",
    id: "skatingRinksA4",
    parentId: "assembly",
  },
  {
    name: "Stadiums - A-5",
    id: "stadiumsA5",
    parentId: "assembly",
  },
  {
    name: "Swimming Pool Indoor ( with out spectator Seating)- A-3",
    id: "swimmingPoolIndoorWithoutSpectatorSeatingA3",
    parentId: "assembly",
  },
  {
    name: "Swimming Pools - A-4",
    id: "swimmingPoolsA4",
    parentId: "assembly",
  },
  {
    name: "Symphony and Concert Halls - A1",
    id: "symphonyAndConcertHallsA1",
    parentId: "assembly",
  },
  {
    name: "Taverns - A-2",
    id: "tavernsA2",
    parentId: "assembly",
  },
  {
    name: "Television and Radio Studios admitting an Audience -A1",
    id: "televisionAndRadioStudiosAdmittingAnAudienceA1",
    parentId: "assembly",
  },
  {
    name: "Television Studios - A-1",
    id: "televisionStudiosA1",
    parentId: "assembly",
  },
  {
    name: "Tennis Court - A-4",
    id: "tennisCourtA4",
    parentId: "assembly",
  },
  {
    name: "Tennis Court Indoor (with out spectator Seating) - A-3",
    id: "tennisCourtIndoorWithoutSpectatorSeatingA3",
    parentId: "assembly",
  },
  {
    name: "Theaters A-1",
    id: "theatersA1",
    parentId: "assembly",
  },
  {
    name: "Theaters Performing Arts - A-1",
    id: "theatersPerformingArtsA1",
    parentId: "assembly",
  },
  {
    name: "Waiting areas in transportation Terminals A-3",
    id: "waitingAreasInTransportationTerminalsA3",
    parentId: "assembly",
  },

  {
    name: "Animal Hospital - B",
    id: "animalHospitalB",
    parentId: "business",
  },
  {
    name: "Antennas - B",
    id: "antennasB",
    parentId: "business",
  },
  {
    name: "Art Gallery - B",
    id: "artGalleryB",
    parentId: "business",
  },
  {
    name: "Artists studio - B",
    id: "artistsStudioB",
    parentId: "business",
  },
  {
    name: "Bank - B",
    id: "bankB",
    parentId: "business",
  },
  {
    name: "Barber Shop - B",
    id: "barberShopB",
    parentId: "business",
  },
  {
    name: "Beauty Shop -B",
    id: "beautyShopB",
    parentId: "business",
  },
  {
    name: "Bed and Breakfast - B",
    id: "bedAndBreakfastB",
    parentId: "business",
  },
  {
    name: "Car Wash - B",
    id: "carWashB",
    parentId: "business",
  },
  {
    name: "Catering Establishment - B",
    id: "cateringEstablishmentB",
    parentId: "business",
  },
  {
    name: "Chancery - B",
    id: "chanceryB",
    parentId: "business",
  },
  {
    name: "Civic administration",
    id: "civicAdministration",
    parentId: "business",
  },
  {
    name: "Clinic , outpatient- B",
    id: "clinicOutpatientB",
    parentId: "business",
  },
  {
    name: "Colleges - B",
    id: "collegesB",
    parentId: "business",
  },
  {
    name: "Commercial Kitchens not associated with restaurants , cafeterias and similar dining facilities not more than 2500 Sq.ft in ares - B ",
    id: "commercialKitchens",
    parentId: "business",
  },
  {
    name: "Deli - B",
    id: "deliB",
    parentId: "business",
  },
  {
    name: "Dentists - B",
    id: "dentistsB",
    parentId: "business",
  },
  {
    name: "Dog Pound - B",
    id: "dogPoundB",
    parentId: "business",
  },
  {
    name: "Dressmaking Establishment - B",
    id: "dressmakingEstablishmentB",
    parentId: "business",
  },
  {
    name: "Dry Cleaning: Pick up and delivery Stations and self-service  - B",
    id: "dryCleaning",
    parentId: "business",
  },
  {
    name: "Educational above 12th grade - B",
    id: "educationalAbove12thGradeB",
    parentId: "business",
  },
  {
    name: "Electric Appliance Store -B",
    id: "electricApplianceStoreB",
    parentId: "business",
  },
  {
    name: "Electronic Data Processing - B",
    id: "electronicDataProcessingB",
    parentId: "business",
  },
  {
    name: "Fire Station - B",
    id: "fireStationB",
    parentId: "business",
  },
  {
    name: "Food Delivery Service - B",
    id: "foodDeliveryServiceB",
    parentId: "business",
  },
  {
    name: "Food processing establishments not associated with restaurants, cafeterias and similar dining facilities not more than 2500 Sq.ft in ares - B",
    id: "foodProcessingEstablishments",
    parentId: "business",
  },
  {
    name: "Ice Cream Parlor - B",
    id: "iceCreamParlorB",
    parentId: "business",
  },
  {
    name: "Kennel - B",
    id: "kennelB",
    parentId: "business",
  },
  {
    name: "Laboratory - B",
    id: "laboratoryB",
    parentId: "business",
  },
  {
    name: "Laundry - Pick up and delivery Stations and self-service - B",
    id: "laundryPickUpAndDelivery",
    parentId: "business",
  },
  {
    name: "Locksmith - B",
    id: "locksmithB",
    parentId: "business",
  },
  {
    name: "Martial arts Studio",
    id: "martialArtsStudio",
    parentId: "business",
  },
  {
    name: "Motor Vehicle Showroom - B",
    id: "motorVehicleShowroomB",
    parentId: "business",
  },
  {
    name: "Newspaper Distribution - B",
    id: "newspaperDistributionB",
    parentId: "business",
  },
  {
    name: "Office - B",
    id: "officeB",
    parentId: "business",
  },
  {
    name: "Optometrists - B",
    id: "optometristsB",
    parentId: "business",
  },
  {
    name: "Police Station - B",
    id: "policeStationB",
    parentId: "business",
  },
  {
    name: "Post Office - B",
    id: "postOfficeB",
    parentId: "business",
  },
  {
    name: "Print Shop - B",
    id: "printShopB",
    parentId: "business",
  },
  {
    name: "Private Club - B",
    id: "privateClubB",
    parentId: "business",
  },
  {
    name: "Professional Services - B",
    id: "professionalServicesB",
    parentId: "business",
  },
  {
    name: "Radio Station - B",
    id: "radioStationB",
    parentId: "business",
  },
  {
    name: "Research Lab - B",
    id: "researchLabB",
    parentId: "business",
  },
  {
    name: "Salvage Yard - B",
    id: "salvageYardB",
    parentId: "business",
  },
  {
    name: "Seamstress Shop - B",
    id: "seamstressShopB",
    parentId: "business",
  },
  {
    name: "Tailor Shop - B",
    id: "tailorShopB",
    parentId: "business",
  },
  {
    name: "Telephone Exchanges - B",
    id: "telephoneExchangesB",
    parentId: "business",
  },
  {
    name: "Television Station - B",
    id: "televisionStationB",
    parentId: "business",
  },
  {
    name: "Training and skill development not in school or academic program ",
    id: "trainingAndSkillDevelopment",
    parentId: "business",
  },
  {
    name: "Tutoring Center",
    id: "tutoringCenter",
    parentId: "business",
  },
  {
    name: "Universities - B",
    id: "universitiesB",
    parentId: "business",
  },
  {
    name: "Watch Repair -B",
    id: "watchRepairB",
    parentId: "business",
  },

  {
    name: "A child day care 6 to 100 children <=2 1/2 of age - located on a level of exist discharge - each childcare rooms has an exit door directly to the exterior",
    id: "aChildDayCare6100Children2AndHalfAge",
    parentId: "educational",
  },
  {
    name: "Day Care facilities more than 5 and older than 2 1/2 of age educational or supervision or personal care fewer than 24hrs",
    id: "dayCareFacilitiesMoreThan5AndOlderThan2AndHalfAge",
    parentId: "educational",
  },
  {
    name: "Education for 6+ children over 2 1/2 years - E",
    id: "educationFor6ChildrenOver2AndHalfYearsE",
    parentId: "educational",
  },
  {
    name: "Education for 6+ though 12th grade - E-1",
    id: "educationFor6Though12thGradeE1",
    parentId: "educational",
  },
  {
    name: "Education for 6+ through 12th grade - E",
    id: "educationFor6Through12thGradeE",
    parentId: "educational",
  },
  {
    name: "Private School - E (up to 12 th grade)",
    id: "privateSchoolEUpTo12ThGrade",
    parentId: "educational",
  },
  {
    name: "Public School - E (up to 12th grade)",
    id: "publicSchoolEUpTo12thGrade",
    parentId: "educational",
  },

  {
    name: "Aircraft (manufacturing, not include repair)",
    id: "aircraftManufacturing",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Appliances - F-1",
    id: "appliances",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Athletic Equipment - F-1",
    id: "athleticEquipment",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Automobiles and other Motor Vehicles - F-1",
    id: "automobilesAndOtherMotorVehicles",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Bakeries - F-1",
    id: "bakeries",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Beverages: over 16 percent alcohol content",
    id: "beveragesOver16PercentAlcohol",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Beverages (non-alcoholic) - F-2",
    id: "beveragesNonAlcoholic",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Beverages (up to and including 16% alcohol content) - F-2",
    id: "beveragesUpToIncluding16PercentAlcohol",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Bicycles",
    id: "bicycles",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Boats",
    id: "boats",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Brick and Masonry - F-2",
    id: "brickAndMasonry",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Brooms or Brushes",
    id: "broomsOrBrushes",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Business Machines - F-1",
    id: "businessMachines",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Cameras and Photo Equipment",
    id: "camerasAndPhotoEquipment",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Canvas or Similar Fabric",
    id: "canvasOrSimilarFabric",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Carpets and Rugs (including cleaning)",
    id: "carpetsAndRugs",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Ceramic Products - F-2",
    id: "ceramicProducts",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Clothing",
    id: "clothing",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Construction and Agricultural Machinery",
    id: "constructionAndAgriculturalMachinery",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Disinfectants",
    id: "disinfectants",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Dry Cleaning Plant - F-1",
    id: "dryCleaningPlant",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Dyeing",
    id: "dyeing",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Electric Generation Plants",
    id: "electricGenerationPlants",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Electric Light Plants - F-1",
    id: "electricLightPlants",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Electrical Power Houses - F-1",
    id: "electricalPowerHouses",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Electronics - F-1",
    id: "electronics",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Engines (including rebuilding)",
    id: "enginesIncludingRebuilding",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Food Processing Establishments and Commercial Kitchens more than 2,500 sq. ft - F-1",
    id: "foodProcessingEstablishments",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Foundries - F-2",
    id: "foundries",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Furniture - F-1",
    id: "furniture",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Glass Products - F-2",
    id: "glassProducts",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Gypsum - F-2",
    id: "gypsum",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Ice - F-2",
    id: "ice",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Metal Products - F-2",
    id: "metalProducts",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Motion Picture and Television Filming (without spectators) - F-1",
    id: "motionPictureAndTelevisionFilming",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Musical Instruments",
    id: "musicalInstruments",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Optical Goods - F-1",
    id: "opticalGoods",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Paper Mills or Products",
    id: "paperMillsOrProducts",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Photographic Film",
    id: "photographicFilm",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Plastic Products - F-1",
    id: "plasticProducts",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Printing or Publishing - F-1",
    id: "printingOrPublishing",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Recreational Vehicles",
    id: "recreationalVehicles",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Refuse Incineration - F-1",
    id: "refuseIncineration",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Shoes",
    id: "shoes",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Soap and Detergent - F-1",
    id: "soapAndDetergent",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Television Filming - F-1",
    id: "televisionFilming",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Textiles - F-1",
    id: "textiles",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Tobacco",
    id: "tobacco",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Trailers - F-1",
    id: "trailers",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Upholstering - F-1",
    id: "upholstering",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Wood; Distillation - F-1",
    id: "woodDistillation",
    parentId: "factoryAndIndustrial",
  },
  {
    name: "Woodworking (Cabinet) - F-1",
    id: "woodworkingCabinet",
    parentId: "factoryAndIndustrial",
  },

  {
    name: "Deflagration Hazard",
    id: "deflagrationHazard",
    parentId: "highHazard",
  },
  {
    name: "Detonation Hazard",
    id: "detonationHazard",
    parentId: "highHazard",
  },
  {
    name: "Health hazards (corrosives, highly toxic and Toxic etc.)",
    id: "healthHazards",
    parentId: "highHazard",
  },
  {
    name: "High Hazard - H",
    id: "highHazardH",
    parentId: "highHazard",
  },
  {
    name: "Readily support combustion or that pose physical hazard",
    id: "physicalHazard",
    parentId: "highHazard",
  },
  {
    name: "Semiconductor fabrication facilities and comparable RD areas",
    id: "semiconductorAreas",
    parentId: "highHazard",
  },

  {
    name: "Alcohol and Drug Centers -(Condition 1) I-1",
    id: "alcoholAndDrugCentersCondition1I1",
    parentId: "institutional",
  },
  {
    name: "Alcohol and Drug Centers -(Condition 2) I-1",
    id: "alcoholAndDrugCentersCondition2I1",
    parentId: "institutional",
  },
  {
    name: "Assisted Living Facility - (Condition 1) I-1",
    id: "assistedLivingFacilityCondition1I1",
    parentId: "institutional",
  },
  {
    name: "Assisted Living Facility - (Condition 2) I-1",
    id: "assistedLivingFacilityCondition2I1",
    parentId: "institutional",
  },
  {
    name: "Child Care ( Foster Care Facility ) 24-7 basis 6+ less than 2 1/2 years old - (Condition 1) I-2",
    id: "childCareFosterCareFacility247Condition1I2",
    parentId: "institutional",
  },
  {
    name: "Child Care ( Foster Care Facility ) 24-7 basis 6+ less than 2 1/2 years old - (Condition 2) I-2",
    id: "childCareFosterCareFacility247Condition2I2",
    parentId: "institutional",
  },
  {
    name: "Congregate Care Facility - (Condition 1) I-1",
    id: "congregateCareFacilityCondition1I1",
    parentId: "institutional",
  },
  {
    name: "Congregate Care Facility - (Condition 2) I-1",
    id: "congregateCareFacilityCondition2I1",
    parentId: "institutional",
  },
  {
    name: "Convalescent Facility - (Condition 1) I-1",
    id: "convalescentFacilityCondition1I1",
    parentId: "institutional",
  },
  {
    name: "Convalescent Facility - (Condition 2) I-1",
    id: "convalescentFacilityCondition2I1",
    parentId: "institutional",
  },
  {
    name: "Detoxification Facility - (Condition 1) I-2",
    id: "detoxificationFacilityCondition1I2",
    parentId: "institutional",
  },
  {
    name: "Detoxification Facility - (Condition 2) I-2",
    id: "detoxificationFacilityCondition2I2",
    parentId: "institutional",
  },
  {
    name: "Drug Abuse Center - (Condition 1) I-1",
    id: "drugAbuseCenterCondition1I1",
    parentId: "institutional",
  },
  {
    name: "Drug Abuse Center - (Condition 2) I-1",
    id: "drugAbuseCenterCondition2I1",
    parentId: "institutional",
  },
  {
    name: "Emergency Shelter - (Condition 1) I-1",
    id: "emergencyShelterCondition1I1",
    parentId: "institutional",
  },
  {
    name: "Emergency Shelter - (Condition 2) I-1",
    id: "emergencyShelterCondition2I1",
    parentId: "institutional",
  },
  {
    name: "Group Homes (Condition 1 )",
    id: "groupHomesCondition1",
    parentId: "institutional",
  },
  {
    name: "Group Homes (Condition 2 )",
    id: "groupHomesCondition2",
    parentId: "institutional",
  },
  {
    name: "Half-way Houses (Condition 1) I-1",
    id: "halfWayHousesCondition1I1",
    parentId: "institutional",
  },
  {
    name: "Half-way Houses (Condition 2) I-1",
    id: "halfWayHousesCondition2I1",
    parentId: "institutional",
  },
  {
    name: "Health Care Facility - (Condition 1) I-2",
    id: "healthCareFacilityCondition1I2",
    parentId: "institutional",
  },
  {
    name: "Health Care Facility - (Condition 2) I-2",
    id: "healthCareFacilityCondition2I2",
    parentId: "institutional",
  },
  {
    name: "Hospital - (Condition 1) I-2",
    id: "hospitalCondition1I2",
    parentId: "institutional",
  },
  {
    name: "Hospital - (Condition 2) I-2",
    id: "hospitalCondition2I2",
    parentId: "institutional",
  },
  {
    name: "Mental Hospital - (Condition 1) I-2",
    id: "mentalHospitalCondition1I2",
    parentId: "institutional",
  },
  {
    name: "Mental Hospital - (Condition 2) I-2",
    id: "mentalHospitalCondition2I2",
    parentId: "institutional",
  },
  {
    name: "Nursing Home - (Condition 1) I-2",
    id: "nursingHomeCondition1I2",
    parentId: "institutional",
  },
  {
    name: "Nursing Home - (Condition 2) I-2",
    id: "nursingHomeCondition2I2",
    parentId: "institutional",
  },
  {
    name: "Residential Board and Care Facilities - (Condition 1) I-1",
    id: "residentialBoardAndCareFacilitiesCondition1I1",
    parentId: "institutional",
  },
  {
    name: "Residential Board and Care Facilities - (Condition 2) I-1",
    id: "residentialBoardAndCareFacilitiesCondition2I1",
    parentId: "institutional",
  },
  {
    name: "Social Rehabilitation Facilities - (Condition 1) I-1",
    id: "socialRehabilitationFacilitiesCondition1I1",
    parentId: "institutional",
  },
  {
    name: "Social Rehabilitation Facilities - (Condition 2) I-1",
    id: "socialRehabilitationFacilitiesCondition2I1",
    parentId: "institutional",
  },
  {
    name: "Substance Abuser's Home ( 6 or fewer persons ) - (Condition 1) I-2",
    id: "substanceAbusersHomeCondition1I2",
    parentId: "institutional",
  },
  {
    name: "Substance Abuser's Home ( 6 or fewer persons ) - (Condition 2) I-2",
    id: "substanceAbusersHomeCondition2I2",
    parentId: "institutional",
  },
  {
    name: "Youth Rehabilitation Home ( 6 or fewer persons ) - (Condition 1) I-2",
    id: "youthRehabilitationHomeCondition1I2",
    parentId: "institutional",
  },
  {
    name: "Youth Rehabilitation Home ( 6 or fewer persons ) - (Condition 2) I-2",
    id: "youthRehabilitationHomeCondition2I2",
    parentId: "institutional",
  },
  {
    name: "Youth Residential Care Home ( 6 or fewer persons ) - (Condition 1) I-2",
    id: "youthResidentialCareHomeCondition1I2",
    parentId: "institutional",
  },
  {
    name: "Youth Residential Care Home ( 6 or fewer persons ) - (Condition 2) I-2",
    id: "youthResidentialCareHomeCondition2I2",
    parentId: "institutional",
  },
  {
    name: "Correctional Center - (Condition 1) I-3",
    id: "correctionalCenterCondition1I3",
    parentId: "institutional",
  },
  {
    name: "Correctional Center - (Condition 2) I-3",
    id: "correctionalCenterCondition2I3",
    parentId: "institutional",
  },
  {
    name: "Correctional Center - (Condition 3) I-3",
    id: "correctionalCenterCondition3I3",
    parentId: "institutional",
  },
  {
    name: "Correctional Center - (Condition 4) I-3",
    id: "correctionalCenterCondition4I3",
    parentId: "institutional",
  },
  {
    name: "Correctional Center - (Condition 5) I-3",
    id: "correctionalCenterCondition5I3",
    parentId: "institutional",
  },
  {
    name: "Detention Center - (Condition 1) I-3",
    id: "detentionCenterCondition1I3",
    parentId: "institutional",
  },
  {
    name: "Detention Center - (Condition 2) I-3",
    id: "detentionCenterCondition2I3",
    parentId: "institutional",
  },
  {
    name: "Detention Center - (Condition 3) I-3",
    id: "detentionCenterCondition3I3",
    parentId: "institutional",
  },
  {
    name: "Detention Center - (Condition 4) I-3",
    id: "detentionCenterCondition4I3",
    parentId: "institutional",
  },
  {
    name: "Detention Center - (Condition 5) I-3",
    id: "detentionCenterCondition5I3",
    parentId: "institutional",
  },
  {
    name: "Jails - (Condition 1) I-3",
    id: "jailsCondition1I3",
    parentId: "institutional",
  },
  {
    name: "Jails - (Condition 2) I-3",
    id: "jailsCondition2I3",
    parentId: "institutional",
  },
  {
    name: "Jails - (Condition 3) I-3",
    id: "jailsCondition3I3",
    parentId: "institutional",
  },
  {
    name: "Jails - (Condition 4) I-3",
    id: "jailsCondition4I3",
    parentId: "institutional",
  },
  {
    name: "Jails - (Condition 5) I-3",
    id: "jailsCondition5I3",
    parentId: "institutional",
  },
  {
    name: "Prisons - (Condition 1) I-3",
    id: "prisonsCondition1I3",
    parentId: "institutional",
  },
  {
    name: "Prisons - (Condition 2) I-3",
    id: "prisonsCondition2I3",
    parentId: "institutional",
  },
  {
    name: "Prisons - (Condition 3) I-3",
    id: "prisonsCondition3I3",
    parentId: "institutional",
  },
  {
    name: "Prisons - (Condition 4) I-3",
    id: "prisonsCondition4I3",
    parentId: "institutional",
  },
  {
    name: "Prisons - (Condition 5) I-3",
    id: "prisonsCondition5I3",
    parentId: "institutional",
  },
  {
    name: "Reformatories - (Condition 1) I-3",
    id: "reformatoriesCondition1I3",
    parentId: "institutional",
  },
  {
    name: "Reformatories - (Condition 2) I-3",
    id: "reformatoriesCondition2I3",
    parentId: "institutional",
  },
  {
    name: "Reformatories - (Condition 3) I-3",
    id: "reformatoriesCondition3I3",
    parentId: "institutional",
  },
  {
    name: "Reformatories - (Condition 4) I-3",
    id: "reformatoriesCondition4I3",
    parentId: "institutional",
  },
  {
    name: "Reformatories - (Condition 5) I-3",
    id: "reformatoriesCondition5I3",
    parentId: "institutional",
  },
  {
    name: "Adult day care 6+ persons of any age receiving custodial care for fewer than 24hrs",
    id: "adultDayCare6Plus",
    parentId: "institutional",
  },
  {
    name: "Child Day Care 6+ persons of any age receiving custodial care for fewer than 24hrs",
    id: "childDayCare6Plus",
    parentId: "institutional",
  },
  {
    name: "Custodial Care in Dwelling unit - per DCBC 308.6.4",
    id: "custodialCareDwellingUnitDCBC30864",
    parentId: "institutional",
  },
  {
    name: "Child Care 24-7 basis 6+ less than 2 1/2 years old - I-4",
    id: "childCare247Condition1I4",
    parentId: "institutional",
  },

  {
    name: "Art Supplies - M",
    id: "artSuppliesM",
    parentId: "mercantile",
  },
  {
    name: "Book Store - M",
    id: "bookStoreM",
    parentId: "mercantile",
  },
  {
    name: "Cosmetics - M",
    id: "cosmeticsM",
    parentId: "mercantile",
  },
  {
    name: "Department Store - M",
    id: "departmentStoreM",
    parentId: "mercantile",
  },
  {
    name: "Drug Store - M",
    id: "drugStoreM",
    parentId: "mercantile",
  },
  {
    name: "Florist - M",
    id: "floristM",
    parentId: "mercantile",
  },
  {
    name: "Flower Stand - M",
    id: "flowerStandM",
    parentId: "mercantile",
  },
  {
    name: "Gift Shop - M",
    id: "giftShopM",
    parentId: "mercantile",
  },
  {
    name: "Hardware store - M",
    id: "hardwareStoreM",
    parentId: "mercantile",
  },
  {
    name: "Hobby Shop - M",
    id: "hobbyShopM",
    parentId: "mercantile",
  },
  {
    name: "Jewelry Store - M",
    id: "jewelryStoreM",
    parentId: "mercantile",
  },
  {
    name: "Markets - M",
    id: "marketsM",
    parentId: "mercantile",
  },
  {
    name: "Motor Fuel-Dispensing Facilities - M",
    id: "motorFuelDispensingFacilitiesM",
    parentId: "mercantile",
  },
  {
    name: "Motorcycle Sales - M",
    id: "motorcycleSalesM",
    parentId: "mercantile",
  },
  {
    name: "Music Store - M",
    id: "musicStoreM",
    parentId: "mercantile",
  },
  {
    name: "New/Used Car Sales - M",
    id: "newUsedCarSalesM",
    parentId: "mercantile",
  },
  {
    name: "Newstand - M",
    id: "newstandM",
    parentId: "mercantile",
  },
  {
    name: "Notions/Novelty Store - M",
    id: "notionsNoveltyStoreM",
    parentId: "mercantile",
  },
  {
    name: "Paint Store - M",
    id: "paintStoreM",
    parentId: "mercantile",
  },
  {
    name: "Pharmacy - M",
    id: "pharmacyM",
    parentId: "mercantile",
  },
  {
    name: "Retail or Wholesale Store - M",
    id: "retailOrWholesaleStoreM",
    parentId: "mercantile",
  },
  {
    name: "Sales Rooms - M",
    id: "salesRoomsM",
    parentId: "mercantile",
  },
  {
    name: "Sporting Goods Store - M",
    id: "sportingGoodsStoreM",
    parentId: "mercantile",
  },

  {
    name: "Alcohol and drug centers 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 1) R-4",
    id: "alcoholAndDrugCenters7LessThan16ExcludingStaffCondition1R4",
    parentId: "residential",
  },
  {
    name: "Alcohol and drug centers 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 2) R-4",
    id: "alcoholAndDrugCenters7LessThan16ExcludingStaffCondition2R4",
    parentId: "residential",
  },
  {
    name: "Alcohol and drug centers 7 less than 16 occupants excluding staff ( receiving custodial care on a 24hr basis) - (Condition 1) R-4",
    id: "alcoholAndDrugCenters7LessThan16OccupantsExcludingStaffCondition1R4",
    parentId: "residential",
  },
  {
    name: "Alcohol and drug centers 7 less than 16 occupants excluding staff ( receiving custodial care on a 24hr basis) - (Condition 2) R-4",
    id: "alcoholAndDrugCenters7LessThan16OccupantsExcludingStaffCondition2R4",
    parentId: "residential",
  },
  {
    name: "Apartment Houses - R-2",
    id: "apartmentHousesR2",
    parentId: "residential",
  },
  {
    name: "Assisted living facilites 7 less than 16 occupants excluding staff ( receiving custodial care on a 24hr basis) - (Condition 1) R-4",
    id: "assistedLivingFacilites7LessThan16OccupantsExcludingStaffCondition1R4",
    parentId: "residential",
  },
  {
    name: "Assisted living facilites 7 less than 16 occupants excluding staff ( receiving custodial care on a 24hr basis) - (Condition 2) R-4",
    id: "assistedLivingFacilites7LessThan16OccupantsExcludingStaffCondition2R4",
    parentId: "residential",
  },
  {
    name: "Boarding House (non-transient) 17+ occupants - R-2",
    id: "boardingHouseNonTransient17PlusOccupantsR2",
    parentId: "residential",
  },
  {
    name: "Boarding House (transient) 11+ occupants - R-1",
    id: "boardingHouseTransient11PlusOccupantsR1",
    parentId: "residential",
  },
  {
    name: "Boarding houses ( nontransient) 16 or fewer occupants",
    id: "boardingHousesNontransient16OrFewerOccupants",
    parentId: "residential",
  },
  {
    name: "Boarding houses (transient) 10 or fewer occupants",
    id: "boardingHousesTransient10OrFewerOccupants",
    parentId: "residential",
  },
  {
    name: "Condo - R-2",
    id: "condoR2",
    parentId: "residential",
  },
  {
    name: "Congregate care facilites 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 1) R-4",
    id: "congregateCareFacilites7LessThan16ExcludingStaffCondition1R4",
    parentId: "residential",
  },
  {
    name: "Congregate care facilites 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 2) R-4",
    id: "congregateCareFacilites7LessThan16ExcludingStaffCondition2R4",
    parentId: "residential",
  },
  {
    name: "Congregate living facilities (nontransient) 17+ occupants",
    id: "congregateLivingFacilitiesNontransient17PlusOccupants",
    parentId: "residential",
  },
  {
    name: "Congregate living facilities (transient) 11+ occupants",
    id: "congregateLivingFacilitiesTransient11PlusOccupants",
    parentId: "residential",
  },
  {
    name: "Congregate living facilities( nontransient) with 16 or fewer occupants",
    id: "congregateLivingFacilitiesNontransient16OrFewerOccupants",
    parentId: "residential",
  },
  {
    name: "Congregate living facilities(transient) with 10 or fewer occupants",
    id: "congregateLivingFacilitiesTransient10OrFewerOccupants",
    parentId: "residential",
  },
  {
    name: "Convalescent Facilities 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 1) R-4",
    id: "convalescentFacilities7LessThan16ExcludingStaffCondition1R4",
    parentId: "residential",
  },
  {
    name: "Convalescent Facilities 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 2) R-4",
    id: "convalescentFacilities7LessThan16ExcludingStaffCondition2R4",
    parentId: "residential",
  },
  {
    name: "Convents - R-2",
    id: "conventsR2",
    parentId: "residential",
  },
  {
    name: "Dormitory - R-2",
    id: "dormitoryR2",
    parentId: "residential",
  },
  {
    name: "Embassy - R-3",
    id: "embassyR3",
    parentId: "residential",
  },
  {
    name: "Fraternities and Sororities - R-2",
    id: "fraternitiesAndSororitiesR2",
    parentId: "residential",
  },
  {
    name: "Group Homes 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 1) R-4",
    id: "groupHomes7LessThan16ExcludingStaffCondition1R4",
    parentId: "residential",
  },
  {
    name: "Group Homes 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 2) R-4",
    id: "groupHomes7LessThan16ExcludingStaffCondition2R4",
    parentId: "residential",
  },
  {
    name: "Halfway Houses 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 1) R-4",
    id: "halfwayHouses7LessThan16ExcludingStaffCondition1R4",
    parentId: "residential",
  },
  {
    name: "Halfway Houses 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 2) R-4",
    id: "halfwayHouses7LessThan16ExcludingStaffCondition2R4",
    parentId: "residential",
  },
  {
    name: "Hotel(Transient) - R-1",
    id: "hotelTransientR1",
    parentId: "residential",
  },
  {
    name: "Hotels(nontransient)",
    id: "hotelsNontransient",
    parentId: "residential",
  },
  {
    name: "Live/work units",
    id: "liveWorkUnits",
    parentId: "residential",
  },
  {
    name: "Lodging Houses with 5 or fewer guest rooms.",
    id: "lodgingHousesWith5OrFewerGuestRooms",
    parentId: "residential",
  },
  {
    name: "Monastery- R-2",
    id: "monasteryR2",
    parentId: "residential",
  },
  {
    name: "Motels (nontransient)",
    id: "motelsNontransient",
    parentId: "residential",
  },
  {
    name: "Motels (Transient)- R-1",
    id: "motelsTransientR1",
    parentId: "residential",
  },
  {
    name: "Residential 5 6 or fewer persons receiving custodial care for less than 24 Hr - R-3",
    id: "residential56OrFewerPersonsReceivingCustodialCareForLessThan24HrR3",
    parentId: "residential",
  },
  {
    name: "Residential 6 or fewer persons receiving Medical care on a 24 Hr basis- R-3",
    id: "residential6OrFewerPersonsReceivingMedicalCareOnA24HrBasisR3",
    parentId: "residential",
  },
  {
    name: "Residential Board and Custodial care facilties 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 1) R-4",
    id: "residentialBoardAndCustodialCareFacilties7LessThan16ExcludingStaffCondition1R4",
    parentId: "residential",
  },
  {
    name: "Residential Board and Custodial care facilties 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 2) R-4",
    id: "residentialBoardAndCustodialCareFacilties7LessThan16ExcludingStaffCondition2R4",
    parentId: "residential",
  },
  {
    name: "Residential Care or facility housing > 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 1) R-4",
    id: "residentialCareOrFacilityHousing7LessThan16ExcludingStaffCondition1R4",
    parentId: "residential",
  },
  {
    name: "Residential Care or facility housing > 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 2) R-4",
    id: "residentialCareOrFacilityHousing7LessThan16ExcludingStaffCondition2R4",
    parentId: "residential",
  },
  {
    name: "Single Family Dwelling - R-3",
    id: "singleFamilyDwellingR3",
    parentId: "residential",
  },
  {
    name: "Single Family Dwelling with ADU (Accessory Dwelling Unit) - R-3",
    id: "singleFamilyDwellingWithADUAccessoryDwellingUnitR3",
    parentId: "residential",
  },
  {
    name: "Social Rehabilitation facilities 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 1) R-4",
    id: "socialRehabilitationFacilities7LessThan16ExcludingStaffCondition1R4",
    parentId: "residential",
  },
  {
    name: "Social Rehabilitation facilities 7 less than 16 excluding staff ( receiving custodial care on a 24hr basis) - (Condition 2) R-4",
    id: "socialRehabilitationFacilities7LessThan16ExcludingStaffCondition2R4",
    parentId: "residential",
  },
  {
    name: "Two-Family Flat - R-3",
    id: "twoFamilyFlatR3",
    parentId: "residential",
  },
  {
    name: "Vacation timeshare properties",
    id: "vacationTimeshareProperties",
    parentId: "residential",
  },

  {
    name: "Aircraft hanger ( storage and repair )",
    id: "aircraftHangerStorageAndRepair",
    parentId: "storage",
  },
  {
    name: "Cardboard and Cardboard Boxes - S-1",
    id: "cardboardAndCardboardBoxesS1",
    parentId: "storage",
  },
  {
    name: "Dry Cell Batteries",
    id: "dryCellBatteries",
    parentId: "storage",
  },
  {
    name: "Food Products - S-2",
    id: "foodProductsS2",
    parentId: "storage",
  },
  {
    name: "Horns and combs , other than celluloid",
    id: "hornsAndCombsOtherThanCelluloid",
    parentId: "storage",
  },
  {
    name: "Motor Vehicle Service Station - S-1",
    id: "motorVehicleServiceStationS1",
    parentId: "storage",
  },
  {
    name: "Motorcycle Repair - S-1",
    id: "motorcycleRepairS1",
    parentId: "storage",
  },
  {
    name: "Parking Garages - S-2",
    id: "parkingGaragesS2",
    parentId: "storage",
  },
  {
    name: "Parking Lots - S-2",
    id: "parkingLotsS2",
    parentId: "storage",
  },
  {
    name: "Storage Aerosols, Levels 2 and 3 - S-1",
    id: "storageAerosolsLevels2And3S1",
    parentId: "storage",
  },
  {
    name: "Storage Asbestos - S-2",
    id: "storageAsbestosS2",
    parentId: "storage",
  },
  {
    name: "Storage Bags : cloth, burlap and paper - S-1",
    id: "storageBagsClothBurlapAndPaperS1",
    parentId: "storage",
  },
  {
    name: "Storage Bamboos and Rattan - S-1",
    id: "storageBamboosAndRattanS1",
    parentId: "storage",
  },
  {
    name: "Storage Baskets - S-1",
    id: "storageBasketsS1",
    parentId: "storage",
  },
  {
    name: "Storage Belting : canvas and leather - S-1",
    id: "storageBeltingCanvasAndLeatherS1",
    parentId: "storage",
  },
  {
    name: "Storage Beverages (alcohol) 16% up alcohol in metal, glass or ceramic containers- S-2",
    id: "storageBeveragesAlcohol16PercentUpS2",
    parentId: "storage",
  },
  {
    name: "Storage Books - S-1",
    id: "storageBooksS1",
    parentId: "storage",
  },
  {
    name: "Storage Boots and shoes - S-1",
    id: "storageBootsAndShoesS1",
    parentId: "storage",
  },
  {
    name: "Storage Buttons, including cloth covered, pearl or bone",
    id: "storageButtonsIncludingClothCoveredPearlOrBone",
    parentId: "storage",
  },
  {
    name: "Storage Cement in Bags - S-2",
    id: "storageCementInBagsS2",
    parentId: "storage",
  },
  {
    name: "Storage Clothing, Woolen Wearing Apparel - S-1",
    id: "storageClothingWoolenWearingApparelS1",
    parentId: "storage",
  },
  {
    name: "Storage Cordage",
    id: "storageCordage",
    parentId: "storage",
  },
  {
    name: "Storage Dairy Products in nonwaxed coated paper containers- S-2",
    id: "storageDairyProductsInNonwaxedCoatedPaperContainersS2",
    parentId: "storage",
  },
  {
    name: "Storage Dry Boat Indoor - S-1",
    id: "storageDryBoatIndoorS1",
    parentId: "storage",
  },
  {
    name: "Storage Electrical Coils - S-2",
    id: "storageElectricalCoilsS2",
    parentId: "storage",
  },
  {
    name: "Storage Electrical Motors  - S-2",
    id: "storageElectricalMotorsS2",
    parentId: "storage",
  },
  {
    name: "Storage Empty Cans - S-2",
    id: "storageEmptyCansS2",
    parentId: "storage",
  },
  {
    name: "Storage Foods food products - S-2",
    id: "storageFoodsFoodProductsS2",
    parentId: "storage",
  },
  {
    name: "Storage foods in noncombustible containers",
    id: "storageFoodsInNoncombustibleContainers",
    parentId: "storage",
  },
  {
    name: "Storage Fresh Fruits and Vegetables  - S-2",
    id: "storageFreshFruitsAndVegetablesS2",
    parentId: "storage",
  },
  {
    name: "Storage Frozen Foods - S-2",
    id: "storageFrozenFoodsS2",
    parentId: "storage",
  },
  {
    name: "Storage Furniture - S-1",
    id: "storageFurnitureS1",
    parentId: "storage",
  },
  {
    name: "Storage Furs - S-1",
    id: "storageFursS1",
    parentId: "storage",
  },
  {
    name: "Storage Glass - S-2",
    id: "storageGlassS2",
    parentId: "storage",
  },
  {
    name: "Storage Glass Bottles empty or filled with non combustible liquids - S-2",
    id: "storageGlassBottlesEmptyOrFilledWithNonCombustibleLiquidsS2",
    parentId: "storage",
  },
  {
    name: "Storage Glues, Mucilage,pastes and size - S-1",
    id: "storageGluesMucilagePastesAndSizeS1",
    parentId: "storage",
  },
  {
    name: "Storage Grain - S-1",
    id: "storageGrainS1",
    parentId: "storage",
  },
  {
    name: "Storage Gypsum Board - S-2",
    id: "storageGypsumBoardS2",
    parentId: "storage",
  },
  {
    name: "Storage Hazardous Materials - S-1",
    id: "storageHazardousMaterialsS1",
    parentId: "storage",
  },
  {
    name: "Storage Inert Pigments",
    id: "storageInertPigments",
    parentId: "storage",
  },
  {
    name: "Storage Ivory",
    id: "storageIvory",
    parentId: "storage",
  },
  {
    name: "Storage Leather - S-1",
    id: "storageLeatherS1",
    parentId: "storage",
  },
  {
    name: "Storage Linoleum - S-1",
    id: "storageLinoleumS1",
    parentId: "storage",
  },
  {
    name: "Storage Lumber - S-1",
    id: "storageLumberS1",
    parentId: "storage",
  },
  {
    name: "Storage Meats - S-2",
    id: "storageMeatsS2",
    parentId: "storage",
  },
  {
    name: "Storage Metal Cabinets - S-2",
    id: "storageMetalCabinetsS2",
    parentId: "storage",
  },
  {
    name: "Storage Metal desks with plastic tops and trim",
    id: "storageMetalDesksWithPlasticTopsAndTrim",
    parentId: "storage",
  },
  {
    name: "Storage Metal parts - S-2",
    id: "storageMetalPartsS2",
    parentId: "storage",
  },
  {
    name: "Storage Metals - S-2",
    id: "storageMetalsS2",
    parentId: "storage",
  },
  {
    name: "Storage Mirrors - S-2",
    id: "storageMirrorsS2",
    parentId: "storage",
  },
  {
    name: "Storage Motor Vehicle Repair Garage - S-1",
    id: "storageMotorVehicleRepairGarageS1",
    parentId: "storage",
  },
  {
    name: "Storage oil-filled and other types of distribution transformers",
    id: "storageOilFilledAndOtherTypesOfDistributionTransformers",
    parentId: "storage",
  },
  {
    name: "Storage Paper in Rolls or Packs - S-1",
    id: "storagePaperInRollsOrPacksS1",
    parentId: "storage",
  },
  {
    name: "Storage Parking Garage (open or enclosed) - S-2",
    id: "storageParkingGarageOpenOrEnclosedS2",
    parentId: "storage",
  },
  {
    name: "Storage Photo Engravings - S-1",
    id: "storagePhotoEngravingsS1",
    parentId: "storage",
  },
  {
    name: "Storage Porcelain and Pottery - S-2",
    id: "storagePorcelainAndPotteryS2",
    parentId: "storage",
  },
  {
    name: "Storage Resilient Flooring - S-1",
    id: "storageResilientFlooringS1",
    parentId: "storage",
  },
  {
    name: "Storage Silks",
    id: "storageSilks",
    parentId: "storage",
  },
  {
    name: "Storage Soaps",
    id: "storageSoaps",
    parentId: "storage",
  },
  {
    name: "Storage Stoves - S-2",
    id: "storageStovesS2",
    parentId: "storage",
  },
  {
    name: "Storage Sugar",
    id: "storageSugar",
    parentId: "storage",
  },
  {
    name: "storage Talc and soapstones",
    id: "storageTalcAndSoapstones",
    parentId: "storage",
  },
  {
    name: "Storage Tires, bulk storage of",
    id: "storageTiresBulkStorageOf",
    parentId: "storage",
  },
  {
    name: "Storage Tobacco , cigars, cigarettes and snuff- S-1",
    id: "storageTobaccoCigarsCigarettesAndSnuffS1",
    parentId: "storage",
  },
  {
    name: "Storage Upholstery and mattresses",
    id: "storageUpholsteryAndMattresses",
    parentId: "storage",
  },
  {
    name: "Storage Washers and Dryers - S-2",
    id: "storageWashersAndDryersS2",
    parentId: "storage",
  },
  {
    name: "Storage Wax Candles - S-1",
    id: "storageWaxCandlesS1",
    parentId: "storage",
  },

  {
    parentId: "utilityAndMiscellaneous",
    id: "agriculturalBuildingU",
    name: "Agricultural Building - U",
  },
  { parentId: "utilityAndMiscellaneous", id: "barnU", name: "Barn - U" },
  {
    parentId: "utilityAndMiscellaneous",
    id: "carportsU",
    name: "Carports - U",
  },
  {
    parentId: "utilityAndMiscellaneous",
    id: "fencesMoreThan6FeetU",
    name: "Fences more than 6 feet - U",
  },
  {
    parentId: "utilityAndMiscellaneous",
    id: "greenhouseU",
    name: "Greenhouse - U",
  },
  {
    parentId: "utilityAndMiscellaneous",
    id: "privateGaragesU",
    name: "Private Garages - U",
  },
  {
    parentId: "utilityAndMiscellaneous",
    id: "retainingWallsU",
    name: "Retaining Walls - U",
  },
  { parentId: "utilityAndMiscellaneous", id: "shedsU", name: "Sheds - U" },
  { parentId: "utilityAndMiscellaneous", id: "stablesU", name: "Stables - U" },
  { parentId: "utilityAndMiscellaneous", id: "tanksU", name: "Tanks - U" },
  { parentId: "utilityAndMiscellaneous", id: "towerU", name: "Tower - U" },

  { parentId: "other", id: "mixedUseSpecify", name: "Mixed Use (Specify)" },
  {
    parentId: "other",
    id: "oneFamilyAccessoryDwellingUnitAdu",
    name: "One Family Accessory Dwelling Unit (ADU)",
  },
  {
    parentId: "other",
    id: "individualCondoOrCoOp",
    name: "Individual Condo or Co-op",
  },
];

export const getCategoryByIdAndType = (id: string, type: string) => {
  let collection: Params[] = [];
  if (type === "generalBuildingCodeUseCategory") {
    collection = GENERAL_BUILDING_CODE_CATEGORY;
  }

  if (type === "specificBuildingCodeUse") {
    collection = SPECIFIC_BUILDING_CODE_CATEGORY;
  }

  if (type === "zr16UseCategory") {
    collection = ZR16_USE_CATEGORY;
  }

  if (type === "specificZoningUse") {
    collection = SPECIFIC_ZONING_USE;
  }

  return collection.find((item) => item.id === id);
};
