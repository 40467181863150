import { createTheme } from '@mui/material/styles';

import { FONTS_SET, GLOBAL_THEME } from './global';

// Created with new guidelines from mui v5
export const OVERRIDES_THEME = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          [GLOBAL_THEME.breakpoints.up("lg")]: {
            padding: `0 ${GLOBAL_THEME.spacing(6)}`,
          },
          "&.disable-center": {
            margin: 0,
          },
          "&.disableGutters": {
            padding: "0 !important",
          },
          "&.negativeGutter-md": {
            [GLOBAL_THEME.breakpoints.down("md")]: {
              margin: `0 -${GLOBAL_THEME.spacing(2)}`,
              width: `calc(100% + ${GLOBAL_THEME.spacing(4)})`,
            },
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          "&.isWithoutBg": {
            background: "none",
          },
        },
      },
    },
    MuiStepButton: {
      styleOverrides: {
        vertical: {
          padding: 0,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: GLOBAL_THEME.spacing(5),
          height: GLOBAL_THEME.spacing(5),
          marginLeft: -GLOBAL_THEME.spacing(1),
          color: "#e1e5e9",
          "&.Mui-active": {
            text: {
              fill: "#fff !important",
            },
          },
        },
        text: {
          fontSize: "1.1rem !important",
          fontWeight: "bold",
          fill: "#535863",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          paddingRight: GLOBAL_THEME.spacing(2),
          "&.Mui-completed svg": { color: GLOBAL_THEME.palette.success.main },
        },
        label: {
          display: "none",
          [GLOBAL_THEME.breakpoints.up("md")]: {
            display: "block",
          },
          color: "#213A6D !important",
          fontSize: "1.4rem",
          fontWeight: "bold",
          fontFamily: FONTS_SET.join(","),
          "&.Mui-active, &.Mui-completed": {
            fontWeight: "bold",
          },
          "&.clickable": {
            cursor: "pointer",
          },
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          paddingLeft: GLOBAL_THEME.spacing(4.5),
          [GLOBAL_THEME.breakpoints.down("md")]: {
            marginLeft: 0,
            paddingLeft: 0,
            border: "none",
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          top: "16px",
          "&.Mui-active .MuiStepConnector-line, &.Mui-completed .MuiStepConnector-line":
            {
              borderColor: GLOBAL_THEME.palette.success.main,
            },
        },
        vertical: {
          marginLeft: 10,
          "& .MuiStepConnector-lineVertical": {
            minHeight: 12,
            borderLeftWidth: "4px",
            borderColor: "#e1e5e9",
          },
        },
        line: {
          borderTopWidth: "8px",
          borderColor: "#e1e5e9",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&.centered-strong": {
            display: "flex",
            flexDirection: "column",
            margin: 0,
            ".MuiFormControlLabel-label": {
              fontSize: "2.4rem",
              fontWeight: 700,
              textTransform: "uppercase",
              color: GLOBAL_THEME.palette.primary.main,
              textAlign: "center",
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          background: GLOBAL_THEME.palette.success.main,
          color: "#fff",
          "& .MuiAlert-icon": {
            color: "#fff",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "1.6rem",
          fontWeight: "bold",
          minWidth: "96px",
          "&.fullWidth": {
            minWidth: "100%",
          },
          "&.success": {
            background: GLOBAL_THEME.palette.success.main,
          },
          "&.fail": {
            background: GLOBAL_THEME.palette.error.main,
          },
          "&.dark": {
            background: GLOBAL_THEME.palette.primary.dark,
          },
        },
        text: {
          fontWeight: "normal",
          color: `${GLOBAL_THEME.palette.info.light} !important`,
          textDecoration: "underline",
          "&:hover": {
            textDecoration: "none",
            background: "none !important",
          },
          "&.Mui-disabled": {
            color: `${GLOBAL_THEME.palette.grey[500]} !important`,
            opacity: 0.7,
          },
        },
        outlined: {
          minWidth: "85px",
          color: "#0f7895",
          "&.error": {
            color: GLOBAL_THEME.palette.error.main,
          },
          "&.successOutlined": {
            color: GLOBAL_THEME.palette.success.main,
            borderColor: GLOBAL_THEME.palette.success.main,
          },
          "&.Mui-disabled": {
            color: `${GLOBAL_THEME.palette.grey[500]} !important`,
            opacity: 0.7,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.error": {
            color: GLOBAL_THEME.palette.error.main,
          },
          "&.outlined": {
            border: "1px solid #fff",
            borderRadius: `${GLOBAL_THEME.shape.borderRadius}px`,
            padding: "8px",
          },
          "&.outlined-dark": {
            border: `1px solid #67707E`,
            borderRadius: `${GLOBAL_THEME.shape.borderRadius}px`,
            padding: "8px",
          },
          "&.outlined-warning": {
            border: `1px solid ${GLOBAL_THEME.palette.secondary.main}`,
            borderRadius: `${GLOBAL_THEME.shape.borderRadius}px`,
            padding: "8px",
            color: GLOBAL_THEME.palette.secondary.main,
          },
          "&.filled": {
            color: GLOBAL_THEME.palette.grey["50"],
            backgroundColor: GLOBAL_THEME.palette.primary.main,
            padding: "10px",
          },
          "&.active": {
            backgroundColor: `${GLOBAL_THEME.palette.secondary.main} !important`,
          },
          "&.fixed-size": {
            width: "40px",
            height: "40px",
          },
          "&.fixed-size-small": {
            width: "30px",
            height: "30px",
            fontSize: "1.6rem",
          },
          "&.Mui-disabled": {
            color: `${GLOBAL_THEME.palette.grey[500]} !important`,
            opacity: 0.7,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: `0px 3px 5px rgba(52, 61, 75, 0.05)`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          marginBottom: GLOBAL_THEME.spacing(4),
          "&.card-type-dark": {
            color: GLOBAL_THEME.palette.common.white,
            background: GLOBAL_THEME.palette.primary.dark,
            "& a": {
              color: GLOBAL_THEME.palette.common.white,
            },
          },
          "&.card-type-superdark": {
            color: GLOBAL_THEME.palette.common.white,
            background: GLOBAL_THEME.palette.primary.main,
            "& a": {
              color: GLOBAL_THEME.palette.common.white,
            },
          },
          "&.card-type-success": {
            color: GLOBAL_THEME.palette.common.white,
            background: GLOBAL_THEME.palette.success.main,
          },
          "&.card-type-success-light": {
            color: GLOBAL_THEME.palette.grey[700],
            background: "#E9F3E8",
          },
          "&.card-type-error": {
            color: GLOBAL_THEME.palette.common.white,
            background: GLOBAL_THEME.palette.error.main,
            "& a": {
              color: GLOBAL_THEME.palette.common.white,
            },
            "& > .MuiBox-root > .MuiSvgIcon-root": {
              color: `${GLOBAL_THEME.palette.common.white} !important`,
            },
          },
          "&.card-type-error2": {
            color: GLOBAL_THEME.palette.common.white,
            background: GLOBAL_THEME.palette.primary.main,
            "& a": {
              color: GLOBAL_THEME.palette.common.white,
            },
          },
          "&.card-type-info": {
            color: GLOBAL_THEME.palette.common.white,
            background: "#e27816",
            "& a": {
              color: GLOBAL_THEME.palette.common.white,
            },
          },
          "&.card-type-neutral": {
            color: GLOBAL_THEME.palette.common.white,
            background: GLOBAL_THEME.palette.grey[500],
            "& a": {
              color: GLOBAL_THEME.palette.common.white,
            },
          },
          "&.card-type-light": {
            color: GLOBAL_THEME.palette.grey[700],
            background: GLOBAL_THEME.palette.grey[200],
            "& a": {
              color: GLOBAL_THEME.palette.common.white,
            },
            "& .MuiInputBase-root": {
              backgroundColor: GLOBAL_THEME.palette.common.white,
            },
          },
          "&.card-type-neutral-inversed": {
            color: GLOBAL_THEME.palette.grey[700],
            background: GLOBAL_THEME.palette.grey[400],
            "& > .MuiBox-root > .MuiSvgIcon-root": {
              color: `${GLOBAL_THEME.palette.grey[700]} !important`,
            },
          },
          "&.card-type-info-light": {
            color: GLOBAL_THEME.palette.grey[700],
            background: "#fff3e6",
            "& > .MuiBox-root > .MuiSvgIcon-root": {
              color: `${GLOBAL_THEME.palette.grey[700]} !important`,
            },
          },
          "&.card-type-blue-light": {
            color: "",
            background: "#cce0ec",
            "& > .MuiBox-root > .MuiSvgIcon-root": {
              color: `${GLOBAL_THEME.palette.grey[700]} !important`,
            },
          },
          "&.card-type-warning": {
            color: "#3B414D",
            background: "#FFA800",
            "& a": {
              color: "#3B414D",
            },
          },
          "&.left-no-radius": {
            borderRadius: `0 ${GLOBAL_THEME.shape.borderRadius}px ${GLOBAL_THEME.shape.borderRadius}px ${GLOBAL_THEME.shape.borderRadius}px`,
          },
          "&.right-no-radius": {
            borderRadius: `${GLOBAL_THEME.shape.borderRadius}px 0 ${GLOBAL_THEME.shape.borderRadius}px ${GLOBAL_THEME.shape.borderRadius}px`,
          },
          "&.overflow-visible": {
            overflow: "visible",
          },
          "&.rounded": {
            borderRadius: "20px",
          },
          "&.rounded-small": {
            borderRadius: "8px",
          },
          "&.rounded-medium": {
            borderRadius: "20px",
          },
          "&.secondaryLevel": {
            background: GLOBAL_THEME.palette.grey[200],
          },
          "&.secondaryLevelOutline": {
            border: `1px solid ${GLOBAL_THEME.palette.grey[400]}`,
            boxShadow: "none",
          },
          "&.h-full": {
            flexGrow: 1,
          },
          "&.no-margin-bottom": {
            marginBottom: 0,
          },
          "&.small-margin-bottom": {
            marginBottom: GLOBAL_THEME.spacing(1.5),
          },
          "&.no-shadow": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: GLOBAL_THEME.spacing(4),
          [GLOBAL_THEME.breakpoints.down("md")]: {
            padding: `${GLOBAL_THEME.spacing(3.5)} ${GLOBAL_THEME.spacing(
              2.75
            )}`,
          },
          "&.with-indicator": {
            position: "relative",
            "&:before": {
              position: "absolute",
              top: 0,
              left: 0,
              display: "block",
              content: '""',
              width: GLOBAL_THEME.spacing(0.5),
              height: `calc(100% - ${GLOBAL_THEME.spacing(4)})`,
              background: GLOBAL_THEME.palette.primary.dark,
            },

            "&.full:before": {
              height: "100%",
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: { transform: "none" },
        formControl: {
          position: "relative",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: 4,
          color: GLOBAL_THEME.palette.primary.main,
          fontSize: "1.4rem",
          lineHeight: 1.2,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          color: "GLOBAL_THEME.palette.primary.main",
          [GLOBAL_THEME.breakpoints.down(600)]: {
            whiteSpace: "normal",
          },
          "&.Mui-focused": {
            color: GLOBAL_THEME.palette.primary.dark,
          },
        },
        asterisk: {
          color: GLOBAL_THEME.palette.error.light,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        formControl: {
          "label + &": {
            marginTop: GLOBAL_THEME.spacing(0.8),
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          overflow: "hidden",
          width: "100%",
          background: "#f2f3f4",
          borderRadius: GLOBAL_THEME.shape.borderRadius,
          border: `1px solid #67707e`,
          "&.white-bg": {
            background: "#fff",
          },
          "&.Mui-error": {
            borderColor: GLOBAL_THEME.palette.error.main,
            borderWidth: 2,
            "& input": {
              height: `calc(${GLOBAL_THEME.spacing(5)} - 4px`,
              paddingLeft: `calc(${GLOBAL_THEME.spacing(1)} - 1px`,
            },
            "& .MuiSelect-select": {
              height: `calc(${GLOBAL_THEME.spacing(5)} - 4px)`,
              padding: `0 0 0 calc(${GLOBAL_THEME.spacing(1)} - 1px)`,
              lineHeight: `calc(${GLOBAL_THEME.spacing(5)} - 4px)`,
            },
          },
          "&.Mui-focused": {
            borderColor: GLOBAL_THEME.palette.text.primary,
            borderWidth: 2,
            "& input": {
              height: `calc(${GLOBAL_THEME.spacing(5)} - 4px)`,
              paddingLeft: `calc(${GLOBAL_THEME.spacing(1)} - 1px)`,
            },
            "& .MuiSelect-select": {
              height: `calc(${GLOBAL_THEME.spacing(5)} - 4px)`,
              padding: `0 0 0 calc(${GLOBAL_THEME.spacing(2)} - 1px)`,
              lineHeight: `calc(${GLOBAL_THEME.spacing(5)} - 4px)`,
            },
          },
          "&:before": {
            display: "none",
          },
          "&:after": {
            display: "none",
          },
          "&.Mui-disabled": {
            borderColor: GLOBAL_THEME.palette.divider,
          },
          "& .MuiSelect-select .placeholder": {
            opacity: 0.4,
          },
        },
        input: {
          padding: `0 0 0 ${GLOBAL_THEME.spacing(1)}`,
          lineHeight: `calc(${GLOBAL_THEME.spacing(5)} - 2px)`,
          height: `calc(${GLOBAL_THEME.spacing(5)} - 2px)`,
          fontSize: "2rem",
          fontWeight: 400,
          color: GLOBAL_THEME.palette.text.primary,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: 0,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          paddingRight: "8px",
        },
        positionStart: {
          paddingLeft: "8px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          right: GLOBAL_THEME.spacing(1),
        },
        outlined: {
          padding: `0 0 0 calc(${GLOBAL_THEME.spacing(2)} - 1px)`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 5,
          backgroundColor: GLOBAL_THEME.palette.text.primary,
          "& .MuiBox-root": {
            lineHeight: 1.33,
            fontSize: "1.2rem",
          },
          "& .MuiTooltip-arrow": { color: GLOBAL_THEME.palette.text.primary },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: `${GLOBAL_THEME.spacing(2)} ${GLOBAL_THEME.spacing(3)}`,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          position: "relative",
          "&:before": {
            content: '""',
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            height: "1px",
            background: GLOBAL_THEME.palette.divider,
          },
          "&.round-filled:before": {
            display: "none",
          },
          "&.round-filled.right-aligned .MuiTabs-flexContainer": {
            justifyContent: "flex-end",
          },
          "&.round-filled .MuiTabs-indicator": {
            display: "none",
          },
          "&.round-filled .MuiTab-root": {
            fontSize: "2rem",
            minHeight: "56px",
            padding: "0 30px",
            borderRadius: "20px 20px 0 0",
            border: "1px solid #e1e5e9",
            borderWidth: "1px 1px 0 1px",
            "&.Mui-selected": {
              borderColor: "#fff",
              backgroundColor: "#fff",
            },
          },
          "&.filled": {
            minHeight: 1,
          },
          "&.filled:before": {
            display: "none",
          },
          "&.filled.right-aligned .MuiTabs-flexContainer": {
            justifyContent: "flex-end",
          },
          "&.filled .MuiTabs-indicator": {
            display: "none",
          },
          "&.filled .MuiTab-root": {
            fontSize: "1.6rem",
            minHeight: "44px",
            padding: "0 10px",
            "&.Mui-selected": {
              backgroundColor: "#fff",
            },
          },
          "&.uppercase .MuiTab-root": {
            textTransform: "uppercase",
          },
          "&.strong .MuiTab-root": {
            fontWeight: "bold",
            fontSize: "2.4rem",
            minHeight: "64px",
            padding: "0 24px",
          },
          "& .MuiTabs-scrollButtons.Mui-disabled": {
            opacity: 0.3,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: "0 !important",
          textTransform: "none",
          fontSize: "1.6rem",
          fontWeight: "normal",
          "&.Mui-selected": {
            color: GLOBAL_THEME.palette.primary.main,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: GLOBAL_THEME.shape.borderRadius,
          backgroundColor: GLOBAL_THEME.palette.grey[400],
          maxWidth: "100%",
          "&.flat": {
            borderRadius: "5px",
          },
          "&.no-label": {
            padding: "5px",
            "& .MuiChip-label": {
              display: "none",
            },
            "& .MuiChip-icon": {
              margin: 0,
            },
          },
          "&.dark": {
            backgroundColor: GLOBAL_THEME.palette.primary.dark,
            color: "#fff",
          },
          "&.main": {
            backgroundColor: GLOBAL_THEME.palette.primary.main,
            color: "#fff",
          },
          "&.white": {
            backgroundColor: GLOBAL_THEME.palette.common.white,
          },
          "&.upper": {
            textTransform: "uppercase",
          },
          "&.success": {
            backgroundColor: GLOBAL_THEME.palette.success.light,
            border: `1px solid ${GLOBAL_THEME.palette.success.dark}`,
            color: GLOBAL_THEME.palette.success.dark,
          },
          "&.warning": {
            backgroundColor: "#fff3e6",
            border: `1px solid #b05e11`,
          },
          "&.success-flat": {
            backgroundColor: GLOBAL_THEME.palette.success.main,
            color: GLOBAL_THEME.palette.common.white,
          },
          "&.error-flat": {
            backgroundColor: GLOBAL_THEME.palette.error.main,
            color: GLOBAL_THEME.palette.common.white,
          },
          "&.error-outlined": {
            backgroundColor: "#ffe9ee",
            color: GLOBAL_THEME.palette.text.primary,
            border: `1px solid ${GLOBAL_THEME.palette.error.main}`,
          },
          "&.outlined": {
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            color: "#0e182d",
            border: `1px solid #0e182d`,
            borderRadius: "5px",
            height: "26px",
          },
          "&.outlined-gray": {
            backgroundColor: "rgba(225, 229, 233, 0.5)",
            color: "#0e182d",
            border: `1px solid rgba(103, 112, 126, 0.5)`,
            borderRadius: "5px",
          },
          "&.outlined-dark": {
            backgroundColor: "#67707E",
            color: "#fff",
            border: `1px solid rgba(255, 255, 255, 0.5)`,
            borderRadius: "5px",
            "& .MuiChip-deleteIcon .MuiSvgIcon-root": {
              color: "rgba(255, 255, 255, 0.5)",
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.noSummary": {
            "&.Mui-expanded": { margin: 0, padding: 0 },
            "& > .MuiAccordionSummary-root": {
              display: "none",
            },
            "&:before": { display: "none" },
            "& .MuiExpanded": { margin: 0, padding: 0 },
            "& .MuiAccordionDetails-root": { display: "block", padding: 0 },
          },
          "&.noBg": { background: "transparent" },
          "&.noBorder": {
            "&:before": {
              display: "none",
            },
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "&.equal-heights": {
            display: "flex",
            // flexDirection: "column",
          },
        },
        item: {
          "&.with-h-divider": {
            position: "relative",
            "&:before": {
              position: "absolute",
              content: "''",
              width: "1px",
              height: "calc(100% - 20px)",
              backgroundColor: GLOBAL_THEME.palette.divider,
              left: 0,
              top: "22px",
            },
          },
          "&.with-v-divider": {
            borderBottom: `1px solid ${GLOBAL_THEME.palette.divider}`,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "&.no-borders .MuiTableCell-root": {
            borderBottom: "none",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: GLOBAL_THEME.palette.primary.main,
          color: "#fff",
          ".MuiTableCell-root": {
            color: "#fff",
            fontSize: "1.6rem",
            fontWeight: 700,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${GLOBAL_THEME.palette.divider}`,
          padding: GLOBAL_THEME.spacing(1),
          fontSize: "1.6rem",
          "&.with-border-bottom": {
            borderBottom: `1px solid ${GLOBAL_THEME.palette.divider} !important`,
          },
          "&.with-summary-border": {
            borderBottom: `2px solid ${GLOBAL_THEME.palette.primary.dark} !important`,
          },
        },
        head: {
          fontSize: "1.4rem",
          textTransform: "uppercase",
          color: GLOBAL_THEME.palette.grey[600],
          fontWeight: "normal",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          [GLOBAL_THEME.breakpoints.down("lg")]: {
            margin: GLOBAL_THEME.spacing(0.5),
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 48,
          height: 24,
          padding: 0,
          margin: GLOBAL_THEME.spacing(1),
          marginRight: GLOBAL_THEME.spacing(2),
          marginLeft: 0,
          display: "flex",
          alignItems: "center",
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 0,
            top: 4,
            transform: "translateX(4px)",
            "&.Mui-checked": {
              transform: "translateX(28px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: GLOBAL_THEME.palette.primary.dark,
              },
            },
          },
          "& .MuiSwitch-thumb": {
            width: 16,
            height: 16,
            boxShadow: "none",
          },
          "& .MuiSwitch-track": {
            borderRadius: 24,
            opacity: 1,
            backgroundColor: GLOBAL_THEME.palette.grey[500],
          },
        },
      },
    },
  },
});
