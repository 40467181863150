import { Params } from "types";

import {
  LOAD_TYPES,
  SPECIFIC_BUILDING_CODE_CATEGORY,
  SPECIFIC_ZONING_USE,
} from "./categories.const";

export const getSpecificZoningUseCollectionByParentId = (
  id: string
): Params[] => {
  return SPECIFIC_ZONING_USE.filter((item: Params) => {
    return item.parentId === id;
  });
};

export const getSpecificZoningUseCategory = (
  id: string
): Params | undefined => {
  return SPECIFIC_ZONING_USE.find((item: Params) => {
    return item.id === id;
  });
};

export const getSpecificBuildingCollectionByParentId = (
  id: string
): Params[] => {
  return SPECIFIC_BUILDING_CODE_CATEGORY.filter((item: Params) => {
    return item.parentId === id;
  });
};

export const getLoadTypeById = (id: string): Params | undefined => {
  return LOAD_TYPES.find((item: Params) => {
    return item.id === id;
  });
};
