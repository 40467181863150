import { ReactNode } from 'react';
import InfoCircleIcon from 'ui/icons/InfoCircleIcon';

import { Box, InputLabel, Tooltip, useTheme } from '@mui/material';

export interface LabelTooltipProps {
  tooltipText?: string | ReactNode;
  disabled?: boolean;
  label: string;
  inputName: string;
  required?: boolean;
  error: boolean;
}

export default function LabelTooltip(props: LabelTooltipProps) {
  const { tooltipText, disabled, label, inputName, required, error } = props;

  const theme = useTheme();

  return (
    <Tooltip title={tooltipText} arrow>
      <Box sx={{ display: "flex", float: "left" }}>
        <InputLabel
          disabled={disabled}
          shrink
          htmlFor={inputName}
          required={required ? true : false}
          error={error}
        >
          {label}
        </InputLabel>
        {tooltipText && (
          <InfoCircleIcon
            sx={{
              fontSize: "1.9rem",
              color: disabled
                ? theme.palette.text.disabled
                : theme.palette.text.primary,
              ml: 0.5,
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
}
