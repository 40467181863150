import { getApplicationByNumber } from 'core/api/applications';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';
import ErrorsList from 'ui/components/errors-list/ErrorsList';

import { Box, Container } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export default function SwitchPublicCfoNumber() {
  const { id } = useParams();
  const [redirect, setRedirect] = useState<string>("");
  const queryKey = ["applications", id];
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (redirect !== "") {
      navigate(redirect);
    }
  }, [redirect, navigate]);

  const { isSuccess, isLoading, isError, error, data } = useQuery({
    queryKey,
    queryFn: () => getApplicationByNumber(id ?? ""),
    enabled: id !== "",
  });

  useEffect(() => {
    if (isSuccess) {
      queryClient.setQueryData(["applications", data.id], data);
      setRedirect(`/public/applications/${data.id}/details/status-info`);
    }
  }, [isSuccess]);

  return (
    <Container>
      <Box mt={5}>
        {isLoading && <DataLoader />}
        {isError && (
          <ErrorsList title="Application load error" errors={error} />
        )}
      </Box>
    </Container>
  );
}
