import Error404Page from 'core/layouts/error-pages/Error404Page';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';

const ReviewDetailsTabsContent = lazy(
  () =>
    import(
      "app-sections/user/applications/details/components/sections/review-details/components/review-details-tabs-content/ReviewDetailsTabsContent"
    )
);
// const Issuance = lazy(
//   () =>
//     import(
//       "app-sections/user/applications/details/components/sections/issuance/Issuance"
//     )
// );
// const Payment = lazy(
//   () =>
//     import(
//       "app-sections/user/applications/details/components/sections/payment/Payment"
//     )
// );

export const applicationDetailsReviewRoutes: RouteObject[] = [
  {
    path: "",
    // element: <></>,
    element: <Navigate replace to="applicantValidation" />,
  },
  {
    path: "applicantValidation",
    element: (
      <Suspense fallback={<DataLoader />}>
        <ReviewDetailsTabsContent type="applicantValidation" />
      </Suspense>
    ),
  },
  {
    path: "existingCoo",
    element: (
      <Suspense fallback={<DataLoader />}>
        <ReviewDetailsTabsContent type="existingCOO" />
      </Suspense>
    ),
  },
  {
    path: "propertySize",
    element: (
      <Suspense fallback={<DataLoader />}>
        <ReviewDetailsTabsContent type="propertySize" />
      </Suspense>
    ),
  },
  {
    path: "permits",
    element: (
      <Suspense fallback={<DataLoader />}>
        <ReviewDetailsTabsContent type="permits" />
      </Suspense>
    ),
  },
  {
    path: "inspections",
    element: (
      <Suspense fallback={<DataLoader />}>
        <ReviewDetailsTabsContent type="inspections" />
      </Suspense>
    ),
  },
  {
    path: "finalAcceptance",
    element: (
      <Suspense fallback={<DataLoader />}>
        <ReviewDetailsTabsContent type="finalAcceptance" />
      </Suspense>
    ),
  },
  {
    path: "*",
    element: <Error404Page />,
  },
  {
    path: ":any/*",
    element: <Error404Page />,
  },
];
