import { getApplicationById } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import LinesSkeleton from 'ui/components/skeletons/lines-skeleton/LinesSkeleton';
import SummaryPropertyInfo from 'ui/components/summary-property-info/SummaryPropertyInfo';

import { Box, Card, CardContent } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

export default function SummaryHeaderWrapper() {
  const { activeApplicationId } = useApplicationsStore();

  const applicationQueryKey = ["applications", activeApplicationId];

  const appQuery = useQuery({
    queryKey: applicationQueryKey,
    queryFn: () => getApplicationById(activeApplicationId),
    enabled: activeApplicationId !== "",
  });

  if (appQuery.isLoading || appQuery.isError) {
    return (
      <Box mt={6} mb={-5}>
        <Card>
          <CardContent>
            <Box mt={-5} mb={0}>
              <LinesSkeleton
                elements={1}
                isWithButtons={false}
                lineHeight={25}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  if (appQuery.isSuccess) {
    return (
      <Box mt={6} mb={0}>
        <SummaryPropertyInfo data={appQuery.data} />
      </Box>
    );
  }
  return null;
}
