import { WebsocketMessage } from 'types';

import ApplicationSwoUpdate from '../updates-actions/ApplicationSwoUpdate';
import ApplicationUpdate from '../updates-actions/ApplicationUpdate';
import FileGenerated from '../updates-actions/FileGenerated';
import IncreaseUnreadCount from '../updates-actions/IncreaseUnreadCount';
import SetCertificatesForAddress from '../updates-actions/SetCertificatesForAddress';
import SetPermitsForAddress from '../updates-actions/SetPermitsForAddress';
import SetReviewCollection from '../updates-actions/SetReviewCollection';
import SetUnreadCount from '../updates-actions/SetUnreadCount';
import UpdateReviewCollection from '../updates-actions/UpdateReviewCollection';

export interface UpdatesListenerActionsProps {
  message: WebsocketMessage;
}

/**
 * Split messages into actions
 * @param props
 * @returns
 */
export default function UpdatesListenerActions(props: UpdatesListenerActionsProps) {
  const { message } = props;

  /**
   * PDF File For Certificate
   */
  if (message.type === 'pdf_generated') {
    return <FileGenerated type="pdf" message={message} />;
  }

  /**
   * CSV File Generated
   */
  if (message.type === 'csv_generated') {
    return <FileGenerated type="csv" message={message} />;
  }

  /**
   * Notification unread count
   */
  if (message.type === 'notifications_unread_count') {
    return <SetUnreadCount message={message} />;
  }

  /**
   * New notification
   */
  if (message.type === 'notification') {
    return <IncreaseUnreadCount message={message} />;
  }

  if (message.type === 'application') {
    return <ApplicationUpdate message={message} />;
  }

  if (message.type === 'application_swo') {
    return <ApplicationSwoUpdate message={message} />;
  }

  if (message.type === 'update_review') {
    return <UpdateReviewCollection message={message} />;
  }

  if (message.type === 'set_reviews') {
    return <SetReviewCollection message={message} />;
  }

  if (message.type === 'permits_for_address') {
    return <SetPermitsForAddress message={message} />;
  }

  if (message.type === 'certificates_for_address') {
    return <SetCertificatesForAddress message={message} />;
  }

  return null;
}
