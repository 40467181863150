import { Params } from 'types';

export type NestedFormItemType =
  | "checkbox"
  | "text"
  | "textarea"
  | "select"
  | "datepicker";

export interface ExtendableGroupData {
  buttonLabel: string;
  removeButtonLabel: string;
  groupSchema: Params;
  maxItems: number;
  summaryOptions?: {
    showTotalByField: string;
  };
  groupItemHeader?: string;
}

export interface NestedFormItem {
  id: string;
  label: string;
  type: NestedFormItemType;
  isExtendable: ExtendableGroupData | null;
  hasChildren: null | NestedFormItem[];
  childrenType?: "group" | "array";

  // for select types
  dictionary?: Params[];

  //
  fullWidth?: boolean;

  // for text input
  adornment?: { position: "start" | "end"; label: string };
  helperText?: string;

  // for textarea
  maxLength?: number;

  // for datepickers
  disablePast?: boolean;
  disableFuture?: boolean;

  // for groups - set header inside group or any other element
  beforeChildren?: JSX.Element;
  afterChildren?: JSX.Element;

  // hide for users
  isHidden?: boolean;
}

export const getItemById = (id: string, config: NestedFormItem[]) => {
  return config.find((item) => item.id === id);
};

export const getFieldName = (
  fieldData: Record<"id", string>
): string | undefined => {
  return Object.keys(fieldData).find(
    (key: string) => key !== "items" && key !== "id"
  );
};

export const getSimpleCheckbox = (
  id: string,
  label: string
): NestedFormItem => {
  return {
    id,
    label,
    type: "checkbox",
    isExtendable: null,
    fullWidth: true,
    hasChildren: null,
  };
};
