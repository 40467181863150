import { APP_DETAILS_NAV_TABS, getLinkToDetails } from 'app-sections/user/applications/steps/config';
import { useAuthStore } from 'core/store/auth';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SwoLiftedInfo } from 'types';
import { ConfirmationDialog } from 'ui/components/confirmation-dialog/ConfirmationDialog';
import { isClient } from 'ui/helpers/users';

import { Box, Button, Typography } from '@mui/material';

export default function SwoLiftedListener() {
  const { currentUser } = useAuthStore();

  const [dialogState, setDialogState] = useState<boolean>(false);
  const [swoLiftedApplications, setSwoLiftedApplications] = useState<SwoLiftedInfo[]>([]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.swoLiftedApplications && currentUser.swoLiftedApplications.length > 0) {
        setDialogState(true);
        setSwoLiftedApplications(currentUser.swoLiftedApplications);
      }
    }
  }, [currentUser?.swoLiftedApplications.length]);

  if (!currentUser || !isClient(currentUser.userProfile.roles)) {
    return null;
  }

  return (
    <ConfirmationDialog
      title=""
      description={
        <Box mb={2} padding={0} maxWidth="576px">
          <Box mt={2} textAlign="center" color="main.dark">
            <Typography component="h2" variant="h2">
              Stop Work Order Lifted
            </Typography>
          </Box>
          <Box fontSize="2rem" textAlign="center">
            We would like to inform you that the Stop Work Order (SWO) restriction associated with your application(s){' '}
            <SetApplicationsUrls
              data={swoLiftedApplications}
              onClickHandler={() => {
                setDialogState(false);
              }}
            />{' '}
            has been lifted, allowing the review process to resume.
            <br />
            Please click on application ID(s) to view your application information.
          </Box>
        </Box>
      }
      opened={dialogState}
      confirmButtonVariant="contained"
      onConfirm={() => {
        setDialogState(false);
      }}
      confirmButtonClass="success"
      confirmButtonText="OK"
      isWithClosingIcon={true}
      centeredButtons={true}
    />
  );
}

interface SetApplicationsUrlsProps {
  data: SwoLiftedInfo[];
  onClickHandler: () => void;
}

function SetApplicationsUrls(props: SetApplicationsUrlsProps) {
  const { data, onClickHandler } = props;

  return (
    <>
      {data.map((item, index) => (
        <Fragment key={item.id}>
          <Button
            sx={{ minWidth: 'auto', padding: 0, fontSize: 'inherit' }}
            aria-label="Go to Application"
            component={Link}
            to={`${getLinkToDetails(item.id, APP_DETAILS_NAV_TABS[0].id)}`}
            variant="text"
            onClick={() => {
              onClickHandler();
            }}
          >
            {item.certificatePermitNumber && `${item.certificatePermitNumber || 'N/A'}`}
            {!item.certificatePermitNumber && `Draft ${item.id}`}
          </Button>
          {data.length - 1 !== index && ', '}
        </Fragment>
      ))}
    </>
  );
}
