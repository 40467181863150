import { get } from 'lodash-es';
import { Params } from 'types';

import { DEFAULT_LOAD_TYPE } from '../property-use-form/categories-config/categories.const';
import { getLoadTypeById, getSpecificZoningUseCategory } from '../property-use-form/categories-config/categories.helper';

export const getLoadData = (categoryId: string): Params => {
  const category = getSpecificZoningUseCategory(categoryId);
  if (category && category.loadType) {
    const loadType = getLoadTypeById(category.loadType);
    if (loadType) {
      return loadType;
    }
  }
  return DEFAULT_LOAD_TYPE;
};

export const isChildCareVisible = (loadTypeId: string): boolean => {
  return ["childrenStaff"].includes(loadTypeId);
};

export const isEatingDrinkingVisible = (loadTypeId: string): boolean => {
  return ["seatsPersons"].includes(loadTypeId);
};

export const isDwellingUnitsVisible = (loadTypeId: string): boolean => {
  return ["dwellingUnits"].includes(loadTypeId);
};

export const isDwellingUnitsFilled = (data: Params): boolean => {
  const affordableZoningUnits =
    get(data, "extendedPropertyLoad.affordableZoningUnits") ?? false;
  const inclusionaryZoningUnits =
    get(data, "extendedPropertyLoad.inclusionaryZoningUnits") ?? false;

  return inclusionaryZoningUnits || affordableZoningUnits;
};
