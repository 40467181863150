import { NestedFormItem } from 'ui/form-fields/nested-fields/config';

export const SECTIONS = [
  { name: "Cellar/Basement", id: "cellarBasement" },
  { name: "Mezzanine", id: "mezzanine" },
  { name: "1st Floor", id: "1stFloor" },
  { name: "2nd Floor", id: "2ndFloor" },
  { name: "3rd Floor", id: "3rdFloor" },
  { name: "4th Floor", id: "4thFloor" },
  { name: "5th Floor", id: "5thFloor" },
  { name: "6th Floor", id: "6thFloor" },
  { name: "7th Floor", id: "7thFloor" },
  { name: "8th Floor", id: "8thFloor" },
  { name: "9th Floor", id: "9thFloor" },
  { name: "10th Floor", id: "10thFloor" },
  { name: "11th Floor", id: "11thFloor" },
  { name: "12th Floor", id: "12thFloor" },
  { name: "13th Floor", id: "13thFloor" },
  { name: "14th Floor", id: "14thFloor" },
  { name: "Roof", id: "roof" },
  { name: "Summer Garden (private outdoor space)", id: "summerGarden" },
  { name: "Outdoor Café (public space)", id: "outdoorCafe" },
  { name: "Other", id: "other" },
];

const SECTION_FIELDS_GUESTS: NestedFormItem[] = [
  {
    id: "sectionId",
    label: "Select section of the building/space",
    type: "select",
    isExtendable: null,
    hasChildren: null,
    dictionary: SECTIONS,
  },
  {
    id: "sectionNotes",
    label: "Notes (optional)",
    type: "text",
    isExtendable: null,
    hasChildren: null,
    helperText: "Specify the selected section of the building/space.",
  },
  {
    id: "sectionLoad",
    label: "Number of guests",
    type: "text",
    isExtendable: null,
    hasChildren: null,
  },
  {
    id: "sectionSqFootage",
    label: "Square Footage",
    type: "text",
    isExtendable: null,
    hasChildren: null,
    helperText:
      "Specify the square footage of this section of the building/space.",
  },
];

const SECTION_FIELDS_STAFF: NestedFormItem[] = [
  {
    id: "sectionId",
    label: "Select section of the building/space",
    type: "select",
    isExtendable: null,
    hasChildren: null,
    dictionary: SECTIONS,
  },
  {
    id: "sectionNotes",
    label: "Notes (optional)",
    type: "text",
    isExtendable: null,
    hasChildren: null,
    helperText: "Specify the selected section of the building/space.",
  },
  {
    id: "sectionSqFootage",
    label: "Square Footage",
    type: "text",
    isExtendable: null,
    hasChildren: null,
    helperText:
      "Specify the square footage of this section of the building/space.",
  },
  {
    id: "sectionLoad",
    label: "Number of staff",
    type: "text",
    isExtendable: null,
    hasChildren: null,
  },
];

export const EATING_DRINKING_FIELD_SCHEMA: NestedFormItem[] = [
  {
    id: "numberOfGuestsSeated",
    label: "Number of guests seated",
    type: "checkbox",
    fullWidth: true,
    isExtendable: {
      maxItems: SECTIONS.length,
      buttonLabel: "Add another section",
      removeButtonLabel: "Delete section",
      groupSchema: {
        sectionId: "",
        sectionNotes: "",
        sectionSqFootage: "",
        sectionLoad: "",
      },
      summaryOptions: {
        showTotalByField: "sectionLoad",
      },
    },
    hasChildren: SECTION_FIELDS_GUESTS,
    childrenType: "group",
  },
  {
    id: "numberOfGuestsStanding",
    label: "Number of guests standing",
    type: "checkbox",
    fullWidth: true,
    isExtendable: {
      maxItems: SECTIONS.length,
      buttonLabel: "Add another section",
      removeButtonLabel: "Delete section",
      groupSchema: {
        sectionId: "",
        sectionNotes: "",
        sectionSqFootage: "",
        sectionLoad: "",
      },
      summaryOptions: {
        showTotalByField: "sectionLoad",
      },
    },
    hasChildren: SECTION_FIELDS_GUESTS,
    childrenType: "group",
  },
  {
    id: "staff",
    label: "Number of staff",
    type: "checkbox",
    fullWidth: true,
    isExtendable: {
      maxItems: SECTIONS.length,
      buttonLabel: "Add another section",
      removeButtonLabel: "Delete section",
      groupSchema: {
        sectionId: "",
        sectionNotes: "",
        sectionSqFootage: "",
        sectionLoad: "",
      },
      summaryOptions: {
        showTotalByField: "sectionLoad",
      },
    },
    hasChildren: SECTION_FIELDS_STAFF,
    childrenType: "group",
  },
];
