import { createTheme } from '@mui/material';

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    tablet: true;
    lg: true;
    xl: true;
  }
}

export const FONTS_SET = ["Montserrat", "Helvetica", "Arial", "sans-serif"];
export const PALETTE_MAIN_COLOR = "#213A6D";

export const GLOBAL_THEME = createTheme({
  typography: {
    fontFamily: FONTS_SET.join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    htmlFontSize: 10,
    body1: {
      fontSize: "1.4rem",
    },
    body2: {
      fontSize: "1.6rem",
    },
    h1: {
      fontSize: "4rem",
      fontWeight: "bold",
      fontFamily: FONTS_SET.join(","),
      marginBottom: "11px",
      color: PALETTE_MAIN_COLOR,
      wordBreak: "break-word",
      textTransform: "uppercase",
      "&.with-indicator": {
        paddingBottom: "16px",
        marginBottom: "16px",
        position: "relative",
        "&:before": {
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "block",
          content: '""',
          width: "100px",
          height: "4px",
          background: "#ad3838",
        },
      },
      "&.centered": {
        textAlign: "center",
        "&:before": {
          left: "50%",
          marginLeft: "-50px",
        },
      },
    },
    h2: {
      fontSize: "3.2rem",
      fontWeight: "bold",
      fontFamily: FONTS_SET.join(","),
      color: PALETTE_MAIN_COLOR,
      marginBottom: "16px",
      textTransform: "uppercase",
      wordBreak: "break-word",
    },
    h3: {
      fontSize: "2.4rem",
      fontWeight: "bold",
      fontFamily: FONTS_SET.join(","),
      textTransform: "uppercase",
      color: PALETTE_MAIN_COLOR,
      wordBreak: "break-word",
    },
    h4: {
      fontSize: "2rem",
      fontWeight: "normal",
      color: "#67707e",
      "&.upper": {
        textTransform: "uppercase",
      },
    },
    h5: {
      fontSize: "2rem",
      fontWeight: "bold",
      fontFamily: FONTS_SET.join(","),
      "&.upper": {
        textTransform: "uppercase",
        marginBottom: "8px",
        color: PALETTE_MAIN_COLOR,
      },
      "&.faded": {
        color: "#67707e",
      },
      "&.primary": {
        color: PALETTE_MAIN_COLOR,
      },
    },
    h6: {
      fontSize: "1.8rem",
      fontFamily: FONTS_SET.join(","),
      textTransform: "uppercase",
      fontWeight: 500,
      color: PALETTE_MAIN_COLOR,
    },
    subtitle1: {
      fontSize: "1.4rem",
      fontFamily: FONTS_SET.join(","),
      textTransform: "uppercase",
      fontWeight: "bold",
      color: PALETTE_MAIN_COLOR,
    },
    subtitle2: {
      fontSize: "2rem",
      fontFamily: FONTS_SET.join(","),
      color: PALETTE_MAIN_COLOR,
      fontWeight: "normal",
      "&.warning": {
        color: "#d50037",
      },
    },
  },
  palette: {
    primary: {
      dark: "#0062a0",
      main: PALETTE_MAIN_COLOR,
    },
    secondary: {
      main: "#ad3838",
      dark: "#eb1203",
      contrastText: "#fff",
    },
    success: {
      main: "#28a96b",
      dark: "#166d12",
      light: "#e9f3e8",
    },
    error: {
      main: "#d50037",
      dark: "#aa002c",
    },
    text: {
      primary: "#535863",
      secondary: "#535863",
    },
    info: {
      main: "#89929f",
      light: "#0F7895",
    },
    background: {
      paper: "#fff",
      default: "#f2f3f4",
    },
    divider: "#e1e5e9",
    grey: {
      50: "#fff",
      200: "#F2F3F4",
      400: "#e1e5e9",
      500: "#cecfd3",
      600: "#67707e",
      700: "#535863",
      900: "#0e182d",
    },
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "tablet", "lg", "xl"],
    values: {
      xs: 0,
      sm: 448,
      md: 800,
      tablet: 960,
      lg: 1376,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 4,
  },
});
