import { Application } from 'types';
import SummaryHeader from 'ui/components/summary-header/SummaryHeader';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from '@mui/material';

export interface SummaryPropertyInfoProps {
  data: Application;
  isExtended?: boolean;
}

export default function SummaryPropertyInfo(props: SummaryPropertyInfoProps) {
  const { data, isExtended = false } = props;

  return (
    <Accordion elevation={0} className="noBorder" defaultExpanded={isExtended}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box px={2} pt={1} pb={0.5}>
          <Typography component="h3" variant="h3">
            Property Info
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box mt={0} pb={3}>
          <Divider />
        </Box>
        <Box px={2}>
          <SummaryHeader application={data} isExtended={isExtended} />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
