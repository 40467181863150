import Error404Page from 'core/layouts/error-pages/Error404Page';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';

import { applicationDetailsReviewRoutes } from './applicationDetailsReviewRoutes';

const StatusInfo = lazy(
  () =>
    import(
      'app-sections/user/applications/details/components/sections/status-info/StatusInfo'
    )
);

const ReviewDetails = lazy(
  () =>
    import(
      'app-sections/user/applications/details/components/sections/review-details/ReviewDetails'
    )
);
const Issuance = lazy(
  () =>
    import(
      'app-sections/user/applications/details/components/sections/issuance/Issuance'
    )
);

export const applicationDetailsRoutes: RouteObject[] = [
  {
    path: '',
    element: <Navigate replace to="status-info" />,
  },
  {
    path: 'status-info',
    element: (
      <Suspense fallback={<DataLoader />}>
        <StatusInfo />
      </Suspense>
    ),
  },
  {
    path: 'review-details',
    element: (
      <Suspense fallback={<DataLoader />}>
        <ReviewDetails />
      </Suspense>
    ),
    children: applicationDetailsReviewRoutes,
  },
  {
    path: 'c-of-o-issuance',
    element: (
      <Suspense fallback={<DataLoader />}>
        <Issuance />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: <Error404Page />,
  },
  {
    path: ':any/*',
    element: <Error404Page />,
  },
];
